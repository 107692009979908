import React from "react";
import { Link } from "react-router-dom";

import alt from "../../assets/images/blog/1.png";

export default function PostCarThree({ data }) {
	const convertDateTime = (d) => {
		let t = new Date(1970, 0, 1);
		if (t && d) {
			let time = t.setSeconds(d.seconds);
			const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
				time
			);
			const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(time);
			const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(time);
			return `${da}-${mo}-${ye}`;
		}
	};
	return (
		<div className="post-card-three">
			<Link to={"/blog/post/" + data?.id}>
				<a className="post-card-three__image">
					<img
						src={data?.coverImageUrl || alt}
						alt={data?.mainTitle}
					/>
				</a>
			</Link>
			<div className="post-card-three__content">
				<Link to={"/blog/post/" + data?.id}>
					<a>{data?.mainTitle}</a>
				</Link>
				<p>{convertDateTime(data.createdAt)}</p>
			</div>
		</div>
	);
}
