import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import backendUrl from "../../constants/urls";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import { firebase } from "../../firebase";
import { toast } from "react-toastify";
import { isMobile } from "../../common/utils";
import {
    removeTemporaryData,
} from "../../redux/actions/shopActions";
import {
    removeAllFromCart,
} from "../../redux/actions/cartActions";
import LayoutFour from "../../components/Layout/LayoutFour";
import Button from "../../components/Control/Button";
import { Breadcrumb, BreadcrumbItem } from "../../components/Other/Breadcrumb";
import spa from '../../assets/images/banners/spa.jpg'

const override = css`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-self: center;
	justify-items: center;
	flex-direction: row;
	width: 100%;
	align-items: center;
`;


const extractPaymentIdFromUrl = (url) => {
    const firstPart = url.split('payment_id=');
    const altFirstPart = url.split('payment_type=');
    let paymentId = null;
    let paymentType = null;
    if (firstPart && firstPart.length > 0) {
        const secondPart = firstPart[1].split('&');
        if (secondPart && secondPart.length > 0) {
            paymentId = secondPart[0];
        }
    }
    if (altFirstPart && altFirstPart.length > 0) {
        const secondPart = altFirstPart[1].split('&');
        if (secondPart && secondPart.length > 0) {
            paymentType = secondPart[0];
        }
    }
    return { paymentId: paymentId, paymentType: paymentType };
}

const Success = (props) => {
    const params = useParams()
    const [loading, setLoading] = useState(true);
    const mercadoNumber = params?.id;
    const [orderId, setOrderId] = useState('')
    const dispatch = useDispatch();

    useEffect(async () => {

        const fetchData = async (mercadoNumber) => {
            const db = firebase.firestore();
            let dbOrderRef = db.collection("orders");
            let ordersArray = [];
            try {
                const res = await dbOrderRef.where('mercadoNumber', '==', mercadoNumber).get();
                res.forEach((doc) => {
                    let bannersObj = doc.data();
                    bannersObj.id = doc.id;
                    if (bannersObj.status == "mercado") {
                    ordersArray.push(bannersObj);
                    }
                });
                const finalArray = ordersArray.filter((v,i,a)=>a.findIndex(v2=>(v2.customerDetails.email===v.customerDetails.email))===i)
                return finalArray;
            } catch (err) {
                console.log("error", err);
            }
            setLoading(false);
        };

        if (mercadoNumber) {
            const paymentData = props.location.search;
            const { paymentId, paymentType } = extractPaymentIdFromUrl(paymentData);
            const allOrders = await fetchData(mercadoNumber);
            const order = allOrders.length ? allOrders[0] : null;

            if (allOrders.length) {
                setOrderId(order.id)
                let db = firebase.firestore();
                let dbOrderRef = db.collection("orders");

                for (var i = 0; i < allOrders.length; i++) {
                    let o = allOrders[i];
                    if (o.id) {
                        await dbOrderRef.doc(o.id).update({
                            ...o, status: 'approved',
                            paymentType: paymentType, paymentId: paymentId
                        });
                    }
                }

                await axios.post(`${backendUrl}/api/mailer/confirm`, order);

            }

            dispatch(removeTemporaryData());

            dispatch(removeAllFromCart());
        }
        setLoading(false);

    }, []);

    useEffect(() => {
        if (!loading) {
            setTimeout(function () {
                toast.success("Tu pago se ha completado con éxito.", {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }, 1500);
        }
    }, [loading]);

    return (
        <LayoutFour title="Success">
            <Breadcrumb title="Pedido confirmado" img={spa}>
                <BreadcrumbItem name="Pedido" />
                <BreadcrumbItem name="Resumen" current />
            </Breadcrumb>
            <div className="cart" style={{
                marginTop: '130px',
                marginBottom: '130px'
            }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12" >
                            <div className="error-404__content pb-100" style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                height: '300px'
                            }}>
                                {
                                    loading ?
                                        (
                                            <BeatLoader
                                                css={override}
                                                size={isMobile() ? 20 : 50}
                                                color={"#10ABDE"}
                                                loading={true}
                                            />
                                        ) : (
                                            <>
                                                <h5>Hemos recibido tu pedido.</h5>
                                                {
                                                    orderId && (
                                                        <p>
                                                        <h6><b>
                                                        Nro de pedido: {orderId}.
                                                            </b></h6>

                                                        </p>
                                                    )
                                                }
                                                <p>
                                                    Recibirás un correo electrónico con los detalles.
                </p>
                                                <div className="form-wrapper">
                                                    <Button
                                                        action={"/"}
                                                        className="-underline"
                                                        color="transparent"
                                                        content="Volver al inicio"
                                                    />
                                                </div>
                                            </>
                                        )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutFour>
    );
}

export default withRouter(Success);
