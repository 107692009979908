import React from "react";

import { Link } from "react-router-dom";
import SubscribeEmail from "../Other/SubcribeEmail";

import SocialIcons from "../Other/SocialIcons";
import footerLinks from "../../data/footer/links.json";
import footerInfomation from "../../data/footer/info.json";
import logo from "../../assets/images/logo/logoNegativo.png";
import payment from "../../assets/images/footer/payment.png";

export default function FooterOne() {
	return (
		<div className="footer-one">
			<div className="container">
				<div className="footer-one__header">
					<div className="footer-one__header__logo">
						<Link to="/">
							<img
								src={logo}
								style={{ height: "60px", width: "250px" }}
								alt="Logo"
							/>
						</Link>
					</div>
					<div className="footer-one__header__social">
						<SocialIcons className="-border" />
					</div>
				</div>
				<div className="footer-one__body">
					<div className="row">
						<div className="col-12 col-md-6 col-lg-4">
							<div className="footer__section -info">
								<h5 className="footer-title">Información:</h5>
								<p>
									Dirección: <span>{footerInfomation.address}</span>
								</p>
								<p>
									Teléfono: <span>{footerInfomation.phone}</span>
								</p>
								<p>
									Email: <span>{footerInfomation.email}</span>
								</p>
								<p>
									Horarios: <span>{footerInfomation.open}</span>
								</p>
							</div>
							<div
								className="footer-two__content"
								style={{ marginTop: "30px" }}
							>
								<SubscribeEmail
									mailchimpUrl="https://lariales.us2.list-manage.com/subscribe/post?u=16ce287d201aaf7cc629a4c2f&id=5b9007282d"
									placeholder="Email"
									btnContent="SUBSCRIBIR"
									className="footer-two-newsletter"
								/>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-4">
							<div className="footer__section -links">
								<div className="row">
									<div className="col-12 col-sm-6">
										<h5 className="footer-title">Compras</h5>
										<ul>
											{footerLinks.accountLinks.map((link, index) => (
												<li key={index}>
													<Link to={link.to}>{link.name}</Link>
												</li>
											))}
										</ul>
									</div>
									<div className="col-12 col-sm-6">
										<h5 className="footer-title">Información</h5>
										<ul>
											{footerLinks.informationLinks.map((link, index) => (
												<li key={index}>
													<Link to={link.to}>{link.name}</Link>
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="col-12 col-lg-4">
							<div className="footer__section -payment">
								<h5 className="footer-title">Métodos de pago</h5>
								<p>
									Comprá online desde tu cuenta de Mercado Pago o con tus
									tarjetas de preferencia.
								</p>
								<div className="payment-methods">
									<img src={payment} alt="Payment methods" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="footer-one__footer">
				<div className="container">
					<div className="footer-one__footer__wrapper">
						<p>© Todos los derechos reservados 2020</p>
					</div>
				</div>
			</div>
		</div>
	);
}
