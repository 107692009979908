import { Home } from '../pages/Home';

import Error404 from '../pages/NotFound';
import ProductDetail from '../pages/shop/product/product';
import About from '../pages/other/about';
import Services from '../pages/other/services';
import Checkout from '../pages/shop/checkout';
import Cart from '../pages/shop/cart';
import Information from '../pages/other/information';
import TermsAndConditions from '../pages/other/terms-and-conditions';
import Blog from '../pages/blog/blog';
import BlogPost from '../pages/blog/post/blogpost';
import Contact from '../pages/other/contact';
import Shop from '../pages/shop/fullwidth-left-sidebar';
import Success from '../pages/shop/success';
import Pending from '../pages/shop/pending';
import Failure from '../pages/shop/failure';

import { Login } from '../pages/admin/Login';
import { Dashboard } from '../pages/admin/Dashboard';
import Categories from '../pages/admin/Categories';
import Products from '../pages/admin/Products';
import Orders from '../pages/admin/Orders';
import Banners from '../pages/admin/Banners';
import Vacations from '../pages/admin/Vacations';
import Blogs from '../pages/admin/Blogs';

const routes = [
  {
    path: '/',
    exact: true,
    component: Home,
  },
  {
    path: '/home',
    exact: true,
    component: Home,
  },
  {
    path: '/shop',
    exact: true,
    component: Shop,
  },
  {
    path: '/contact',
    exact: true,
    component: Contact,
  },
  {
    path: '/shop/product/:slug',
    exact: true,
    component: ProductDetail,
  },
  {
    path: '/success/:id',
    exact: true,
    component: Success,
  },
  {
    path: '/pending/:id',
    exact: true,
    component: Pending,
  },
  {
    path: '/failure',
    exact: true,
    component: Failure,
  },
  {
    path: '/blog/post/:id',
    exact: true,
    component: BlogPost,
  },
  {
    path: '/shop/checkout',
    exact: true,
    component: Checkout,
  },
  {
    path: '/shop/cart',
    exact: true,
    component: Cart,
  },
  {
    path: '/about',
    exact: true,
    component: About,
  },
  {
    path: '/services',
    exact: true,
    component: Services,
  },
  {
    path: '/information',
    exact: true,
    component: Information,
  },
  {
    path: '/terms-and-conditions',
    exact: true,
    component: TermsAndConditions,
  },
  {
    path: '/blog',
    exact: true,
    component: Blog,
  },
  {
    path: '/admin/blogs',
    exact: true,
    component: Blogs,
  },
  {
    path: '/admin/login',
    exact: true,
    component: Login,
  },
  {
    path: '/admin/categories',
    exact: true,
    component: Categories,
  },
  {
    path: '/admin/products',
    exact: true,
    component: Products,
  },
  {
    path: '/admin/orders',
    exact: true,
    component: Orders,
  },
  {
    path: '/admin/banners',
    exact: true,
    component: Banners,
  },
  {
    path: '/admin/vacations',
    exact: true,
    component: Vacations,
  },
  {
    path: '/admin/dashboard',
    exact: true,
    component: Dashboard,
  },
  {
    path: '*',
    component: Error404,
  },
];

export { routes };
