import React from "react";
import LayoutFour from "../../components/Layout/LayoutFour";
import { Breadcrumb, BreadcrumbItem } from "../../components/Other/Breadcrumb";
import InstagramTwo from "../../components/Sections/Instagram/InstagramTwo";
import IntroductionSeven from "../../components/Sections/Introduction/IntroductionSeven";
import products from '../../assets/images/banners/products-alt-2.jpg'

const termsAndConditions = [
	{
		id: 1,
		header: "Políticas de compra",
		body:
			"El sitio www.lariales.com.uy utiliza, en todas sus transacciones de comercio electrónico, un sistema de seguridad que garantiza que todos los datos que nos envíes estarán a salvo. Gracias a este proceso es prácticamente imposible que alguien, sin autorización, acceda a cualquier información personal. \nPodrán usar los servicios de www.lariales.com.uy aquellas personas que tengan capacidad legal para contratar. No podrán utilizar los servicios de comercio electrónico menores de edad o usuarios que hayan sido suspendidos temporal o definitivamente. Si estás registrando un usuario como empresa debes tener capacidad para contratar a nombre de tal entidad y de obligar a la misma en los términos de este acuerdo.",
	},
	{
		id: 2,
		header: "Pedidos",
		body:
			"Las órdenes generadas a través de la www.lariales.com.uy, deben estar correctamente completadas con todos los datos requeridos para la inequívoca identificación del autor de la compra. Una vez recibida la orden, nuestro sitio le enviará a su correo electrónico una confirmación.\nEn caso que uno o varios de los productos de la orden de compra no se encuentren disponibles por causas de fuerza mayor, www.lariales.com.uy podrá anular el pedido con previa comunicación al cliente y devolución del importe en caso de haber sido pago.\nDel mismo modo www.lariales.com.uy se reserva, para garantizar la calidad del servicio, el derecho de no atender un pedido si considera que cualquiera de los datos de la orden de compra y/o comprador (datos de identificación, forma de pago o cualquier otra índole) son fraudulentos, erróneos o incompletos.",
	},
	{
		id: 3,
		header: "Precios y validez de la oferta",
		body:
			"Los precios indicados debajo de cada producto se reflejan en pesos Uruguayos y los impuestos están incluidos. Hay que añadirle los gastos de envío, el cuál según destino y opción de transporte elegida se especifican.\nLos precios y condiciones expuestos son los válidos en cada momento y tienen vigencia únicamente en la sesión abierta. En la información de confirmación de pedido, con carácter previo a que el comprador acepte la operación, se especifican claramente los precios de cada uno de los artículos elegidos.\nEl sitio www.lariales.com.uy se reserva el derecho de modificar los precios mencionados en la página de Internet o en cualquiera de sus publicaciones (ya sean piezas gráficas, mensajes por e-mail u otros) sin previo aviso",
	},
];

export default function () {
	return (
		<LayoutFour title="Términos y condiciones">
			<Breadcrumb title="Términos y condiciones" img={products}>
				<BreadcrumbItem name="Inicio" />
				<BreadcrumbItem name="Información" current />
			</Breadcrumb>
			<IntroductionSeven data={termsAndConditions} />
			<InstagramTwo />
		</LayoutFour>
	);
}
