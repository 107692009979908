import React from 'react';

import classNames from "classnames";

export default function SocialIcons({ className, colored }) {
  return (
    <ul className={`${classNames(className)} social-icons`}>
      <li>
        <a
          style={{ color: colored && "#dd34c1" }}
          href="https://www.instagram.com/lariales.uy/"
        >
          <i className="fa fa-instagram" />
        </a>
      </li>
    </ul>
  );
}
