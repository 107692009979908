import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistor, store } from "./redux/store";
import { ToastContainer } from "react-toastify";
import { MyRouter } from "./routes";

import "./styles/styles.scss";
import Loading from "./components/Other/Loading";
import withReduxStore from "./common/with-redux-store";
import "font-awesome/css/font-awesome.min.css";

const App = ({ reduxStore }) => {
	return (
		<Provider store={reduxStore}>
			<PersistGate loading={<Loading />} persistor={persistor}>
				<MyRouter />
				<ToastContainer position="bottom-left" autoClose={3000} />
			</PersistGate>
		</Provider>
	);
};

export default withReduxStore(App);