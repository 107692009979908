import React, { useRef, useEffect } from "react";
import Parallax from "parallax-js";
import Button from "../../Control/Button";
import classNames from "classnames";

import basic from "../../../assets/images/about/15100.png";
import s1a from "../../../assets/images/services/1.jpg";
import s1b from "../../../assets/images/services/2.jpg";
import s2a from "../../../assets/images/services/3.jpg";
import s2b from "../../../assets/images/services/4.jpg";
import s3b from "../../../assets/images/services/5.jpg";
import s3a from "../../../assets/images/services/6.jpg";
import s4b from "../../../assets/images/services/8.jpg";
import s4a from "../../../assets/images/services/probeta.jpg";

const selectPicture = (ind) => {
	switch (ind) {
		case 0:
			return {
				big: s1a,
				small: s1b,
			};
			break;
		case 1:
			return {
				big: s2a,
				small: s2b,
			};
			break;
		case 2:
			return {
				big: s3a,
				small: s3b,
			};
			break;
		case 3:
			return {
				big: s4a,
				small: s4b,
			};
			break;
	}
};

export default function ServiceItem(props) {
	const {
		bigImgSrc,
		smallImgSrc,
		description,
		order,
		reverse,
		title,
		points,
		idx,
	} = props;
	const bg1 = useRef(null);
	const bg2 = useRef(null);
	useEffect(() => {
		let parallax1 = new Parallax(bg1.current);
		let parallax2 = new Parallax(bg2.current);
		return () => {
			parallax1.disable();
			parallax2.disable();
		};
	}, []);
	const renderListStyle = (content) => {
		if (reverse) {
			return (
				<>
					{content}{" "}
					<i className="fa fa-check" style={{ marginLeft: 5 / 15 + "em" }} />
				</>
			);
		}
		return (
			<>
				<i className="fa fa-check" style={{ marginRight: 5 / 15 + "em" }} />{" "}
				{content}
			</>
		);
	};
	return (
		<div className={`services__item ${classNames({ "-reverse": reverse })}`}>
			<div className="container">
				<div className="row">
					<div
						className={`col-12 col-md-6 ${classNames({
							"order-md-2": reverse,
						})}`}
					>
						<div className="services__item__image">
							<div className="services__item__image__background">
								<img src={basic} alt="background" />
							</div>

							<div className="services__item__image__detail">
								<div className="image__item">
									<div ref={bg1} className="wrapper">
										<img
											data-depth="0.3"
											src={selectPicture(idx).big}
											alt="image"
										/>
									</div>
								</div>
								<div className="image__item">
									<div ref={bg2} className="wrapper">
										<img
											data-depth="0.2"
											data-invert-x
											data-invert-y
											src={selectPicture(idx).small}
											alt="image"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						className={`col-12 col-md-6 ${classNames({
							"order-md-1": reverse,
						})}`}
					>
						<div className="services__item__content">
							<div className="services__item__order">
								{reverse ? <h3>.{order}</h3> : <h3>{order}.</h3>}
							</div>
							<h2 className="services__item__title">{title}</h2>
							<p className="services__item__description">{description}</p>
							<ul>
								{points?.length
									? points.map((p) => <li>{renderListStyle(p)}</li>)
									: null}
							</ul>
							<Button
								color="white"
								action="#"
								content="Conozca nuestros productos"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
