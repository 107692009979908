import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { auth } from "../../firebase";
import { Redirect } from "react-router-dom";
import AddProductModal from "./Modals/AddProductModal";
import AddCategoryModal from "./Modals/AddCategoryModal";
import AddBlogPostModal from "./Modals/AddBlogPostModal";
import AddBannerModal from "./Modals/AddBannerModal";
import AddVacationModal from "./Modals/AddVacationModal";
import adminIcon from "../../assets/icons/user.svg";
import logo from "../../assets/images/logo/logolariales.png";

class TopNavbar extends Component {
	_isMounted = false;

	state = {
		display: false,
		layoutCls: true,
		collapsed: true,
		loggedOut: false,
	};

	handleLogout = () => {
		auth
			.signOut()
			.then(() => {
				this.setState({
					loggedOut: true,
				});
				setTimeout(function () {
					toast.success("Has cerrado sesión", {
						position: "bottom-left",
						autoClose: 1000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
				}, 1500);
			})
			.catch(function (error) {
				alert("OOps something went wrong check your console");
				// console.log(error);
			});
	};

	// Navbar
	toggleNavbar = () => {
		this.setState({
			collapsed: !this.state.collapsed,
		});
	};

	// Add Product Modal
	toggleAddProductModal = () => {
		this.setState({
			AddProductModal: !this.state.AddProductModal,
		});
	};

	// Add Subproduct Modal
	toggleAddCategoryModal = () => {
		this.setState({
			AddCategoryModal: !this.state.AddCategoryModal,
		});
	};

	// Add BlogPost Modal
	toggleAddBlogPostModal = () => {
		this.setState({
			AddBlogPostModal: !this.state.AddBlogPostModal,
		});
	};

	// Add Banner Modal
	toggleAddBannerModal = () => {
		this.setState({
			AddBannerModal: !this.state.AddBannerModal,
		});
	};

		// Add Vacation Modal
		toggleAddVacationModal = () => {
			this.setState({
				AddVacationModal: !this.state.AddVacationModal,
			});
		};

	//
	onToggleClass = () => {
		this.setState({
			layoutCls: !this.state.layoutCls,
		});
		this.props.onChangeClass(this.state.layoutCls);
	};

	render() {
		const { collapsed } = this.state;
		const classNameOne = collapsed
			? "collapse navbar-collapse"
			: "collapse navbar-collapse show";

		if (this.state.loggedOut) {
			return <Redirect to="/admin/login" />;
		}

		return (
			<React.Fragment>
				<nav className="navbar navbar-expand admin-top-navbar">
					<Link to="/admin/dashboard">
						<a className="navbar-brand">
							<img className="admin-icon" src={logo} alt="logo" />
						</a>
					</Link>

					{/* Burger menu */}
					<div className="burger-menu x" onClick={this.onToggleClass}>
						<span className="top-bar"></span>
						<span className="middle-bar"></span>
						<span className="bottom-bar"></span>
					</div>

					<div className={classNameOne} id="navbarSupportedContent">
						<ul className="navbar-nav ml-auto">
							<li className="nav-item add-products">
								<a
									className="nav-link"
									onClick={(e) => {
										e.preventDefault();
										this.toggleAddCategoryModal();
									}}
								>
									Agregar categorías
								</a>
							</li>

							<li className="nav-item add-products">
								<a
									className="nav-link"
									onClick={(e) => {
										e.preventDefault();
										this.toggleAddProductModal();
									}}
								>
									Agregar productos
								</a>
							</li>

							<li className="nav-item add-products">
								<a
									className="nav-link"
									onClick={(e) => {
										e.preventDefault();
										this.toggleAddBlogPostModal();
									}}
								>
									Agregar Blog Post
								</a>
							</li>

							<li className="nav-item add-products">
								<a
									className="nav-link"
									onClick={(e) => {
										e.preventDefault();
										this.toggleAddBannerModal();
									}}
								>
									Cartel banner
								</a>
							</li>

							<li className="nav-item add-products">
								<a
									className="nav-link"
									onClick={(e) => {
										e.preventDefault();
										this.toggleAddVacationModal();
									}}
								>
									Días de cierre
								</a>
							</li>

							<li className="nav-item profile">
								<Link to="#">
									<a className="nav-link">
										<img src={adminIcon} alt="Image" />
									</a>
								</Link>

								<ul className="dropdown-menu">
									<li className="nav-item">
										<Link to="#" activeClassName="active">
											<a
												onClick={(e) => {
													e.preventDefault();
													this.handleLogout();
												}}
												className="nav-link"
											>
												Cerrar sesión
											</a>
										</Link>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</nav>

				{/* Sidebar Modal */}
				<AddProductModal
					onClick={this.toggleAddProductModal}
					active={this.state.AddProductModal ? "active" : ""}
				/>
				<AddCategoryModal
					onClick={this.toggleAddCategoryModal}
					active={this.state.AddCategoryModal ? "active" : ""}
				/>
				<AddBlogPostModal
					onClick={this.toggleAddBlogPostModal}
					active={this.state.AddBlogPostModal ? "active" : ""}
				/>
				<AddBannerModal
					onClick={this.toggleAddBannerModal}
					active={this.state.AddBannerModal ? "active" : ""}
				/>
				<AddVacationModal
					onClick={this.toggleAddVacationModal}
					active={this.state.AddVacationModal ? "active" : ""}
				/>
			</React.Fragment>
		);
	}
}

export default TopNavbar;
