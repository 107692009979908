import React from "react";
import LayoutFour from "../../components/Layout/LayoutFour";
import { Breadcrumb, BreadcrumbItem } from "../../components/Other/Breadcrumb";
import IntroductionOne from "../../components/Sections/Introduction/IntroductionOne";
import introductionOneData from "../../data/introduction/introductionOne.json";
import IntroductionTwo from "../../components/Sections/Introduction/IntroductionTwo";
import introductionTwoData from "../../data/pages/about.json";
import TestimonialOne from "../../components/Sections/Testimonial/TestimonialOne";
import testimonialOneData from "../../data/testimonial/data.json";
import Benefits from "../../components/Other/Benefits";
import IntroductionNine from "../../components/Sections/Introduction/IntroductionNine";
import InstagramTwo from "../../components/Sections/Instagram/InstagramTwo";
import soap from '../../assets/images/banners/soap.jpg';

export default function () {
	return (
		<LayoutFour title="Nosotros">
			<Breadcrumb title="Nosotros" img={soap}>
				<BreadcrumbItem name="Inicio" />
				<BreadcrumbItem name="Nosotros" current />
			</Breadcrumb>
			<IntroductionOne data={introductionOneData} />
			<IntroductionTwo data={introductionTwoData} style={{ marginBottom: 0 }} />
			<IntroductionNine />
			<Benefits />
		</LayoutFour>
	);
}
