import React, { useState, useEffect } from "react";
import Paginator from "react-hooks-paginator";

import BlogSidebar from "../../components/Blog/BlogSidebar";
import LayoutFour from "../../components/Layout/LayoutFour";
import InstagramTwo from "../../components/Sections/Instagram/InstagramTwo";
import { Breadcrumb, BreadcrumbItem } from "../../components/Other/Breadcrumb";
import { getPostByKeyword } from "../../common/postSelect";
import BlogContent from "../../components/Blog/BlogContent";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import { isMobile } from "../../common/utils";
import axios from "axios";
import backendUrl from "../../constants/urls";
import soap from '../../assets/images/banners/soap-2.jpg';

const override = css`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-self: center;
	justify-items: center;
	flex-direction: row;
	width: 100%;
	align-items: center;
`;

const Blog = () => {
	const pageLimit = 7;
	const [offset, setOffset] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [currentData, setCurrentData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${backendUrl}/api/blogs/`, {
					headers: {
						accept: "application/json",
						"Accept-Language": "en-US,en;q=0.8",
					},
				});
				setCurrentData(response?.data);
			} catch (e) {}
			setIsLoading(false);
		};
		fetchData();
	}, []);

	return (
		<LayoutFour title="Blog">
			<Breadcrumb title="Blog" img={soap}>
				<BreadcrumbItem name="Inicio" />
				<BreadcrumbItem name="Blog" current />
			</Breadcrumb>
			<div className="blog">
				{isLoading ? (
					<BeatLoader
						css={override}
						size={isMobile() ? 20 : 50}
						color={"#10ABDE"}
						loading={true}
					/>
				) : currentData && currentData.length > 0 ? (
					<div className="container">
						<div className="row">
							<div className="col-12 col-lg-3">
								<BlogSidebar limit={5} popularPostData={currentData} />
							</div>
							<div className="col-12 col-lg-9">
								<BlogContent offset={offset} data={currentData} />
								<Paginator
									pageContainerClass="paginator"
									totalRecords={currentData.length}
									pageLimit={pageLimit}
									pageNeighbours={2}
									setOffset={setOffset}
									currentPage={currentPage}
									setCurrentPage={setCurrentPage}
								/>
							</div>
						</div>
					</div>
				) : (
					<div className="container">
						<p style={{textAlign: 'center'}}>No hay blogposts.</p>
					</div>
				)}
			</div>
			<InstagramTwo />
		</LayoutFour>
	);
};

export default Blog;
