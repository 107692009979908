import React from "react";
import { connect } from "react-redux";
import { useSelector } from "react-redux";

function InstagramOne({ singleRow }) {
	const feed = useSelector((state) => state.shopReducers.feed);

	const normalizedFeed =
		feed && feed.length > 0
			? singleRow
				? feed.slice(0, 7)
				: feed.slice(0, 14)
			: [];
	return (
		<div className="instagram-one">
			<div className="instagram-one__background">
				{normalizedFeed.map((e, i) => (
					<a
						href={e.link}
						className="instagram-one__background__item"
						target="_blank"
						key={i}
					>
						<img src={e.img} alt="Instagram image" />
					</a>
				))}
			</div>
			<a
				href="https://www.instagram.com/lariales.uy"
				className="instagram-one__content"
			>
				<i className="fa fa-instagram"></i>
				<h5>Seguinos en instagram</h5>
				<p>@lariales.uy</p>
			</a>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		feed: state.feed,
	};
};

const WrappedInstagramFeed = connect(mapStateToProps, null)(InstagramOne);
export default WrappedInstagramFeed;
