import { product } from "./variables";

//get product
export const getProducts = (products, category, type, limit) => {
	category = category && category === "0" ? "" : category;
	const finalProducts = category
		? products.filter((product) => product.categoryId === category)
		: products;
	if (type && type === "new") {
		const newProducts = finalProducts.filter((product) => product.new);
		return newProducts.slice(0, limit ? limit : newProducts.length);
	} else {
		return (
			finalProducts &&
			finalProducts.slice(0, limit ? limit : finalProducts.length)
		);
	}
};

//get product by slug
export const getProductBySlug = (products, slug) => {
	return slug
		? products.find(
				(product) => product.slug.toLowerCase() === slug.toLowerCase()
		  )
		: null;
};

//sort product
export const getProductBySort = (products, type, limit) => {
	function sortWithCondition(arr, value, reverse = false) {
		let num = reverse ? -1 : 1;
		return products.slice(0, limit ? limit : products.length).sort((a, b) => {
			if (Number(a?.variants[0][value]) < Number(b?.variants[0][value])) {
				return -1 * num;
			}
			if (Number(a?.variants[0][value]) > Number(b?.variants[0][value])) {
				return 1 * num;
			}
			return 0;
		});
	}
	if (!type || type === "default") {
		return products;
	} else if (type === "lowToHigh") {
		return sortWithCondition(products, "price");
	} else if (type === "highToLow") {
		return sortWithCondition(products, "price", true);
	} else {
		return products.slice(0, limit ? limit : products.length);
	}
};

const biggerThan = (product, from) => {
	let ret = false;
	if (product && product.variants && product.variants.length > 0) {
		product.variants.forEach((p) => {
			if (Number(p.price) > from) {
				ret = true;
			}
		});
	}
	return ret;
};

const smallerThan = (product, from) => {
	let ret = false;
	if (product && product.variants && product.variants.length > 0) {
		product.variants.forEach((p) => {
			if (Number(p.price) < from) {
				ret = true;
			}
		});
	}
	return ret;
};

const betweenThan = (product, from, to) => {
	let ret = false;
	if (product && product.variants && product.variants.length > 0) {
		product.variants.forEach((p) => {
			if (Number(p.price) > from && Number(p.price) < to) {
				ret = true;
			}
		});
	}
	return ret;
};

//get product by price
export const getProductByPrice = (products, from, to, limit) => {
	if (from && !to) {
		return products.filter((p) => biggerThan(p, from) == true);
	} else if (!from && to) {
		return products.filter((p) => smallerThan(p, to) == true);
	} else if (!from && !to) {
		return products;
	} else {
		return products.filter((p) => betweenThan(p, from, to) == true);
	}
};

const hasSize = (product, size) => {
	let has = false;
	if (product && product.variants && product.variants.length > 0) {
		product.variants.forEach((p) => {
			if (p.id == size) {
				has = true;
			}
		});
	}
	return has;
};

//get product by brand
export const getProductByBrand = (products, brand) => {
	return brand ? products.filter((p) => hasSize(p, brand) == true) : products;
};

//get product by filter
export const getProductbyFilter = (
	products,
	sortType,
	category,
	from,
	to,
	brand,
	limit
) => {
	let sortedProduct;
	if (category || category == 0) {
		let productCategory = getProducts(products, category);
		if (to && !brand) {
			let productPrice = getProductByPrice(productCategory, from, to);
			sortedProduct = getProductBySort(productPrice, sortType);
		} else if (!to && brand) {
			let productBrand = getProductByBrand(productCategory, brand);
			sortedProduct = getProductBySort(productBrand, sortType);
		} else if (to && brand) {
			let productBrand = getProductByBrand(productCategory, brand);
			let productPrice = getProductByPrice(productCategory, from, to);
			console.log(productPrice);
			let commonProducts = productBrand.filter((o1) =>
				productPrice.some((o2) => o1.id === o2.id)
			);
			sortedProduct = getProductBySort(commonProducts, sortType);
		} else {
			sortedProduct = getProductBySort(productCategory, sortType);
		}
	} else {
		if (sortType) {
			sortedProduct = getProductBySort(products, sortType);
		} else {
			sortedProduct = products;
		}
	}
	return sortedProduct.slice(0, limit || products.length);
};
