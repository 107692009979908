import React from "react";
import PostCardTwo from "../Post/PostCardTwo";
import PostCardFeatured from "../Post/PostCardFeatured";

export default function BlogContent({ offset, search, data }) {
	const pageLimit = 7;
	return (
		<div className="blog-content">
			{data && (
				<div className="row">
					{offset === 0
						? data.slice(offset, offset + pageLimit).map((item, index) => {
								if (index === 0) {
									return (
										<div key={index} className="col-12">
											<PostCardFeatured data={item} />
										</div>
									);
								}
								return (
									<div key={index} className="col-12 col-md-6">
										<PostCardTwo data={item} />
									</div>
								);
						  })
						: data.slice(offset, offset + pageLimit).map((item, index) => {
								return (
									<div key={index} className="col-12 col-md-6">
										<PostCardTwo data={item} />
									</div>
								);
						  })}
				</div>
			)}
		</div>
	);
}
