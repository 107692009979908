import React from "react";
import { CSSTransition } from "react-transition-group";

import NavigatorMobile from "./NavigatorMobile";
import SocialIcons from "../../Other/SocialIcons";

export default function MobileNavSidebar({ showMobileNav, setShowMobileNav }) {
  return (
    <>

        <CSSTransition
          in={showMobileNav}
          unmountOnExit
          timeout={200}
          classNames="cart-sidebar"
        >
          <div className="navigation-sidebar">
            <NavigatorMobile />
            <div className="navigation-sidebar__footer">
              <SocialIcons />
            </div>
          </div>
        </CSSTransition>

      {showMobileNav && (

          <div
            className="overlay"
            onClick={() => setShowMobileNav(false)}
          ></div>

      )}
    </>
  );
}
