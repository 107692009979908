import React from "react";

import { useState } from "react";
import classNames from "classnames";

import Button from "../Control/Button";
import Modal from "../Control/Modal";

import vid from "../../assets/videos/videoEmpresa.mp4";
import poster from "../../assets/images/about/presentation.jpg";

export default function VideoFrame(props) {
	const [showModal, setShowModal] = useState(false);
	let { height, width, src, className } = props;
	height = height || 1080;
	return (
		<>
			<video
				className={`video-frame ${classNames(className)}`}
				style={{ height: height, width: width ? width : (height * 16) / 9 }}
				autoplay="autoplay"
				muted="true"
				loop="loop"
				id="myVideo"
				playsInline
			>
				<source src={vid} type="video/mp4" />
				Your browser does not support HTML5 video.
			</video>

			{/* {showModal && (
				<Modal
					showModal={showModal}
					setShowModal={setShowModal}
					height={400}
					width={700}
				>
					<iframe
						src={"https://www.youtube.com/embed/80e0QHPYRG4"}
						frameBorder="0"
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					/>
				</Modal>
			)} */}
		</>
	);
}
