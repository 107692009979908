import React, { Component } from "react";
import { PAYMENT_TYPES_NORMALIZED } from "../../../constants/payment_types";

const DELIVERY_METHODS = {
	pickUp: {
		id: "pickUp",
		name: "Retiro",
	},
	deliver: {
		id: "deliver",
		name: "Delivery",
	},
};

class OrderTR extends Component {
	state = {
		orderUpdateModal: true,
	};

	toggleOrderUpdateModal = () => {
		this.setState({
			orderUpdateModal: !this.state.orderUpdateModal,
		});
		this.props.onOrderModal(this.state.orderUpdateModal);
	};

	passId = (order) => {
		this.props.onPassId(order);
	};

	convertDateTime = (d) => {
		if (d) {
			let t = new Date(1970, 0, 1);

			if (t) {
				let time = t.setSeconds(d.seconds);
				const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
					time
				);
				const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(
					time
				);
				const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
					time
				);
				return `${da}-${mo}-${ye}`;
			}
		}
		return null;
	};

	statusBtn = (status) => {
		if (status === "pending") {
			return <span className="badge badge_warning">Pendiente</span>;
		} else if (status === "cancel") {
			return <span className="badge badge_danger">Rechazado</span>;
		} else {
			return <span className="badge badge_success">Aprobado</span>;
		}
	};

	getDeliveryOption = (method) => {
		let msg = "No hay información";
		Object.keys(DELIVERY_METHODS).forEach((o) => {
			if (o == method) {
				msg = DELIVERY_METHODS[o]?.name;
			}
		});
		return msg;
	};

	render() {
		let { orders } = this.props;
		let filteredOrders = orders;
		if (orders?.length) {
			filteredOrders = orders.sort((a, b) => {
				if (a.createdAt && b.createdAt) {
					return (
						new Date(this.convertDateTime(b.createdAt)) -
						new Date(this.convertDateTime(a.createdAt))
					);
				} else if (a.createdAt && !b.createdAt) {
					return -1;
				} else if (!a.createdAt && b.createdAt) {
					return 1;
				} else {
					return 0;
				}
			});
		}
		return (
			<React.Fragment>
				{filteredOrders.map((order, i) => {
					return (
						<tr
							key={i}
							onClick={(e) => {
								this.toggleOrderUpdateModal();
								this.passId(order);
							}}
						>
							<td>{`${order.customerDetails.firstName} ${order.customerDetails.lastName}`}</td>
							<td>{this.convertDateTime(order.createdAt)}</td>
							<td>
								<strong>{order.deliveryTime}</strong>
							</td>
							<td>{this.getDeliveryOption(order.deliveryMethod) || "-"}</td>
							<td>{PAYMENT_TYPES_NORMALIZED[order.paymentType] || "-"}</td>
							<td>{order.customerDetails?.rut || "-"}</td>{" "}
							<td>{order.customerDetails?.address || "-"}</td>
							<td>{order.customerDetails?.town || "-"}</td>
							<td>{order.customerDetails?.zip || "-"}</td>
							<td>{order.customerDetails?.phone || "-"}</td>
							<td>{order.customerDetails?.email || "-"}</td>
							<td>
								<strong>${order.amount}</strong>
							</td>
							<td>{this.statusBtn(order.status)}</td>
						</tr>
					);
				})}
			</React.Fragment>
		);
	}
}

export default OrderTR;
