import React, { useEffect, useState } from "react";

import LayoutFour from "../../../components/Layout/LayoutFour";
import { getPostById } from "../../../common/postSelect";
import BlogSidebar from "../../../components/Blog/BlogSidebar";
import PostContent from "../../../components/Blog/PostContent";
import InstagramTwo from "../../../components/Sections/Instagram/InstagramTwo";
import { withRouter } from "react-router-dom";
import alt from "../../../assets/images/blog/1.png";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import { isMobile } from "../../../common/utils";
import axios from "axios";
import backendUrl from "../../../constants/urls";

const override = css`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-self: center;
	justify-items: center;
	flex-direction: row;
	width: 100%;
	align-items: center;
`;

function Blogpost(props) {
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${backendUrl}/api/blogs/`, {
					headers: {
						accept: "application/json",
						"Accept-Language": "en-US,en;q=0.8",
					},
				});
				setData(response?.data);
			} catch (e) {}
			setIsLoading(false);
		};
		fetchData();
	}, []);

	const { match } = props;
	const { id } = match.params;
	const foundPost = getPostById(data, id);
	return isLoading ? (
		<BeatLoader
			css={override}
			size={isMobile() ? 20 : 50}
			color={"#10ABDE"}
			loading={true}
		/>
	) : (
		foundPost !== null && (
			<LayoutFour tilte={foundPost.title}>
				<div className="post">
					<div className="post__cover">
						<img src={foundPost.coverImageUrl || alt} alt={foundPost.mainTitle} />
					</div>
					<div className="post__body">
						<div className="container">
							<div className="row no-gutters">
								<div className="col-12 col-md-3">
									<div className="post__sidebar">
										<BlogSidebar limit={5} popularPostData={data} />
									</div>
								</div>
								<div className="col-12 col-md-9">
									<div className="post__content">
										<PostContent data={foundPost} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<InstagramTwo />
			</LayoutFour>
		)
	);
};

export default withRouter(Blogpost);
