import React from "react";
import { Link } from "react-router-dom";
import alt from "../../assets/images/blog/1.png";

export default function PostCardFeatured({ data }) {
	const convertDateTime = (d) => {
		let t = new Date(1970, 0, 1);
		if (t && d) {
			let time = t.setSeconds(d.seconds);
			const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
				time
			);
			const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(time);
			const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(time);
			return `${da}-${mo}-${ye}`;
		}
	};

	return (
		<div className="post-card-featured">
			<div className="post-card-featured__image">
				<img src={data.coverImageUrl || alt} alt={data.mainTitle} />
			</div>
			<div className="post-card-featured__content">
				<div className="post-card-featured__content__date">
					<h5> {convertDateTime(data.createdAt)}</h5>
				</div>
				<div className="post-card-featured__content__detail">
					<div className="post-card-featured__info">
						<Link to="#">
							<a>{data.category}</a>
						</Link>
					</div>
					<Link to={"/blog/post/" + data.id}>
						<a
							className="post-card-featured-title"
							style={{ textDecoration: "none" }}
						>
							{data.mainTitle}
						</a>
					</Link>
					<p className="post-card-featured-description">
						{data.content && data.content.length > 0
							? data.content[0].text
							: ""}
					</p>
				</div>
			</div>
		</div>
	);
}
