import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import ImageLightbox from "../Control/ImageLightbox";
import alt from "../../assets/images/blog/1.png";

export default function PostContent({ data, children }) {
	const convertDateTime = (d) => {
		let t = new Date(1970, 0, 1);
		if (t && d) {
			let time = t.setSeconds(d.seconds);
			const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
				time
			);
			const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(time);
			const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(time);
			return `${da}-${mo}-${ye}`;
		}
	};

	let youtubeLink = "";
	let imageUrls = [];
	if (!data) {
		// return <Redirect to='/blog' />;
	} else {
		if (data.imageUrlSecond) {
			imageUrls.push(data.imageUrlSecond);
		}
		if (data.imageUrlThird) {
			imageUrls.push(data.imageUrlThird);
		}
		if (data.imageUrlFourth) {
			imageUrls.push(data.imageUrlFourth);
		}
		if (data.videoLink) {
			const aux = data.videoLink.split("?v=");
			if (aux && aux.length > 1) {
				youtubeLink = aux[1];
			}
		}
	}

	return (
		<div className="post-content">
			<div className="post-content__header">
				<div className="post-content__header__date">
					<h5>{convertDateTime(data.createdAt)}</h5>
				</div>
				<div className="post-content__header__content">
					<div className="post_content__info">
						<Link to="#">
							<a>{data.category}</a>
						</Link>
					</div>
					<h2>{data.mainTitle}</h2>
				</div>
			</div>
			<div className="post-content__body">
				{imageUrls && imageUrls.length > 0 && (
					<ul className="wp-block-gallery columns-3">
						{imageUrls.map((i) => (
							<li
								className={
									imageUrls.length == 1
										? "li-for1"
										: imageUrls.length == 2
										? "li-for2"
										: ""
								}
							>
								<figure>
									<img src={i} alt="image" />
								</figure>
							</li>
						))}
					</ul>
				)}

				{/* <ImageLightbox className="post-image" alt="post image" imgSrc={alt} /> */}

				{data &&
					data.content &&
					data.content.length > 0 &&
					data.content.map((c) => (
						<div>
							<h3>{c.title}</h3>
							<p className="post-paragraph">{c.text}</p>
						</div>
					))}

				{data.quote && (
					<div className="post-quote">
						<h5> {data.quote}</h5>
						<div className="post-quote__author">
							<i className="fa fa-quote-left"></i>
						</div>
					</div>
				)}

				{data.videoLink && (
					<div className="article-video">
						<iframe
							src={`https://www.youtube.com/embed/${youtubeLink}`}
						></iframe>
					</div>
				)}
			</div>
		</div>
	);
}
