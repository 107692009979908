//get post
export const getPosts = (posts, category, limit) => {
  const finalPosts = category
    ? posts.filter(
        (post) => post.category.toLowerCase() === category.toLowerCase()
      )
    : posts;

  return (
    finalPosts &&
    finalPosts.slice(0, limit ? limit : finalPosts.length)
  );
};

//get category quantity
export const getCategoryQuantity = (posts, category) => {
  return category
    ? posts.filter(
        (post) => post.category.toLowerCase() === category.toLowerCase()
      ).length
    : 0;
};

//get post by slug
export const getPostById = (posts, id) => {
  return id && posts
    ? posts.find((post) => post.id === id)
    : null;
};

//get post by keyword
export const getPostByKeyword = (posts, keyword) => {
  return keyword
    ? posts.filter((post) => post.title.toLowerCase().includes(keyword))
    : posts;
};
