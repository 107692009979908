import React from 'react';
import { ToastContainer, toast, Flip } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import { firebase } from '../../firebase';
import { addProducts } from "../../store/actions/cartActions";
import { fetchProducts } from '../../methods/api-calls';
import { connect } from "react-redux";

class Login extends React.Component {
    state = {
        email: '',
        password: '',
        redirectNow: false,
    };

    async componentDidMount() {
        const { products } = this.props;
        if(!products || products && products.length == 0) {
            const newProducts = await fetchProducts();
            this.props.addProducts(newProducts)
        }
    }

    handleSignInWithEmail = (e) => {
        e.preventDefault();
        firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
        .then(() => {
            toast.success('Has iniciado sesión', {
                position: "bottom-left",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            setTimeout(() => {
                this.setState({
                    redirectNow: true
                })
            }, 1500);
        })
        .catch(function(error) {
            toast.error(error.message, {
                position: "bottom-left",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            console.log(error)
        });
    }
    render() {
        if(this.state.redirectNow) {
            return <Redirect to= '/admin/dashboard' />
        }
        return (
            <div className="admin-signin ptb-100">
                <div className="d-table">
                    <div className="d-table-cell">
                        <ToastContainer
                            transition={Flip} 
                        />
 
                        <div className="container">
                            <div className="login-content">
                                <h3 style={{marginBottom: '20px', width: '400px'}}>Panel Admin</h3>

                                <form onSubmit={this.handleSignInWithEmail} className="login-form">
                                    <div className="form-group">
                                        <input 
                                            type="email" 
                                            className="form-control" 
                                            placeholder="Email" 
                                            value={this.state.email}
                                            onChange={e => this.setState({ email: e.target.value })}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <input 
                                            type="password" 
                                            className="form-control" 
                                            placeholder="Contraseña"
                                            value={this.state.password}
                                            onChange={e => this.setState({ password: e.target.value })} 
                                        />
                                    </div>

                                    <button type="submit" className="btn" style={{backgroundColor: '#10ABDE', color: 'white'}}>Iniciar sesión</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
	return {
		products: state.products,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addProducts: (products) => {
			dispatch(addProducts(products));
		},
	};
};
const WrappedLogin = connect(mapStateToProps, mapDispatchToProps)(Login);
export { WrappedLogin as Login } 