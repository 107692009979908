import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import { PrevArrow, NextArrow } from "../../Other/SliderArrow";
import noPhoto from "../../../assets/images/product/noPhoto.png";

export default function ProductDetailSlideTwo({ data }) {
  const slider1Settings = {
    arrows: false,
    swipe: false,
  };
  const slider2Settings = {
    arrows: false,
    focusOnSelect: true,
    slidesToShow: 2,
    centerMode: true,
    centerPadding: "80px",
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          centerPadding: "50px",
        },
      },
    ],
  };
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  let allPics = [...data?.thumbImage, ...data?.images];
  if(!(allPics && allPics.length > 0)){
    allPics = [noPhoto]
  }
  return (
    <div className="product-detail__slide-two">
      <div className="product-detail__slide-two__big">
        <Slider asNavFor={nav2} ref={(c) => setNav1(c)} {...slider1Settings}>
          {data &&
            allPics.map((img, index) => (
              <div key={index} className="slider__item">
                <img src={img} alt="Product image" />
              </div>
            ))}
        </Slider>
      </div>
      <div className="product-detail__slide-two__small">
        <Slider asNavFor={nav1} ref={(c) => setNav2(c)} {...slider2Settings}>
          {data &&
            allPics.map((img, index) => (
              <div key={index} className="slider__item">
                {/* <img src={img || argan} alt="Product image" /> */}
                <img src={img} alt="Product image" /> 
              </div>
            ))}
        </Slider>
      </div>
    </div>
  );
}
