import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";

import CartItem from "./CartItem";
import ClientOnlyPortal from "../../../common/ClientOnlyPortal";
import Button from "../../Control/Button";
import { calculateTotalPrice } from "../../../common/shopUtils";
import noPhoto from '../../../assets/images/product/noPhoto.png';

function CartItemsSidebar({ showCart, setShowCart }) {
	const cartState = useSelector((state) => state.cartReducer);
	const tasksRef = React.useRef(null);

	const calculate = (item) => {
    const withoutDiscount = item.variants && item.variants.length > 0 && item.selectedColor ? item.variants.find(i => i.id === item.selectedColor)?.price : 0;
    const withDiscount = item.discount && item.discount > 0 ? withoutDiscount - Math.round(withoutDiscount * item.discount / 100) : withoutDiscount
    return withDiscount;
  }

	return (
		<>
			{/* <ClientOnlyPortal selector="#cart-sidebar" tasksRef={tasksRef}> */}
			<CSSTransition
				in={showCart}
				unmountOnExit
				timeout={200}
				classNames="cart-sidebar"
			>
				<div className="cart-items__wrapper">
					<h2>Mi carrito</h2>
					{cartState.length === 0 ? (
						<h3 className="empty-noti">No has añadido productos.</h3>
					) : (
						<>
							{cartState.length !== 0 &&
								cartState.map((item) => (
									<CartItem
										key={item.cartId}
										image={item.thumbImage && item.thumbImage.length > 0 ? item.thumbImage[0] : (item.images  && item.images.length > 0 ? item.images[0] : noPhoto)}
										name={item.name}
										price={calculate(item)}
										quantity={item.cartQuantity}
										color={item.selectedColor}
										unit={item.unit}
										cartId={item.cartId}
										slug={item.slug}
									/>
								))}
							<div className="cart-items__total">
								<div className="cart-items__total__price">
									<h5>Total</h5>
									<span>${calculateTotalPrice(cartState)}</span>
								</div>
								<div className="cart-items__total__buttons">
									<Link to="/shop/cart">
										<Button width="100%" color="dark" content="Ver carrito" />
									</Link>

									<Link to="/shop/checkout">
										<Button width="100%" color="red" content="Comprar" />
									</Link>
								</div>
							</div>
						</>
					)}
				</div>
			</CSSTransition>
			{/* </ClientOnlyPortal> */}

			{showCart && (
				<div className="overlay" onClick={() => setShowCart(false)}></div>
			)}
		</>
	);
}

export default CartItemsSidebar;
