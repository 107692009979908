import React, { useRef, useEffect } from "react";
import Parallax from "parallax-js";

import Button from "../../Control/Button";
import SectionTitleOne from "../SectionTitle/SectionTitleOne";
import pic1 from '../../../assets/images/about/aa2.jpg';
import pic2 from '../../../assets/images/about/aa1.jpeg';
import pic3 from '../../../assets/images/about/15100.png';
import pic4 from '../../../assets/images/about/16298.png';
import pic5 from '../../../assets/images/about/16295.png';

export default function IntroductionOne({ data }) {
  const bg1 = useRef(null);
  const bg2 = useRef(null);
  useEffect(() => {
    let parallax1 = new Parallax(bg1.current);
    let parallax2 = new Parallax(bg2.current);
    return () => {
      parallax1.disable();
      parallax2.disable();
    };
  }, []);
  return (
    <div className="introduction-one">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-6">
            <div className="introduction-one__image">
              <div className="introduction-one__image__detail">
                <img
                  src={pic1}
                  alt="background"
                />
                <img
                  src={pic5}
                  alt="background"
                />
              </div>
              <div className="wrapper">
                <div className="introduction-one__image__background">
                  <div className="background__item">
                    <div ref={bg1} className="wrapper">
                      <img
                        data-depth="0.5"
                        src={pic3}
                        alt="background"
                      />
                    </div>
                  </div>
                  <div className="background__item">
                    <div ref={bg2} className="wrapper">
                      <img
                        data-depth="0.3"
                        data-invert-x
                        data-invert-y
                        src={pic2}
                        alt="background"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="introduction-one__content">
              <h5>
                {data.subTitle.main} <span>{data.subTitle.span}</span>
              </h5>
              <SectionTitleOne spaceBottom="1.875em">
                {data.title.main}
                <br />
                {data.title.span}
              </SectionTitleOne>
              {
                data?.description?.length && data.description.map(d => (
                  <p>{d}</p>
                ))
              }
              <Button
                color={data.btn.color}
                content={data.btn.content}
                action="#"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
