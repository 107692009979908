import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
const config = {
    apiKey: "AIzaSyDTcYG2zaogjyTyJI0t66lUwvwNtZGPuRI",
    authDomain: "lariales-front.firebaseapp.com",
    databaseURL: "https://lariales-front.firebaseio.com",
    projectId: "lariales-front",
    storageBucket: "lariales-front.appspot.com",
    messagingSenderId: "293901442942",
    appId: "1:293901442942:web:10743050748111c705dd2f",
    measurementId: "G-PRQB7F8DYN"
};
if (!firebase.apps.length) {
    firebase.initializeApp(config);
    firebase.firestore().settings({ experimentalForceLongPolling: true })
}
const auth = firebase.auth();
export {
    auth,
    firebase
};