import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";

import products from "../../../data/products.json";
import { getProductBySlug } from "../../../common/productSelect";
import ProductDetail from "../../../components/ProductDetail/ProductDetail";
import InstagramTwo from "../../../components/Sections/Instagram/InstagramTwo";
import LayoutOne from "../../../components/Layout/LayoutOne";
import {
	Breadcrumb,
	BreadcrumbItem,
} from "../../../components/Other/Breadcrumb";
import ProductSlideTwo from "../../../components/Sections/ProductThumb/ProductSlide/ProductSlideTwo";
import axios from "axios";
import { product } from "../../../common/variables";
import backendUrl from "../../../constants/urls";
import soap from "../../../assets/images/banners/soap-2.jpg";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import { isMobile } from "../../../common/utils";

const override = css`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin: 22% auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-self: center;
	justify-items: center;
	flex-direction: row;
	width: 100%;
	align-items: center;
`;

const ProductById = (props) => {
	const slug = props.match.params.slug;
	const [products, setProducts] = useState([]);
	const [foundProduct, setFoundProduct] = useState(null);
	const [loading, setLoading] = useState(true);

	const onReviewSubmit = (data) => {
		console.log(data);
	};

	let mobile = isMobile();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${backendUrl}/api/products/`, {
					headers: {
						accept: "application/json",
						"Accept-Language": "en-US,en;q=0.8",
					},
				});
				setProducts(response.data);
				setFoundProduct(getProductBySlug(response.data, slug));
			} catch (e) {
				alert(e);
			}
			setLoading(false);
		};
		fetchData();
	}, []);

	const filteredProducts =
		products && products.length > 0
			? products.filter(
					(p) =>
						p.id != foundProduct?.id &&
						p.categoryId === foundProduct?.categoryId
			  )
			: [];

	return loading ? (
		<BeatLoader
			css={override}
			size={mobile ? 20 : 50}
			color={"#10ABDE"}
			loading={true}
		/>
	) : foundProduct ? (
		<LayoutOne title={foundProduct.name}>
			<Breadcrumb title="Detalles" img={soap}>
				<BreadcrumbItem name="Inicio" />
				<BreadcrumbItem name="Shop" />
				<BreadcrumbItem name={foundProduct.name} current />
			</Breadcrumb>
			<ProductDetail data={foundProduct} onReviewSubmit={onReviewSubmit} />
			{filteredProducts && filteredProducts.length > 0 && (
				<ProductSlideTwo data={filteredProducts} />
			)}
			<InstagramTwo />
		</LayoutOne>
	) : (
		<p style={{ margin: "20% 30%", width: "100%" }}>
			No hemos encontrado el producto.
			<Link to="/">Volver a la página principal</Link>
		</p>
	);
};

export default withRouter(ProductById);
