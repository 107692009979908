import React, { useState } from "react";
import { useForm } from "react-hook-form";

import backendUrl from "../../constants/urls";
import LayoutFour from "../../components/Layout/LayoutFour";
import InstagramTwo from "../../components/Sections/Instagram/InstagramTwo";
import { Breadcrumb, BreadcrumbItem } from "../../components/Other/Breadcrumb";
import ContactInfoItem from "../../components/Pages/Contact/ContactInfoItem";
import contactData from "../../data/pages/contact.json";
import axios from "axios";
import products from '../../assets/images/banners/products-alt-2.jpg'

export default function () {
	const [message, setMessage] = useState('');

	const { register, handleSubmit, watch, errors, reset } = useForm();

	const onSubmit = async (data) => {
		const { email, name, message } = data;
		const body = {
			name: name,
			email: email,
			message: message,
		};

		try {
			await axios.post(`${backendUrl}/api/mailer/contact`, body, {
				headers: {
					accept: "application/json",
					"Accept-Language": "en-US,en;q=0.8",
				},
			});
			setMessage('Se ha enviado su mensaje, le responderemos a la brevedad. Gracias.');
			reset();
		} catch (e) {
			setMessage('Ha ocurrido un error, inténtelo más tarde.');
		}
	};

	return (
		<LayoutFour title="Contacto">
			<Breadcrumb title="Contacto" img={products}>
				<BreadcrumbItem name="Inicio" />
				<BreadcrumbItem name="Contacto" current />
			</Breadcrumb>
			<div className="contact">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-6">
							<h3 className="contact-title">Información de contacto:</h3>
							{contactData &&
								contactData.map((item, index) => (
									<ContactInfoItem
										key={index}
										iconClass={item.iconClass}
										title={item.title}
										detail={item.detail}
									/>
								))}
						</div>
						<div className="col-12 col-md-6">
							<h3 className="contact-title">Escribinos</h3>
							<div className="contact-form">
								<form onSubmit={handleSubmit(onSubmit)}>
									<div className="input-validator">
										<input
											type="text"
											name="name"
											placeholder="Nombre"
											ref={register({ required: true })}
										/>
										{errors.name && (
											<span className="input-error">
												Este campo es requerido
											</span>
										)}
									</div>
									<div className="input-validator">
										<input
											type="text"
											name="email"
											placeholder="Email"
											ref={register({ required: true })}
										/>
										{errors.email && (
											<span className="input-error">
												Este campo es requerido
											</span>
										)}
									</div>
									<div className="input-validator">
										<textarea
											type="text"
											name="message"
											cols="30"
											ref={register({ required: true })}
											rows="3"
											placeholder="Mensaje"
										/>
										{errors.message && (
											<span className="input-error">
												Este campo es requerido
											</span>
										)}
									</div>
									<button className="btn -dark">ENVIAR MENSAJE</button>
									{
										message && (
											<p style={{marginTop: '10px'}}>{message}</p>
										)
									}
								</form>
							</div>
						</div>
						<div className="col-12">
							<iframe
								className="contact-map"
								src="https://maps.google.com/maps?q=lariales%20montevideo&t=&z=13&ie=UTF8&iwloc=&output=embed"
								width="100%"
								height="450"
								frameBorder="0"
								allowFullScreen
							/>
						</div>
					</div>
				</div>
			</div>
			<InstagramTwo />
		</LayoutFour>
	);
}
