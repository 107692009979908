import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";

import Button from "../../Control/Button";
import SectionTitleOne from "../SectionTitle/SectionTitleOne";
import Quantity from "../../../components/Control/Quantity";

export default function IntroductionSeven({ data }) {
  const [currentAccordionIndex, setCurrentAccordionIndex] = useState(1);
  const [quantity, setQuantity] = useState();
  return (
    <div className="introduction-seven">
      <div className="introduction-seven__wrapper -bottom">
        <div className="introduction-seven__wrapper__content">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-12 col-lg-12 col-xl-12 ml-auto">
                <div className="introduction-seven__wrapper__content__detail">
                  <Accordion
                    defaultActiveKey={parseInt(currentAccordionIndex)}
                    onSelect={(i) => setCurrentAccordionIndex(i)}
                  >
                    {data &&
                      data.map((item, index) => (
                        <Card key={index}>
                          <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                            {item.header}
                            <i
                              className={`fa fa-angle-${
                                parseInt(currentAccordionIndex) === item.id
                                  ? "up"
                                  : "down"
                              }`}
                            />
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey={item.id}>
                            <Card.Body>
                              <p>{item.body}</p>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      ))}
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
