import React, { Component } from "react";
import { toast, ToastContainer } from "react-toastify";
import { withRouter } from "react-router-dom";
import { firebase } from "../../../firebase";
import axios from "axios";
import backendUrl from "../../../constants/urls";
import example1 from "../../../assets/images/product/example1.png";
import { PRODUCT_SIZES } from "../../../constants/product_sizes";

class EditProductModal extends Component {
	state = {
		modal: false,
		imageUrls: [],
		imageCount: 0,
		imageHoverUrls: [],
		imageHoverCount: 0,
		quantity: 0,
		title: "",
		productId: null,
		description: "",
		unit: "cc",
		discount: 0,
		code: "",
		currentBenefits: "",
		variants: [],
		currentDoses: "",
		currentUses: "",
		benefits: [],
		doses: [],
		uses: [],
		isNotEaten: false,
		new: false,
		categoryId: null,
		previewImages: [],
		previewImagesHover: [],
	};

	getAvailableVariants = (newVariants) => {
		let finalVariants = [];

		let allVariants = PRODUCT_SIZES.map((p) => ({
			id: p,
			value: p,
			isChecked: false,
			price: 0,
		}));

		if (newVariants && newVariants.length > 0) {
			const aux = allVariants.filter(
				(v) => !newVariants.find((n) => n.id == v.id)
			);
			const withoutOrder = newVariants.concat(aux);
			finalVariants = withoutOrder.sort((a, b) => {
				let auxA = a?.id;
				let auxB = b?.id;
				let aValue = "";
				let bValue = "";

				aValue = auxA;

				bValue = auxB;

				if (Number(aValue) < Number(bValue)) {
					return -1;
				}
				if (Number(aValue) > Number(bValue)) {
					return 1;
				}
				return 0;
			});
		} else {
			finalVariants = allVariants;
		}

		return finalVariants;
	};

	async componentDidMount() {
		let { product } = this.props;

		const response = await axios.get(`${backendUrl}/api/categories/`, {
			headers: {
				accept: "application/json",
				"Accept-Language": "en-US,en;q=0.8",
			},
		});
		this.setState({
			allCategories: response.data?.filter((c) => c.title != "Todos"),
		});

		if (product) {
			this.setState({
				productId: product.id,
				imageUrls: product.images,
				imageHoverUrls: product.thumbImage,
				oldImageUrls: product.images,
				oldImageHoverUrls: product.thumbImage,
				title: product.name,
				code: product.code,
				slug: product.slug,
				description: product.description,
				categoryId: product.categoryId,
				discount: product.discount,
				unit: product.unit || "cc",
				quantity: product.quantity,
				benefits: product.benefits,
				variants: this.getAvailableVariants(product.variants),
				uses: product.uses,
				doses: product.doses,
				newProduct: product.new,
				isNotEaten: product.isNotEaten,
				previewImages: product.images,
				previewImagesHover: product.thumbImage,
			});
		}
	}

	noneIsChecked = () => {
		let noneChecked = true;
		if (this.state.variants && this.state.variants.length > 0) {
			this.state.variants.forEach((v) => {
				if (v.isChecked && v.price && v.price > 0) {
					noneChecked = false;
				}
			});
		}
		return noneChecked;
	};

	baseState = this.state;
	resetForm = () => {
		this.setState(this.baseState);
	};

	closeModal = () => {
		this.props.onClick(this.state.modal);
	};

	addBenefit = (e) => {
		e.preventDefault();
		const { currentBenefits } = this.state;
		if (currentBenefits) {
			this.setState((prevState) => ({
				benefits: [...prevState.benefits, currentBenefits],
				currentBenefits: "",
			}));
		}
	};

	addUse = (e) => {
		e.preventDefault();
		const { currentUses } = this.state;
		if (currentUses) {
			this.setState((prevState) => ({
				uses: [...prevState.uses, currentUses],
				currentUses: "",
			}));
		}
	};

	addDose = (e) => {
		e.preventDefault();
		const { currentDoses } = this.state;
		if (currentDoses) {
			this.setState((prevState) => ({
				doses: [...prevState.doses, currentDoses],
				currentDoses: "",
			}));
		}
	};

	removeUses = (e) => {
		e.preventDefault();
		this.setState({
			uses: [],
		});
	};

	removeDoses = (e) => {
		e.preventDefault();
		this.setState({
			doses: [],
		});
	};

	removeBenefits = (e) => {
		e.preventDefault();
		this.setState({
			benefits: [],
		});
	};

	handleChange = (e) => {
		const { imageCount } = this.state;
		if (imageCount < 4) {
			if (e.target.files[0]) {
				let image = e.target.files[0];
				let reader = new FileReader();
				reader.readAsDataURL(image);
				reader.onload = () => {
					this.setState({
						previewImages:
							imageCount === 0 //Sobreescribir
								? [reader.result]
								: [...this.state.previewImages, reader.result],
					});
				};
				this.handleUpload(image);
			}
		}
	};

	handleUpload = (image) => {
		const data = new FormData();
		if (image) {
			data.append("productImage", image, image.name);
			axios
				.post(`${backendUrl}/api/upload`, data, {
					headers: {
						accept: "application/json",
						"Accept-Language": "en-US,en;q=0.8",
						"Content-Type": `multipart/form-data; boundary=${data._boundary}`,
					},
				})
				.then((response) => {
					if (200 === response.status) {
						// If file size is larger than expected.
						if (response.data.error) {
							if ("LIMIT_FILE_SIZE" === response.data.error.code) {
								alert("Max size: 2MB");
							} else {
								// If not the given file type
								alert(response.data.error);
							}
						} else {
							// Success
							let fileName = response.data;
							this.setState({
								imageUrls:
									this.state.imageCount == 0
										? [fileName.location]
										: [...this.state.imageUrls, fileName.location],
								imageCount: this.state.imageCount + 1,
							});
							console.log("fileName", fileName.location);
							// alert( 'File Uploaded', '#3089cf' );
						}
					}
				})
				.catch((error) => {
					// If another error
					alert(error, "red");
				});
		} else {
			// if file not selected throw error
			alert("Please upload file");
		}
	};

	handleChangeHover = (e) => {
		const { imageHoverCount } = this.state;
		if (imageHoverCount < 2) {
			if (e.target.files[0]) {
				let imageHover = e.target.files[0];
				let reader = new FileReader();
				reader.readAsDataURL(imageHover);
				reader.onload = () => {
					// console.log(reader.result);
					this.setState({
						previewImagesHover:
							imageHoverCount == 0
								? [reader.result]
								: [...this.state.previewImagesHover, reader.result],
					});
				};
				this.handleUploadHover(imageHover);
			}
		}
	};

	handleUploadHover = (imageHover) => {
		const data = new FormData();
		if (imageHover) {
			data.append("productImageHover", imageHover, imageHover.name);
			axios
				.post(`${backendUrl}/api/upload-hover`, data, {
					headers: {
						accept: "application/json",
						"Accept-Language": "en-US,en;q=0.8",
						"Content-Type": `multipart/form-data; boundary=${data._boundary}`,
					},
				})
				.then((response) => {
					if (200 === response.status) {
						// If file size is larger than expected.
						if (response.data.error) {
							if ("LIMIT_FILE_SIZE" === response.data.error.code) {
								alert("Max size: 2MB");
							} else {
								console.log(response.data);
								// If not the given file type
								// alert( response.data.error, 'red' );
							}
						} else {
							// Success
							let fileName = response.data;
							this.setState({
								imageHoverUrls:
									this.state.imageHoverCount == 0
										? [fileName.location]
										: [...this.state.imageHoverUrls, fileName.location],
								imageHoverCount: this.state.imageHoverCount + 1,
							});
							// console.log( 'fileName', fileName.location );
							// alert( 'File Uploaded', '#3089cf' );
						}
					}
				})
				.catch((error) => {
					// If another error
					alert(error);
				});
		} else {
			// if file not selected throw error
			alert("Please upload file");
		}
	};

	convertToSlug = (word) => {
		return word.split(" ").join("-").toLowerCase();
	};

	parseToBoolean = (val) => {
		if (typeof val === "boolean") {
			return Boolean(val);
		} else if (val == "true") {
			return Boolean(true);
		} else {
			return Boolean(false);
		}
	};

	editProduct = async (e) => {
		e.preventDefault();
		let {
			imageHoverUrls,
			title,
			description,
			imageUrls,
			discount,
			isNotEaten,
			quantity,
			benefits,
			uses,
			variants,
			doses,
			code,
			categoryId,
			newProduct,
			productId,
		} = this.state;

		const product = {
			id: productId,
			images: imageUrls || [],
			thumbImage: imageHoverUrls || [],
			name: title,
			code: code,
			slug: this.convertToSlug(title),
			description: description,
			categoryId: categoryId,
			discount: Number(discount),
			quantity: Number(quantity),
			benefits: benefits,
			variants:
				variants.filter((v) => v.isChecked && v.price && v.price > 0) || [],
			uses: uses,
			doses: doses,
			new: this.parseToBoolean(newProduct),
			isNotEaten: this.parseToBoolean(isNotEaten),
		};
		if (productId && title != "" && !this.noneIsChecked() && quantity > 0) {
			try {
				await axios.post(`${backendUrl}/api/products/edit`, product);

				toast.success("Producto editado exitosamente.", {
					position: "bottom-left",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				setTimeout(() => {
					window.location.reload();
				}, 3000);
			} catch (e) {
				console.log(e);
				const { message } = e;
				toast.error("Ha ocurrido un error", {
					position: "bottom-left",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				setTimeout(() => {
					window.location.reload();
				}, 3000);
			}
			this.closeModal();
			this.resetForm();
		} else {
			toast.error("Rellene los campos *", {
				position: "bottom-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	};

	handleAllChecked = (event) => {
		let variants = this.state.variants;
		variants.forEach((variant) => (variant.isChecked = event.target.checked));
		this.setState({ variants: variants });
	};

	handleCheckFieldElement = (event) => {
		let variants = this.state.variants;
		variants.forEach((variant) => {
			if (variant.value === event.target.value)
				variant.isChecked = event.target.checked;
		});
		this.setState({ variants: variants });
	};

	handlePrice = (event, id) => {
		let variants = this.state.variants;
		variants.forEach((variant) => {
			if (variant.id === id) variant.price = event.target.value;
		});
		this.setState({ variants: variants });
	};

	render() {
		console.log(this.state);
		let { previewImagesHover, previewImages } = this.state;
		return (
			<React.Fragment>
				<div className={`admin-product-modal ${this.props.active}`}>
					<ToastContainer position="bottom-left" autoClose={3000} />

					<div className="modal-innter-content">
						<button type="button" className="close" onClick={this.closeModal}>
							<span aria-hidden="true">
								<i className="bx bx-x"></i>
							</span>
						</button>

						<div className="modal-body">
							<h3>Editar producto</h3>

							<form onSubmit={this.editProduct}>
								*Nota: Los nombres de las fotos NO pueden tener ni ( ni /
								caracteres.
								<div className="form-group">
									<label>
										Subir las imagenes principales aquí (2 imágenes)
									</label>
									<div className="row">
										<div className="col-lg-4 col-md-6">
											<p>Ejemplo:</p>
											<img style={{ height: "200px" }} src={example1} />
										</div>
										<div className="col-lg-8 col-md-6 upload-img">
											<span>
												<i className="bx bxs-image-add"></i>
												Seleccionar
											</span>

											<input
												type="file"
												disabled={this.state.imageHoverCount > 1}
												className="form-control-file"
												name="productImageHover"
												onChange={this.handleChangeHover}
											/>
										</div>
									</div>

									{previewImagesHover && previewImagesHover.length > 0 ? (
										<div className="uploaded-img">
											{previewImagesHover.map((e) => (
												<img src={e} alt="Image" className="img-thumbnail" />
											))}
										</div>
									) : null}
								</div>
								<div className="form-group">
									<label>
										Subir el resto de las imágenes aquí (Hasta 4 fotos)
									</label>
									<div className="upload-img">
										<span>
											<i className="bx bxs-image-add"></i>
											Seleccionar
										</span>

										<input
											type="file"
											disabled={this.state.imageCount > 3}
											className="form-control-file"
											name="productImage"
											accept="image/*"
											onChange={this.handleChange}
										/>
									</div>

									{previewImages && previewImages.length > 0 ? (
										<div className="uploaded-img">
											{previewImages.map((e) => (
												<img src={e} alt="Image" className="img-thumbnail" />
											))}
										</div>
									) : null}
								</div>
								<h4 className="title">Información del producto:</h4>
								<div className="form-group">
									<label>Nombre *</label>
									<input
										type="text"
										className="form-control"
										value={this.state.title}
										onChange={(e) => this.setState({ title: e.target.value })}
									/>
								</div>
								<div className="form-group">
									<label>Categoría</label>
									<select
										className="form-control"
										onChange={(e) =>
											this.setState({ categoryId: e.target.value })
										}
										value={this.state.categoryId}
									>
										{this.state.allCategories &&
										this.state.allCategories.length > 0 ? (
											this.state.allCategories.map((c) => (
												<option value={c.id}>{c.title}</option>
											))
										) : (
											<p>No hay categorias ingresadas.</p>
										)}
									</select>
								</div>
								<div className="form-group">
									<label>Código</label>
									<input
										type="text"
										className="form-control"
										value={this.state.code}
										onChange={(e) => this.setState({ code: e.target.value })}
									/>
								</div>
								<div className="form-group">
									<label>Descripción</label>
									<textarea
										className="form-control"
										value={this.state.description}
										onChange={(e) =>
											this.setState({ description: e.target.value })
										}
										rows="3"
									/>
								</div>
								<div className="form-group">
									<label>Cantidad disponible * </label>
									<input
										type="number"
										className="form-control"
										value={this.state.quantity}
										onChange={(e) =>
											this.setState({ quantity: e.target.value })
										}
									/>
								</div>
								<div className="form-group">
									<label>Propiedades y beneficios</label>
									<input
										type="text"
										className="form-control"
										value={this.state.currentBenefits}
										onChange={(e) =>
											this.setState({ currentBenefits: e.target.value })
										}
									/>
									<button onClick={this.addBenefit}>+</button>
									<button onClick={this.removeBenefits}>Borrar todo</button>
									<ul>
										{this.state.benefits && this.state.benefits.length > 0
											? this.state.benefits.map((i) => <li>{i}</li>)
											: "No agregaste propiedades."}
									</ul>
								</div>
								<div className="form-group">
									<label>Usos</label>
									<input
										type="text"
										className="form-control"
										value={this.state.currentUses}
										onChange={(e) =>
											this.setState({ currentUses: e.target.value })
										}
									/>
									<button onClick={this.addUse}>+</button>
									<button onClick={this.removeUses}>Borrar todo</button>
									<ul>
										{this.state.uses && this.state.uses.length > 0
											? this.state.uses.map((i) => <li>{i}</li>)
											: "No agregaste usos."}
									</ul>
								</div>
								<div className="form-group">
									<label>Tamaños y precios *</label>
									<div className="row">
										<div className="col-lg-4 col-md-4">
											<label>Unidades</label>
											<select
												className="form-control"
												onChange={(e) =>
													this.setState({ unit: e.target.value })
												}
												value={this.state.unit}
											>
												<option value="cc">cc</option>
												<option value="ml">ml</option>
												<option value="grs">grs</option>
											</select>
										</div>
									</div>
									<div className="row">
										<div className="col-lg-6 col-md-6">
											<ul>
												{this.state.variants &&
													this.state.variants.length > 0 &&
													this.state.variants.map((v) => (
														<li>
															<input
																key={v.id}
																onClick={this.handleCheckFieldElement}
																type="checkbox"
																checked={v.isChecked}
																value={v.value}
															/>{" "}
															{v.value}
														</li>
													))}
											</ul>
										</div>
										<div className="col-lg-6 col-md-6">
											<input
												style={{ fontSize: "0.7rem", marginBottom: "2px" }}
												placeholder={`Precio para 30${this.state.unit}`}
												type="number"
												className="form-control"
												value={
													this.state.variants.find((v) => v.id === "30")
														?.price > 0
														? this.state.variants.find((v) => v.id === "30")
																?.price
														: null
												}
												onChange={(e) => this.handlePrice(e, "30")}
											/>

											<input
												style={{ fontSize: "0.7rem", marginBottom: "2px" }}
												placeholder={`Precio para 100${this.state.unit}`}
												type="number"
												className="form-control"
												value={
													this.state.variants.find((v) => v.id === "100")
														?.price > 0
														? this.state.variants.find((v) => v.id === "100")
																?.price
														: null
												}
												onChange={(e) => this.handlePrice(e, "100")}
											/>

											<input
												style={{ fontSize: "0.7rem", marginBottom: "2px" }}
												placeholder={`Precio para 500${this.state.unit}`}
												type="number"
												className="form-control"
												value={
													this.state.variants.find((v) => v.id === "500")
														?.price > 0
														? this.state.variants.find((v) => v.id === "500")
																?.price
														: null
												}
												onChange={(e) => this.handlePrice(e, "500")}
											/>

											<input
												style={{ fontSize: "0.7rem", marginBottom: "2px" }}
												placeholder={`Precio para 1000${this.state.unit}`}
												type="number"
												className="form-control"
												value={
													this.state.variants.find((v) => v.id === "1000")
														?.price > 0
														? this.state.variants.find((v) => v.id === "1000")
																?.price
														: null
												}
												onChange={(e) => this.handlePrice(e, "1000")}
											/>
										</div>
									</div>
								</div>
								<div className="form-group">
									<label>Dosis</label>
									<input
										type="text"
										className="form-control"
										value={this.state.currentDoses}
										onChange={(e) =>
											this.setState({ currentDoses: e.target.value })
										}
									/>
									<button onClick={this.addDose}>+</button>
									<button onClick={this.removeDoses}>Borrar todo</button>
									<ul>
										{this.state.doses && this.state.doses.length > 0
											? this.state.doses.map((i) => <li>{i}</li>)
											: "No agregaste dosis."}
									</ul>
								</div>
								<div className="form-group">
									<label>Recordar que no es ingerible?</label>
									<select
										className="form-control"
										value={this.state.isNotEaten}
										onChange={(e) =>
											this.setState({ isNotEaten: e.target.value })
										}
									>
										<option value="false">No</option>
										<option value="true">Si</option>
									</select>
								</div>
								<div className="form-group">
									<label>Es producto nuevo?</label>
									<select
										className="form-control"
										value={this.state.newProduct}
										onChange={(e) =>
											this.setState({ newProduct: e.target.value })
										}
									>
										<option value="false">No</option>
										<option value="true">Si</option>
									</select>
								</div>
								<div className="form-group">
									<label>Descuento en porcentaje (5%, 10% ...)</label>
									<input
										type="number"
										className="form-control"
										value={this.state.discount}
										onChange={(e) =>
											this.setState({ discount: e.target.value })
										}
									/>
								</div>
								<div className="modal-btn">
									<div
										className="btn optional-btn float-left"
										onClick={this.closeModal}
									>
										Cancelar
									</div>
									<button className="btn default-btn float-right">
										Editar Producto
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withRouter(EditProductModal);
