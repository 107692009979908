import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { firebase } from "../../firebase";
import TopNavbar from "../../components/Admin/TopNavbar";
import LeftSidebar from "../../components/Admin/LeftSidebar";
import EditProductModal from "../../components/Admin/Modals/EditProductModal";
import ViewProductModal from "../../components/Admin/Modals/ViewProductModal";
import withAuth from "../../helpers/withAuth";
import noPhoto from "../../assets/images/product/noPhoto.png";
import axios from "axios";
import { removeProduct, resetCart } from "../../store/actions/cartActions";
import { connect } from "react-redux";
import backendUrl from "../../constants/urls";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import { isMobile } from '../../common/utils';

const override = css`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-self: center;
	justify-items: center;
	flex-direction: row;
	width: 100%;
	margin: 20% auto;
	align-items: center;
`;

class Products extends Component {
	// Edit Product Modal
	state = {
		display: false,
		products: [],
		loading: true,
		showEditModal: false,
		modalProduct: null,
	};

	toggleEditProductModal = (product = null) => {
		this.setState({
			showEditModal: !this.state.showEditModal,
			modalProduct: product,
		});
	};

	// View Product Modal
	toggleViewProductModal = () => {
		this.setState({
			ViewProductModal: !this.state.ViewProductModal,
		});
	};
	dataModal = (product) => {
		this.setState({
			modalProduct: product,
		});
	};

	calculatePrice = (data) => {
		return data.variants && data.variants.length > 0
			? data.variants[0].price
			: 1;
	};

	calculateDiscount = (data) => {
		const price = this.calculatePrice(data);
		const ruleOfThree = (data.discount * price) / 100;
		return Math.round(price - ruleOfThree);
	};

	deleteProduct = async (product) => {
		try {
			await axios.post(`${backendUrl}/api/products/delete`, {
				id: product.id,
			});
			let allKeys = "";

			product.images.forEach((i, index) => {
				const splitted = i.split("/");
				if (splitted) {
					if (index == 0) {
						allKeys += splitted[splitted.length - 1];
					} else {
						allKeys += `,${splitted[splitted.length - 1]}`;
					}
				}
			});
			product.thumbImage.forEach((i, index) => {
				const splitted = i.split("/");
				if (splitted) {
					if (index == 0) {
						allKeys += splitted[splitted.length - 1];
					} else {
						allKeys += `,${splitted[splitted.length - 1]}`;
					}
				}
			});

			if (allKeys) {
				axios
					.get(`${backendUrl}/api/upload/erase`, {
						params: {
							fileKeys: allKeys,
						},
						headers: {
							accept: "application/json",
							"Accept-Language": "en-US,en;q=0.8",
							// "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
						},
					})
					.then((response) => {
						toast.success("Se ha eliminado el producto correctamente", {
							position: "bottom-left",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
						});
						setTimeout(() => {
							window.location.reload();
						}, 3000);
					})
					.catch((error) => {
						// If another error
						console.log(error, "red");
					});
			} else {
				toast.success("Se ha eliminado el producto correctamente", {
					position: "bottom-left",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				setTimeout(() => {
					window.location.reload();
				}, 3000);
			}
		} catch (error) {
			toast.error("Ha ocurrido un error", {
				position: "bottom-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
			setTimeout(() => {
				window.location.reload();
			}, 3000);
		}
	};

	componentDidMount() {
		const db = firebase.firestore();
		const dbOrderRef = db.collection("products");
		let productsArray = [];
		dbOrderRef
			.get()
			.then((res) => {
				// console.log(res)
				res.forEach((doc) => {
					let productsObj = doc.data();
					productsObj.id = doc.id;
					productsArray.push(productsObj);
				});
				this.setState({
					products: productsArray,
					loading: false
				});
			})
			.catch((err) => {
				console.log("error", err);
				this.setState({
					loading: false
				});
			});
	}

	render() {
		let { products } = this.state;
		return (
			<React.Fragment>
				<TopNavbar />
				<LeftSidebar />

				<div className="admin-main-content d-flex flex-column">
					<div className="page-header">
						<h2>Productos</h2>
					</div>

					<div className="admin-products">
						{this.state.loading ? (
							<BeatLoader
								css={override}
								size={isMobile() ? 20 : 50}
								color={"#10ABDE"}
								loading={true}
							/>
						) : (
							<div className="row">
								{products.length ? (
									products.map((product, idx) => (
										<div key={idx} className="col-lg-4 col-md-6 col-sm-6">
											<div className="single-products-box">
												<div className="products-image">
													<Link to="#viewproduct">
														<a
															onClick={(e) => {
																e.preventDefault();
															}}
														>
															{" "}
															<img
																src={
																	product.thumbImage &&
																	product.thumbImage.length > 0
																		? product.thumbImage[0]
																		: noPhoto
																}
																className="main-image"
																alt="image"
															/>
															<img
																src={
																	product.thumbImage &&
																	product.thumbImage.length > 1
																		? product.thumbImage[1]
																		: noPhoto
																}
																className="hover-image"
																alt="image"
															/>
														</a>
													</Link>

													{product.discount ? (
														<div className="sale-tag">{`%${product.discount}`}</div>
													) : null}

													{product.new ? (
														<div className="new-tag">Nuevo!</div>
													) : null}

													<div className="products-button">
														<ul>
															<li>
																<div className="compare-btn">
																	<Link to="#">
																		<a
																			onClick={(e) => {
																				e.preventDefault();
																				this.toggleEditProductModal(product);
																			}}
																		>
																			<i className="bx bx-edit"></i>
																			<span className="tooltip-label">
																				Editar
																			</span>
																		</a>
																	</Link>
																</div>
															</li>
															<li>
																<div className="quick-view-btn">
																	<button
																		onClick={() => this.deleteProduct(product)}
																		className="invisible-btn"
																	>
																		<a>
																			<i className="bx bx-x"></i>
																			<span className="tooltip-label">
																				Eliminar
																			</span>
																		</a>
																	</button>
																</div>
															</li>
														</ul>
													</div>
												</div>

												<div className="products-content">
													<h3 style={{fontSize: '14px'}}>{product.name}</h3>
													<div className="price">
														{product.discount ? (
															<React.Fragment>
																<span className="old-price">
																	${product.variants[0].price}
																</span>
																<span className="new-price">
																	${this.calculateDiscount(product)}
																</span>
															</React.Fragment>
														) : (
															<span className="new-price">
																${product.variants[0].price}
															</span>
														)}
													</div>
												</div>
											</div>
										</div>
									))
								) : (
									<p className="no-element-msg">No hay productos.</p>
								)}

								{/* Pagination */}
								{/* <div className="col-lg-12 col-md-12 col-sm-12">
																	<div className="pagination-area admin-pagi">
																			<Link to="#">
																					<a className="prev page-numbers">
																							<i className='bx bx-chevron-left'></i>
																					</a>
																			</Link>
	
																			<span className="page-numbers current">1</span>
	
																			<Link to="#">
																					<a className="page-numbers">2</a>
																			</Link>
	
																			<Link to="#">
																					<a className="page-numbers">3</a>
																			</Link>
	
																			<Link to="#">
																					<a className="next page-numbers">
																							<i className='bx bx-chevron-right'></i>
																					</a>
																			</Link>
																	</div>
															</div> */}
							</div>
						)}
					</div>

					{/* Footer */}
					<div className="flex-grow-1"></div>
				</div>

				{/* Sidebar Modal */}
				{this.state.showEditModal && (
					<EditProductModal
						onClick={this.toggleEditProductModal}
						active={this.state.showEditModal ? "active" : ""}
						product={this.state.modalProduct}
					/>
				)}
				{/* <ViewProductModal
					onClick={this.toggleViewProductModal}
					active={this.state.ViewProductModal ? "active" : ""}
					product={this.state.modalProduct}
				/> */}
			</React.Fragment>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		removeProduct: (id) => {
			dispatch(removeProduct(id));
		},
		resetCart: () => {
			dispatch(resetCart());
		},
	};
};

export default connect(null, mapDispatchToProps)(withAuth(Products));
