import React from "react";

import classNames from "classnames";

export default function SectionTitleOne({
	subTitle,
	className,
	description,
	align,
	spaceBottom,
	showSubTitle,
	children,
	icon,
	hideDecoration,
	titleSize,
}) {
	return (
		<div
			className={`section-title -style1 ${classNames(className)}`}
			style={{
				textAlign: align,
				marginBottom: spaceBottom || "3.125rem",
			}}
		>
			{showSubTitle && <h5>{subTitle}</h5>}
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				{icon && <img src={icon} />}

				<h2 style={{ fontSize: titleSize }}>{children}</h2>
			</div>

			{description && <p>{description}</p>}
		</div>
	);
}
