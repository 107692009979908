import React from "react";
import { Route, BrowserRouter as VendorRouter, Switch } from "react-router-dom";
import ScrollToTopCustom from "../components/Layout/ScrollToTop";
import { routes } from "./routes";

const MyRouter = () => (
	<VendorRouter>
		<ScrollToTopCustom />

		<Switch>
			{routes.map((route) => (
				<Route key={route.path} {...route} />
			))}
		</Switch>
	</VendorRouter>
);

export { MyRouter };
