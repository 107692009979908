import React from "react";
import LayoutFour from "../../components/Layout/LayoutFour";
import { Breadcrumb, BreadcrumbItem } from "../../components/Other/Breadcrumb";
import InstagramTwo from "../../components/Sections/Instagram/InstagramTwo";
import IntroductionSix from "../../components/Sections/Introduction/IntroductionSix";
import soap from '../../assets/images/banners/soap.jpg'

export default function () {
	return (
		<LayoutFour title="Políticas, locales & envíos">
			<Breadcrumb title="Políticas, locales & envíos" img={soap}>
				<BreadcrumbItem name="Inicio" />
				<BreadcrumbItem name="Información" current />
			</Breadcrumb>
			<IntroductionSix />
			<InstagramTwo />
		</LayoutFour>
	);
}
