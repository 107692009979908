import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import clock from "../../../assets/icons/clock.svg";

export default function ContactInfoItem({ iconClass, title, detail }) {
	return (
		<div className="contact-info__item">
			<div className="contact-info__item__icon">
				{iconClass ? (
					<i className={classNames(iconClass)} />
				) : (
					<img src={clock} style={{ height: "17px" }} />
				)}
			</div>
			<div className="contact-info__item__detail">
				<h3>{title}</h3>
				<p>{detail}</p>
			</div>
		</div>
	);
}
