import React from "react";
import { Link } from "react-router-dom";

import Button from "../components/Control/Button";

export default function () {
  return (
    <>
      <div className="error-404">
        <div className="container-full-half">
          <div className="row">
            <div className="col-12 col-md-7">
              <div className="error-404__content">
                <h2>Oops!</h2>
                <h5>404 PÁGINA NO ENCONTRADA</h5>
                <p>
                  Esta página no existe o ha sido reemplazada momentaneamente.
                </p>
                <div className="form-wrapper">
                  <Button
                    action={"/"}
                    className="-underline"
                    color="transparent"
                    content="Volver al inicio"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
