import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { firebase } from "../../firebase";
import TopNavbar from "../../components/Admin/TopNavbar";
import LeftSidebar from "../../components/Admin/LeftSidebar";
import EditCategoryModal from "../../components/Admin/Modals/EditCategoryModal";
import withAuth from "../../helpers/withAuth";
import noPhoto from "../../assets/images/product/noPhoto.png";
import axios from "axios";
import backendUrl from "../../constants/urls";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import { isMobile } from "../../common/utils";

const override = css`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-self: center;
	justify-items: center;
	flex-direction: row;
	width: 100%;
	margin: 20% auto;
	align-items: center;
`;

class Categories extends Component {
	state = {
		display: false,
		categories: [],
		loading: true,
		showEditModal: false,
		modalCategory: null,
	};

	toggleEditCategoryModal = (course = null) => {
		this.setState({
			showEditModal: !this.state.showEditModal,
			modalCategory: course,
		});
	};

	dataModal = (course) => {
		this.setState({
			modalCategory: course,
		});
	};

	deleteCategory = async (category) => {
		try {
			const data = {
				id: category.id,
			};

			const response = await axios.post(
				`${backendUrl}/api/categories/delete`,
				data,
				{
					headers: {
						accept: "application/json",
						"Accept-Language": "en-US,en;q=0.8",
						"Content-Type": `application/json`,
					},
				}
			);

			// Delete completed!
			toast.success("La categoría fue eliminada exitosamente.", {
				position: "bottom-left",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		} catch (error) {
			toast.error("Ha ocurrido un error", {
				position: "bottom-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	};

	componentDidMount() {
		this.setState({
			loading: true,
		});
		const db = firebase.firestore();
		const dbOrderRef = db.collection("categories");
		let categoriesArray = [];
		dbOrderRef
			.get()
			.then((res) => {
				// console.log(res)
				res.forEach((doc) => {
					let categoriesObj = doc.data();
					categoriesObj.id = doc.id;
					categoriesArray.push(categoriesObj);
				});
				this.setState({
					categories: categoriesArray,
					loading: false,
				});
			})
			.catch((err) => {
				console.log("error", err);
				this.setState({
					loading: false,
				});
			});
	}

	render() {
		let { categories, loading } = this.state;
		return (
			<React.Fragment>
				<TopNavbar />
				<LeftSidebar />

				<div className="admin-main-content d-flex flex-column">
					<div className="page-header">
						<h2>Categorías</h2>
					</div>

					<div className="admin-products">
						{this.state.loading ? (
							<BeatLoader
								css={override}
								size={isMobile() ? 20 : 50}
								color={"#10ABDE"}
								loading={true}
							/>
						) : (
							<div className="row">
								{categories.length ? (
									categories.map((category, idx) => (
										<div key={idx} className="col-lg-4 col-md-6 col-sm-6">
											<div className="single-products-box">
												<div className="products-image">
													{" "}
													<img
														src={category.image ? category.image : noPhoto}
														className="main-image"
														alt="image"
													/>
													<div className="products-button">
														<ul>
															<li>
																<div className="compare-btn">
																	<Link to="#">
																		<a
																			onClick={(e) => {
																				e.preventDefault();
																				this.toggleEditCategoryModal(category);
																			}}
																		>
																			<i className="bx bx-edit"></i>
																			<span className="tooltip-label">
																				Editar
																			</span>
																		</a>
																	</Link>
																</div>
															</li>
															<li>
																<div className="quick-view-btn">
																	<button
																		onClick={() =>
																			this.deleteCategory(category)
																		}
																		className="invisible-btn"
																	>
																		<a>
																			<i className="bx bx-x"></i>
																			<span className="tooltip-label">
																				Eliminar
																			</span>
																		</a>
																	</button>
																</div>
															</li>
														</ul>
													</div>
												</div>
												<div className="products-content">
													<h3>{category.title}</h3>
												</div>
											</div>
										</div>
									))
								) : (
									<p className="no-element-msg">No hay categorías.</p>
								)}
							</div>
						)}
					</div>

					{/* Footer */}
					<div className="flex-grow-1"></div>
				</div>

				{/* Sidebar Modal */}
				{this.state.showEditModal && (
					<EditCategoryModal
						onClick={this.toggleEditCategoryModal}
						active={this.state.showEditModal ? "active" : ""}
						category={this.state.modalCategory}
					/>
				)}
			</React.Fragment>
		);
	}
}

export default withAuth(Categories);
