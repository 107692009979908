import React from "react";
import { useParams, withRouter } from "react-router-dom";
import LayoutFour from "../../components/Layout/LayoutFour";
import Button from "../../components/Control/Button";
import { Breadcrumb, BreadcrumbItem } from "../../components/Other/Breadcrumb";
import spa from '../../assets/images/banners/spa.jpg'


const Success = (props) => {
    return (
        <LayoutFour title="Pending">
            <Breadcrumb title="Pedido pendiente" img={spa}>
                <BreadcrumbItem name="Pedido" />
                <BreadcrumbItem name="Resumen" current />
            </Breadcrumb>
            <div className="cart" style={{
                marginTop: '130px',
                marginBottom: '130px'
            }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12" >
                            <div className="error-404__content pb-100" style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                height: '300px'
                            }}>



                                <h5>Ha ocurrido un error procesando tu compra.</h5>
               
                                <div className="form-wrapper">
                                    <Button
                                        action={"/"}
                                        className="-underline"
                                        color="transparent"
                                        content="Volver al inicio"
                                    />
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutFour>
    );
}

export default withRouter(Success);
