import { v4 as uuidv4 } from "uuid";

import { DELIVERY } from "../variables";

const initialState = {
	delivery: false,
};

export default function deliveryReducer(state = initialState, action) {
	switch (action.type) {
		case DELIVERY.ADD_DELIVERY:
			return {
				...state,
				delivery: true,
			};
		case DELIVERY.REMOVE_DELIVERY:
			return {
				...state,
				delivery: false,
			};
		default:
			return state;
	}
}
