import React, { Component } from "react";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { firebase } from "../../../firebase";
import axios from "axios";
import backendUrl from "../../../constants/urls";

class AddVacationModal extends Component {
	state = {
		modal: false,
		progress: 0,
		content: "",
	};

	baseState = this.state;
	resetForm = () => {
		this.setState(this.baseState);
	};

	closeModal = () => {
		this.props.onClick(this.state.modal);
	};

	addVacation = (e) => {
		e.preventDefault();
		let { content } = this.state;
		const vacation = {
			content: content,
		};
		if (content != "") {
			const db = firebase.firestore();

			//First remove old vacations
			const dbOrderRef = db.collection("vacations");
			dbOrderRef
				.get()
				.then((res) => {
					res.forEach((doc) => {
						dbOrderRef
							.doc(doc.id)
							.delete()
							.then(() => {});
					});
					//Now add
					const dbOrderRef2 = db.collection("vacations");
					dbOrderRef2
						.add(vacation)
						.then((res) => {
							toast.success(
								"Notificación de vacaciones fue creada exitosamente.",
								{
									position: "bottom-left",
									autoClose: 3000,
									hideProgressBar: false,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
								}
							);
							setTimeout(() => {
								window.location.reload();
							}, 3000);
							this.closeModal();
							this.resetForm();
						})
						.catch((error) => {
							toast.error("Ha ocurrido un error.", {
								position: "bottom-left",
								autoClose: 3000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
							});
							console.log(error, "red");
						});
				})
				.catch((error) => {
					toast.error("Ha ocurrido un error.", {
						position: "bottom-left",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
					console.log(error, "red");
				});
		} else {
			toast.error("Fillup the form", {
				position: "bottom-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	};

	render() {
		return (
			<React.Fragment>
				<div className={`admin-product-modal ${this.props.active}`}>
					<div className="modal-innter-content">
						<button type="button" className="close" onClick={this.closeModal}>
							<span aria-hidden="true">
								<i className="bx bx-x"></i>
							</span>
						</button>

						<div className="modal-body">
							<h3>Agregar notificación de vacaciones</h3>

							<form onSubmit={this.addVacation}>
								<h4 className="title">Agrega información aquí</h4>

								<div className="form-group">
									<label>
										Contenido (Ej: "Estaremos cerrados desde el día 15/01..."){" "}
									</label>
									<input
										type="text"
										className="form-control"
										value={this.state.content}
										onChange={(e) => this.setState({ content: e.target.value })}
									/>
								</div>

								<div className="modal-btn">
									<div
										className="btn optional-btn float-left"
										onClick={this.closeModal}
									>
										Cancelar
									</div>
									<button className="btn default-btn float-right">
										Crear Notificación
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withRouter(AddVacationModal);
