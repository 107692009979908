import React from "react";
import calendar from "../../../assets/icons/calendar.svg";
import payment from "../../../assets/icons/payment.svg";
import question from "../../../assets/icons/question.svg";
import exchange from "../../../assets/icons/return.svg";
import truck from "../../../assets/icons/truck.svg";
import pin from "../../../assets/icons/pin.svg";
import map from "../../../assets/images/mapa.jpeg";
export default function IntroductionSix({ data }) {
	return (
		<div className="introduction-six">
			<div className="container">
				<div className="introduction-six__wrapper">
					<div className="row">
						<div className="col-12 col-md-6 col-lg-4">
							<div className="introduction-six__wrapper__item">
								<div className="introduction-six__wrapper__item">
									<div className="introduction-six__wrapper__item__image">
										<img src={question} />
									</div>
									<div className="introduction-six__wrapper__item__content">
										<h3>¿Cómo recibo mi producto?</h3>
										<p>
											<strong>- Retiro </strong> en nuestro local.{" "}
										</p>
										<p>
											<strong>- Delivery </strong>en Montevideo con costo.{" "}
										</p>
									</div>
								</div>
							</div>
						</div>

						<div className="col-12 col-md-6 col-lg-4">
							<div className="introduction-six__wrapper__item">
								<div className="introduction-six__wrapper__item__image">
									<img src={pin} />
								</div>
								<div className="introduction-six__wrapper__item__content">
									<h3>Local</h3>
									<p>
										<strong>- Dirección: </strong> Magallanes 920, Montevideo,
										UY{" "}
									</p>
									<p>
										<strong>- Teléfono: </strong> (+598) 2410 0595{" "}
									</p>
									<p>
										<strong>- Fax: </strong> (+598) 2411 0595
									</p>
									<p>
										<strong>- Horarios: </strong>Lunes a Viernes: 8:30 a 12:30,
										y 13:00 a 17:30 hrs.
									</p>
								</div>
							</div>
						</div>

						<div className="col-12 col-md-6 col-lg-4">
							<div className="introduction-six__wrapper__item">
								<div className="introduction-six__wrapper__item__image">
									<img src={truck} />
								</div>
								<div className="introduction-six__wrapper__item__content">
									<h3>Delivery</h3>
									<p>
										- Envíos dentro de <strong>Montevideo *</strong>ver mapa.
									</p>
									<p>
										- Costo <strong>$350</strong>.
									</p>
									<p>
										- Compra mínima <strong>$1000</strong>.
									</p>
									<p>
										- Para compras mayores a <strong>$3000</strong> el envío es
										gratis.
									</p>
								</div>
							</div>
						</div>

						<div className="col-12 col-md-6 col-lg-4">
							<div className="introduction-six__wrapper__item">
								<div className="introduction-six__wrapper__item__image">
									<img src={calendar} />
								</div>
								<div className="introduction-six__wrapper__item__content">
									<h3>Anticipación</h3>
									<p>
										{" "}
										<strong>- Delivery: </strong> Se entregan luego de 48hrs los
										días hábiles.
									</p>
									<p>
										{" "}
										<strong>- Retiro en tienda: </strong> Se entregan luego de
										24hrs los días hábiles en los horarios respectivos.
									</p>
								</div>
							</div>
						</div>

						<div className="col-12 col-md-6 col-lg-4">
							<div className="introduction-six__wrapper__item">
								<div className="introduction-six__wrapper__item__image">
									<img src={payment} />
								</div>
								<div className="introduction-six__wrapper__item__content">
									<h3>Métodos de pago</h3>
									<p>- Mercado Pago.</p>
									<p>- Tarjetas de débito o crédito.</p>
									<p>
										- Transferencia bancaria Brou o Santander (5% descuento).
									</p>
									<p>- Factura con RUT.</p>
								</div>
							</div>
						</div>

						<div className="col-12 col-md-6 col-lg-4">
							<div className="introduction-six__wrapper__item">
								<div className="introduction-six__wrapper__item__image">
									<img src={exchange} />
								</div>
								<div className="introduction-six__wrapper__item__content">
									<h3>Cambios y devoluciones</h3>
									<p>
										- La empresa no acepta cambios ni devoluciones. Si ocurre un
										error de stock, te devolvemos el monto total de la compra.
										Tienes 90 días para comunicarte con nosotros.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row justify-content-center">
						<p><strong>*</strong>Zonas de envío:</p>
						<img src={map} style={{ width: "400px" }} />
					</div>
				</div>
			</div>
		</div>
	);
}
