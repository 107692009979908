import React, { Component, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import backendUrl from "../../constants/urls";
import { css } from "@emotion/core";
import BarLoader from "react-spinners/BarLoader";
import { calculate } from "../../common/shopUtils";
import {
	selectPaymentType,
	selectPaymentId,
} from "../../constants/payment_types";
import { calculateTotal } from "../../common/utils";
import { removeAllFromCart } from "../../redux/actions/cartActions";

const override = css`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-self: center;
	justify-items: center;
	flex-direction: row;
	width: 100%;
	align-items: center;
`;

const MercadoPago = (props) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const cartState = useSelector((state) => state.cartReducer);
	const deliveryState = useSelector((state) => state.deliveryReducer);

	const total = calculateTotal(cartState);

	const { delivery } = deliveryState;

	useEffect(() => {
		const aux = document.getElementById("mercadoForm");
		if (aux) {
			aux.innerHTML = "";
		}
		const fetchData = async () => {
			if (cartState) {
				let info = {};
				let deliveryItem = {};
				if (delivery && total < 3000) {
					deliveryItem = {
						title: "Delivery",
						unit_price: 350,
						quantity: 1,
						currency_id: "UYU",
					};

					info = {
						items: [
							...cartState.map((i) => ({
								title: i.name,
								unit_price: Number(calculate(i)),
								quantity: i.cartQuantity,
								currency_id: "UYU",
							})),
							deliveryItem,
						],
					};
				} else {
					info = {
						items: cartState.map((i) => ({
							title: i.name,
							unit_price: Number(calculate(i)),
							quantity: i.cartQuantity,
							currency_id: "UYU",
						})),
					};
				}

				const initPoint = await axios.post(
					`${backendUrl}/api/mercadopago/${props.mercadoNumber}`,

					info,
					{
						headers: {
							accept: "application/json",
							"Accept-Language": "en-US,en;q=0.8",
						},
					}
				);
				const script = document.createElement("script");
				script.src =
					"https://www.mercadopago.com.uy/integrations/v1/web-payment-checkout.js";
				script.async = true;
				script.setAttribute("data-preference-id", initPoint.data.preferenceId);
				if (aux) {
					aux.appendChild(script);
				}
				setLoading(false);
			}
		};
		window.addEventListener("message", detectAnswer);
		fetchData();
		return () => {
			window.removeEventListener("message", detectAnswer);
		};
	}, [deliveryState]);

	async function detectAnswer(e) {
		const shouldPreAdd = e.data && typeof e.data === 'string' && e.data.includes('action');

		if (!shouldPreAdd) {
			return;
		}

		props.paymentCallback(props.mercadoNumber);

		return;
	}

	const detectAnswer2 = (event) => {
		if (event.origin !== "https://www.mercadopago.com.uy" || !event.data.type) {
			return;
		}

		let dataType = event.data.type;

		if (dataType === "submit") {
			const paymentData = event.data.value;
			if (paymentData && paymentData.length > 3) {
				const paymentMode = paymentData[2];
				const paymentType =
					paymentMode && paymentMode.value
						? selectPaymentType(paymentMode.value)
						: "No hay info";
				const paymentId = paymentData ? selectPaymentId(paymentData) : null;

				const paymentStatus = paymentData[4];
				if (paymentStatus.id === "payment_status") {
					switch (paymentData[4].value) {
						case "approved":
							setTimeout(function () {
								toast.success("Tu pago se ha completado con éxito.", {
									position: "bottom-left",
									autoClose: 3000,
									hideProgressBar: true,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
								});
							}, 1500);
							props.paymentCallback(paymentType, false, paymentId);
							// dispatch(removeAllFromCart());
							break;
						case "in_process":
							let inProcess = true;
							setTimeout(function () {
								toast.warning(
									"Cuando recibamos tu pago aprobaremos tu compra.",
									{
										position: "bottom-left",
										autoClose: 3000,
										hideProgressBar: true,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
									}
								);
							}, 1500);
							props.forceNotification();
							props.paymentCallback(paymentType, inProcess, paymentId);
							break;
						case "pending":
							const isInProcess = true;
							setTimeout(function () {
								toast.warning(
									"Cuando recibamos tu pago aprobaremos tu compra.",
									{
										position: "bottom-left",
										autoClose: 3000,
										hideProgressBar: true,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
									}
								);
							}, 1500);
							props.forceNotification();
							props.paymentCallback(paymentType, isInProcess, paymentId);

							break;
						case "rejected":
							setTimeout(function () {
								toast.error("Ha ocurrido un error con tu pago.", {
									position: "bottom-left",
									autoClose: 3000,
									hideProgressBar: true,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
								});
							}, 1500);
							break;
						default:
							setTimeout(function () {
								toast.error("Ha ocurrido un error con tu pago.", {
									position: "bottom-left",
									autoClose: 1000,
									hideProgressBar: true,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
								});
							}, 1500);
							break;
					}
				}
			}

			return;
		}
	};

	var isMobile = false;
	// device detection
	if (
		/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
			navigator.userAgent
		) ||
		/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
			navigator.userAgent.substr(0, 4)
		)
	) {
		isMobile = true;
	}

	// if (!products) {
	// 	return <Redirect to="/" />;
	// }
	return (
		<div className="col-lg-12 col-md-12">
			<form action="javascript:void(0)" id="mercadoForm" />
			{loading && (
				<BarLoader
					css={override}
					size={isMobile ? 20 : 50}
					color={"#10ABDE"}
					loading={true}
				/>
			)}
			{!cartState && !loading && "Ha ocurrido un error."}
		</div>
	);
};

export default withRouter(MercadoPago);
