import React, { Component } from "react";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { firebase } from "../../../firebase";
import axios from "axios";
import backendUrl from "../../../constants/urls";

class AddBannerModal extends Component {
	state = {
		modal: false,
		progress: 0,
		title: "",
		image: "",
		subtitle: "",
		comment: "",
		link: "",
	};

	baseState = this.state;
	resetForm = () => {
		this.setState(this.baseState);
	};

	closeModal = () => {
		this.props.onClick(this.state.modal);
	};

	handleChangeCover = (e) => {
		if (e.target.files[0]) {
			let imageHover = e.target.files[0];
			let reader = new FileReader();
			reader.readAsDataURL(imageHover);
			reader.onload = () => {
				// console.log(reader.result);
				this.setState({ previewCover: reader.result });
			};
			this.handleUploadCover(imageHover);
		}
	};

	handleUploadCover = (imageHover) => {
		const data = new FormData();
		if (imageHover) {
			data.append("productImageHover", imageHover, imageHover.name);
			axios
				.post(`${backendUrl}/api/upload-hover`, data, {
					headers: {
						accept: "application/json",
						"Accept-Language": "en-US,en;q=0.8",
						"Content-Type": `multipart/form-data; boundary=${data._boundary}`,
					},
				})
				.then((response) => {
					if (200 === response.status) {
						// If file size is larger than expected.
						if (response.data.error) {
							if ("LIMIT_FILE_SIZE" === response.data.error.code) {
								alert("Max size: 2MB");
							} else {
								console.log(response.data);
								// If not the given file type
								// alert( response.data.error, 'red' );
							}
						} else {
							// Success
							let fileName = response.data;
							this.setState({
								imageCoverUrl: fileName.location,
							});
							// console.log( 'fileName', fileName.location );
							// alert( 'File Uploaded', '#3089cf' );
						}
					}
				})
				.catch((error) => {
					// If another error
					alert(error);
				});
		} else {
			// if file not selected throw error
			alert("Please upload file");
		}
	};

	addBanner = (e) => {
		e.preventDefault();
		let { title, subtitle, comment, link, imageCoverUrl } = this.state;
		const banner = {
			title: title,
			subtitle: subtitle,
			image: imageCoverUrl,
			comment: comment,
			createdAt: new Date(),
			link: link,
		};
		if (title != "") {
			const db = firebase.firestore();

			//First remove old banners
			const dbOrderRef = db.collection("banners");
			dbOrderRef
				.get()
				.then((res) => {
					res.forEach((doc) => {
						dbOrderRef
							.doc(doc.id)
							.delete()
							.then(() => {});
					});
					//Now add
					const dbOrderRef2 = db.collection("banners");
					dbOrderRef2
						.add(banner)
						.then((res) => {
							toast.success("Banner fue creado exitosamente.", {
								position: "bottom-left",
								autoClose: 3000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
							});
							setTimeout(() => {
								window.location.reload();
							}, 3000);
							this.closeModal();
							this.resetForm();
						})
						.catch((error) => {
							toast.error("Ha ocurrido un error.", {
								position: "bottom-left",
								autoClose: 3000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
							});
							console.log(error, "red");
						});
				})
				.catch((error) => {
					toast.error("Ha ocurrido un error.", {
						position: "bottom-left",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
					console.log(error, "red");
				});
		} else {
			toast.error("Fillup the form", {
				position: "bottom-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	};

	render() {
		const { previewCover } = this.state;
		return (
			<React.Fragment>
				<div className={`admin-product-modal ${this.props.active}`}>
					<div className="modal-innter-content">
						<button type="button" className="close" onClick={this.closeModal}>
							<span aria-hidden="true">
								<i className="bx bx-x"></i>
							</span>
						</button>

						<div className="modal-body">
							<h3>Agregar banner</h3>

							<form onSubmit={this.addBanner}>
								<div className="form-group">
									<label>Subir la imagen aquí. Formato imagen <strong>horizontal</strong> </label>
									<div className="upload-img">
										<span>
											<i className="bx bxs-image-add"></i>
											Seleccionar
										</span>

										<input
											type="file"
											className="form-control-file"
											name="productImageHover"
											onChange={this.handleChangeCover}
										/>
									</div>

									{previewCover ? (
										<div className="uploaded-img">
											<img
												src={previewCover}
												alt="Image"
												className="img-thumbnail"
											/>
										</div>
									) : null}
								</div>
								<h4 className="title">Agrega información del banner aquí</h4>

								<div className="form-group">
									<label>Comentario (arriba) </label>
									<input
										type="text"
										className="form-control"
										value={this.state.comment}
										onChange={(e) => this.setState({ comment: e.target.value })}
									/>
								</div>

								<div className="form-group">
									<label>Titulo principal (del medio) </label>
									<input
										type="text"
										className="form-control"
										value={this.state.title}
										onChange={(e) => this.setState({ title: e.target.value })}
									/>
								</div>

								<div className="form-group">
									<label>Subtitulo (abajo) </label>
									<input
										type="text"
										className="form-control"
										value={this.state.subtitle}
										onChange={(e) =>
											this.setState({ subtitle: e.target.value })
										}
									/>
								</div>

								<div className="form-group">
									<label>Link</label>
									<p style={{ fontSize: "12px" }}>
										*Nota: Por defecto va a todos los productos. Incluir link completo, ej: https://lariales.com.uy/shop
									</p>
									<input
										type="text"
										className="form-control"
										value={this.state.link}
										onChange={(e) => this.setState({ link: e.target.value })}
									/>
								</div>

								<div className="modal-btn">
									<div
										className="btn optional-btn float-left"
										onClick={this.closeModal}
									>
										Cancelar
									</div>
									<button className="btn default-btn float-right">
										Crear Banner
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withRouter(AddBannerModal);
