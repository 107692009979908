import React from 'react';
import { useForm } from "react-hook-form";

import SocialIcons from "../Other/SocialIcons";
import PostCarThree from "../Post/PostCarThree";
import SubscribeEmail from "../Other/SubcribeEmail";

export default function BlogSidebar({ limit, popularPostData }) {
  const { register, handleSubmit, errors } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    errors: errors2,
  } = useForm();
  return (
    <div className="blog-sidebar">
      <div className="blog-sidebar__section">
        <h5 className="blog-sidebar__title">Encontranos en:</h5>
        <SocialIcons className="-border -round -border--light-bg" />
      </div>
      <div className="blog-sidebar__section -polular-post">
        <h5 className="blog-sidebar__title">Últimos blogposts</h5>
        {popularPostData &&
          popularPostData
            .slice(0, limit)
            .map((post, index) => <PostCarThree key={index} data={post} />)}
      </div>
      <div className="blog-sidebar__section -newsletter">
        <h5 className="blog-sidebar__title">Newsletter</h5>
        <p>
          Suscribite a nuestro newsletter para recibir las últimas noticias y descuentos especiales.
        </p>
        <SubscribeEmail
          mailchimpUrl="https://lariales.us2.list-manage.com/subscribe/post?u=16ce287d201aaf7cc629a4c2f&id=5b9007282d"
          placeholder="Email"
          btnContent="Subscribirme"
          className="blog-sidebar-newsletter"
        >
        </SubscribeEmail>
      </div>
    </div>
  );
}
