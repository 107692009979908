import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { useForm } from "react-hook-form";
import loup from "../../../assets/images/header/loupe.png";
import { useOutsideClickHandle } from "../../../common/ElementOutsideClick";

export default function SearchBox({ search, setCurrentSearch }) {
	const wrapperRef = useRef(null);

	return (
		<CSSTransition
			in={true}
			unmountOnExit
			timeout={200}
			classNames="search-box"
		>
			<div ref={wrapperRef} className="search-box">
				<input
					type="text"
					placeholder="Buscar..."
					name="search"
					value={search}
					onChange={(e) => setCurrentSearch(e.target.value)}
				/>

				<img src={loup} alt="Search icon" />
			</div>
		</CSSTransition>
	);
}
