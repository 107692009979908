import { firebase } from "../firebase";

export const fetchProducts = async () => {
	const db = firebase.firestore();
	const dbOrderRef = db.collection("products");
	const productsArray = [];
	const res = await dbOrderRef.get();
	res.forEach((doc) => {
		let productsObj = doc.data();
		productsObj.id = doc.id;
		productsArray.push(productsObj);
	});
	return productsArray;
};

export const fetchProductById = async (id) => {
	const db = firebase.firestore();
	const dbOrderRef = db.collection("products");
	const query = dbOrderRef.where("id", "==", id);
	const productsArray = [];
	const res = await query.get();
	let productsObj = res.doc.data();
	productsObj.id = res.doc.id;
	return productsObj;
};

export const fetchAllBlogPosts = async () => {
	const db = firebase.firestore();
	const dbOrderRef = db.collection("blogs");
	const blogsArray = [];
	const res = await dbOrderRef.get();
	res.forEach((doc) => {
		let productsObj = doc.data();
		productsObj.id = doc.id;
		blogsArray.push(productsObj);
	});
	return blogsArray;
};

export const fetchBlogPostById = async (id) => {
	const db = firebase.firestore();
	const dbOrderRef = db.collection("blogs");
	const query = dbOrderRef.doc(id);
	const productsArray = [];
	const res = await query.get();
		let productsObj = res.data();
		productsObj.id = res.id;
		return productsObj;
};

export const fetchBanner = async () => {
	const db = firebase.firestore();
	const dbOrderRef = db.collection("banners");
	const subproductsArray = [];
	const res = await dbOrderRef.get();
	res.forEach((doc) => {
		let productsObj = doc.data();
		productsObj.id = doc.id;
		subproductsArray.push(productsObj);
	});
	return subproductsArray;
};

export const fetchIgToken = async () => {
	const db = firebase.firestore();
	const dbOrderRef = db.collection("igtokens");
	const subproductsArray = [];
	const res = await dbOrderRef.orderBy("createdAt").get();
	res.forEach((doc) => {
		let productsObj = doc.data();
		productsObj.id = doc.id;
		subproductsArray.push(productsObj);
	});
	return subproductsArray;
};