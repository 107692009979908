import React, { Component } from "react";
import { Link } from "react-router-dom";
import TopNavbar from "./TopNavbar";
import axios from "axios";

class LeftSidebar extends Component {
	state = {
		layoutCls: false,
	};

	handleLayoutCls = (e) => {
		this.setState({
			layoutCls: e,
		});
	};

	render() {
		let { layoutCls } = this.state;
		return (
			<React.Fragment>
				<TopNavbar onChangeClass={this.handleLayoutCls} />
				<nav className={`admin-sidebar ${layoutCls ? "active" : null}`}>
					<ul>
						<li className="nav-item">
							<Link
								to="/admin/dashboard"
								activeClassName="active"
								className="nav-link"
							>
								<i className="bx bx-home-alt"></i>
								Dashboard
							</Link>
						</li>
						<li className="nav-item" title="Coming Soon">
							<Link
								to="/admin/products"
								activeClassName="active"
								className="nav-link"
							>
								<i className="bx bx-cart"></i>
								Productos
							</Link>
						</li>
						<li className="nav-item" title="Coming Soon">
							<Link
								to="/admin/categories"
								activeClassName="active"
								className="nav-link"
							>
								<i className="bx bx-cart"></i>
								Categorías
							</Link>
						</li>
						<li className="nav-item" title="Coming Soon">
							<Link
								to="/admin/orders"
								activeClassName="active"
								className="nav-link"
							>
								<i className="bx bx-file-blank"></i>
								Ordenes
							</Link>
						</li>
						<li className="nav-item" title="Coming Soon">
							<Link
								to="/admin/blogs"
								activeClassName="active"
								className="nav-link"
							>
								<i className="bx bx-file-blank"></i>
								Blogs
							</Link>
						</li>
						<li className="nav-item" title="Coming Soon">
							<Link
								to="/admin/banners"
								activeClassName="active"
								className="nav-link"
							>
								<i className="bx bx-box"></i>
								Banners
							</Link>
						</li>
						<li className="nav-item" title="Coming Soon">
							<Link
								to="/admin/vacations"
								activeClassName="active"
								className="nav-link"
							>
								<i className="bx bx-box"></i>
								Vacaciones
							</Link>
						</li>
						<li className="nav-item">
							<Link to="/" activeClassName="active" className="nav-link">
								<i className="bx bx-link"></i>
								Pagina clientes
							</Link>
						</li>
					</ul>
				</nav>
			</React.Fragment>
		);
	}
}

export default LeftSidebar;
