import { combineReducers } from "redux";

import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import shopReducers from "./shopReducers";
import deliveryReducer from "./deliveryReducer";

const rootReducer = combineReducers({
  cartReducer,
  wishlistReducer,
  shopReducers,
  deliveryReducer,
});

export default rootReducer;
