import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";

import SectionTitleOne from "../Sections/SectionTitle/SectionTitleOne";
import { shop } from "../../common/variables";
import { formatCurrency } from "../../common/utils";
import {
	setFilterCategory,
	setFilterSize,
	setFilterPriceRange,
	resetFilter,
} from "../../redux/actions/shopActions";
import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import backendUrl from "../../constants/urls";

const override = css`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-self: center;
	justify-items: center;
	flex-direction: row;
	width: 100%;
	align-items: center;
`;

export default function ShopSidebar() {
	const dispatch = useDispatch();
	const [isLoading, setLoading] = useState(false);
	const [categories, setCategories] = useState([]);
	const filterData = useSelector((state) => state.shopReducers.filter);

	useEffect(() => {
		setLoading(true);
		const fetchData = async () => {
			const response = await axios.get(`${backendUrl}/api/categories/`, {
				headers: {
					accept: "application/json",
					"Accept-Language": "en-US,en;q=0.8",
				},
			});
			setCategories(response.data);
			setLoading(false);
		};
		fetchData();
		return () => {
			// dispatch(resetFilter());
		};
	}, []);

	var isMobile = false;
	// device detection
	if (
		/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
			navigator.userAgent
		) ||
		/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
			navigator.userAgent.substr(0, 4)
		)
	) {
		isMobile = true;
	}

	return (
		<div className="shop-sidebar">
			<div className="shop-sidebar__content">
				<div className="shop-sidebar__section -categories">
					<SectionTitleOne className="-medium" spaceBottom={30 / 16 + "em"}>
						Categorías
					</SectionTitleOne>
					{isLoading ? (
						<BeatLoader
							css={override}
							size={isMobile ? 20 : 50}
							color={"#10ABDE"}
							loading={true}
						/>
					) : (
						<ul>
							{categories?.length ? (
								categories.map((item, index) => (
									<li
										key={index}
										className={classNames({
											active: item.id === filterData.category,
										})}
									>
										<Link to={process.env.PUBLIC_URL + "#"} key={index}>
											<a
												onClick={(e) => {
													e.preventDefault();
													dispatch(setFilterCategory(item.id));
												}}
											>
												{item.title}
											</a>
										</Link>
									</li>
								))
							) : (
								<p>No hay categorías</p>
							)}
						</ul>
					)}
				</div>
				<div className="shop-sidebar__section -refine">
					<SectionTitleOne className="-medium" spaceBottom={30 / 16 + "em"}>
						Más filtros
					</SectionTitleOne>
					<div className="shop-sidebar__section__item">
						<h5>Tamaño</h5>
						<p style={{ fontSize: "12px", marginBottom: "10px" }}>
							<strong>Unidades:</strong> cc, ml, grs.
						</p>
						<ul>
							{shop.SIZES.map((item, index) => (
								<li key={index}>
									<label htmlFor={"b-" + item}>
										<input
											name="brand-filter"
											type="checkbox"
											id={"b-" + item}
											value={item}
											checked={filterData.size === item}
											onChange={() => {
												if (filterData.size === item) {
													dispatch(setFilterSize(""));
												} else {
													dispatch(setFilterSize(item));
												}
											}}
										/>
										{item}
									</label>
								</li>
							))}
						</ul>
					</div>
					<div className="shop-sidebar__section__item">
						<h5>Precio</h5>
						<ul>
							{shop.PRICE_POINTS.map((item, index) => {
								let nextVal = shop.PRICE_POINTS[index + 1];
								if (index === shop.PRICE_POINTS.length - 1) {
									return;
								}
								return (
									<li key={index}>
										<label htmlFor={`f${item}t${nextVal}`}>
											<input
												type="checkbox"
												name="price-filter"
												id={`f${item}t${nextVal}`}
												value={`f${item}t${nextVal}`}
												checked={filterData.priceRange.to === nextVal}
												onChange={() => {
													if (filterData.priceRange.to === nextVal) {
														dispatch(setFilterPriceRange({}));
													} else {
														dispatch(
															setFilterPriceRange({ from: item, to: nextVal })
														);
													}
												}}
											/>
											{formatCurrency(item)} - {formatCurrency(nextVal)}
										</label>
									</li>
								);
							})}
						</ul>
					</div>
					<div className="shop-sidebar__section__item">
						<button
							onClick={() => dispatch(resetFilter())}
							className="btn -transparent -underline"
						>
							Borrar filtros
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
