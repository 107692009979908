import React from "react";

import { Link, withRouter, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import classNames from "classnames";

import { formatCurrency } from "../../../common/utils";
import { addToCart } from "../../../redux/actions/cartActions";
import { addToWishlist } from "../../../redux/actions/wishlistActions";
import ProductDetailController from "./ProductDetailController";
import ProductDetailInfoTab from "./ProductDetailInfoTab";
import Rate from "../../Other/Rate";
import jar from "../../../assets/images/jars/lid.svg";
import { checkProductInWishList, calculate } from "../../../common/shopUtils";

function ProductDetailInfo(props) {
	const { data, onReviewSubmit, hideTab } = props;
	const { slug } = useParams();

	const dispatch = useDispatch();
	const wishlistState = useSelector((state) => state.wishlistReducer);
	const [quantity, setQuantity] = useState();
	const [currentId, setCurrentId] = useState(slug);
	const [otherSize, setOtherSize] = useState(
		data.variants && data.variants.length > 0 ? data.variants[0] : null
	);

	const calculatePrice = () => {
		console.log(otherSize)
		return otherSize ? otherSize.price : 0;
	};

	const calculateDiscount = (data) => {
		const price = calculatePrice();
		const ruleOfThree = data.discount * price / 100;
		return Math.round(price - ruleOfThree)
	}
	

	useEffect(() => {
		// if (currentId != slug) {
		// 	setCurrentId(slug);
		//window.location.reload();
		// }
	}, []);
	const getQuantity = (q) => {
		setQuantity(q);
	};
	const onAddToCart = (e) => {
		e.preventDefault();
		dispatch(addToCart(data, quantity, otherSize?.value));
		toast.success("Producto añadido al carrito");
	};
	const onAddToWishList = (e) => {
		e.preventDefault();
		let product = checkProductInWishList(wishlistState, data.id);
		dispatch(addToWishlist(data));
		toast.dismiss();
		if (!product) {
			return toast.success("Product added to wishlist !");
		} else {
			return toast.error("Product removed from wishlist !");
		}
	};
	return (
		<div className="product-detail__content">
			<div className="product-detail__content__header">
				<h5>{data.category}</h5>
				<h2>{data.name}</h2>

				<h3>
					{data.discount
						? formatCurrency(calculateDiscount(data))
						: formatCurrency(calculatePrice())}
					{data.discount ? <span>{formatCurrency(calculatePrice())}</span> : null} 
				</h3>
			</div>
			<div className="divider"></div>
			<div className="product-detail__content__footer">
				<ul>
					<li>
						<i
							className={`fa ${data.quantity > 0 ? "fa-check" : "fa-times"}`}
							style={{ marginRight: "2px" }}
						/>
						Disponibilidad:
						{data.quantity > 0 ? (
							<span className="in-stock"> En stock.</span>
						) : (
							<span className="out-stock"> Agotado.</span>
						)}
					</li>
					<li style={{ textTransform: "none" }}>
						<i className="fa fa-truck" style={{ marginRight: "2px" }} />
						Envíos en Montevideo con un mínimo de compra de $1000.
					</li>
					<li style={{ textTransform: "none" }}>
						<i className="fa fa-dollar" style={{ marginRight: "2px" }} />
						Costo del delivery en Montevideo: $350.
					</li>
					<li style={{ textTransform: "none" }}>
						<i className="fa fa-info-circle" style={{ marginRight: "2px" }} />
						Envíos gratis en Montevideo con compras mayores a $3000.
					</li>
					{data.isNotEaten && (
						<li>
							<i className="fa fa-warning" style={{ marginRight: "2px" }} />
							No ingerir.
						</li>
					)}
				</ul>

				{data.variants && (
					<div
						className="product-detail__colors"
						style={{ display: "flex", alignItems: "center" }}
					>
						<span>Tamaño:</span>
						{data.variants.map((bottle, index) => (
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									paddingLeft: "5px",
								}}
							>
								<img
									key={index}
									className={`product-colors__item${`-${bottle.id}`} ${classNames(
										{
											active: otherSize && otherSize.value === bottle.value,
										}
									)}`}
									src={jar}
									// style={{ backgroundColor: 'red' }}
									onClick={() => {
										setOtherSize(bottle);
										// setCurrentPic((p) => (p == i1 ? i2 : i1));
									}}
								/>
								<p style={{ fontSize: "12px" }}>{`${bottle.value}${data.unit}`}</p>
							</div>
						))}
					</div>
				)}
				<ProductDetailController
					data={data}
					getQuantity={getQuantity}
					onAddToCart={onAddToCart}
					onAddToWishList={onAddToWishList}
					size={otherSize}
				/>
			</div>
			{!hideTab && (
				<>
					<div className="divider"></div>
					<div className="product-detail__content__tab">
						<ProductDetailInfoTab data={data} onReviewSubmit={onReviewSubmit} />
					</div>
				</>
			)}
		</div>
	);
}

export default withRouter(ProductDetailInfo);
