import React, { Component } from "react";
import OrderUpdateModal from "../Modals/OrderUpdateModal";
import { firebase } from "../../../firebase";
import OrderTR from "../orders/OrderTR";

class OrdersTable extends Component {
	state = {
		ordersData: [],
		loading: true,
		orderUpdateModal: false,
		order: "",
	};

	componentDidMount() {
		const db = firebase.firestore();
		const dbOrderRef = db.collection("orders");
		let orderArray = [];
		dbOrderRef.where('status', '!=', 'mercado')
			.get()
			.then((res) => {
				res.forEach((doc) => {
					let orderObj = doc.data();
					orderObj.id = doc.id;
					orderArray.push(orderObj);
				});
				this.setState({
					ordersData: orderArray,
				});
				this.loading = false;
			})
			.catch((err) => {
				console.log("error", err);
			});
	}

	toggleOrderUpdateModal = (e) => {
		this.setState({
			orderUpdateModal: e,
		});
	};

	closeOrderUpdateModal = () => {
		this.setState({
			orderUpdateModal: false,
		});
	};

	getId = (e) => {
		this.setState({
			order: e,
		});
	};
	render() {
		const { ordersData, loading } = this.state;
		return (
			<div className="admin-main-content d-flex flex-column">
				<div className="page-header">
					<h2>Ordenes</h2>
				</div>

				<div className="admin-table admin-orders-table height-555">
					<div className="table-responsive">
						<table className="table table-hover mb-0">
							<thead>
								<tr>
									<th>Nombre</th>
									<th>Fecha creación</th>
									<th>Fecha de entrega</th>
									<th>Forma de envío</th>
									<th>Forma de pago</th>
									<th>RUT</th>
									<th>Dirección</th>
									<th>Zona</th>
									<th>C.P.</th>
									<th>Telefono</th>
									<th>Mail</th>
									<th>Monto</th>
									<th>Estado</th>
								</tr>
							</thead>
							<tbody>
								{loading ? (
									<OrderTR
										onOrderModal={this.toggleOrderUpdateModal}
										onPassId={this.getId}
										orders={ordersData}
									/>
								) : (
									"Loading..."
								)}
							</tbody>
						</table>
					</div>
				</div>

				{this.state.orderUpdateModal && (
					<OrderUpdateModal
						onClick={this.closeOrderUpdateModal}
						order={this.state.order}
						active={this.state.orderUpdateModal ? "active" : ""}
					/>
				)}

				{/* Footer */}
				<div className="flex-grow-1"></div>
			</div>
		);
	}
}

export default OrdersTable;
