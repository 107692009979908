import React from "react";

import VideoFrame from "../../Other/VideoFrame";
import tick from '../../../assets/images/about/tick.svg';

export default function IntroductionTwo({ data, style }) {
	return (
		<div className="introduction-two" style={style}>
			<VideoFrame height={500} /> 
			<div className="introduction-two__content">
				<div className="container">
					{data.map((item, index) => (
						<div key={index} className={`introduction-two__content__item`}>
							<div style={{display: 'flex'}}><img src={tick} style={{height: '20px', paddingRight: '10px'}}/><h2>{item.name}</h2></div>
							<p>{item.description && item.description}</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
