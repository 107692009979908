import React from "react";
import Slider from "react-slick";

import Button from "../../Control/Button";
import { PrevArrow, NextArrow } from "../../Other/SliderArrow";

export default function IntroductionNine() {
	const settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 6,
		slidesToScroll: 1,
		prevArrow: <PrevArrow />,
		nextArrow: <NextArrow />,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 5,
				},
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 2,
				},
			},
		],
	};
	return (
		<div className="introduction-nine">
			<div className="introduction-nine__logos">
				<div className="container">
					<Slider {...settings}>
						{Array.from(Array(6), (e, i) => {
							return (
								<div key={i} className="slide__item">
									<img
										src={require(`../../../assets/images/brands/BrandsOne/s${
											i + 1
										}-alt.png`)}
										// src={
										//   process.env.PUBLIC_URL +
										//   `/assets/images/introduction/IntroductionNine/${
										//     i + 1
										//   }.png`
										// }
										alt="Brand item"
									/>
								</div>
							);
						})}
					</Slider>
				</div>
			</div>
			<div className="container">
				<div className="introduction-nine__content">
					<h3>
						Proveemos productos <br />
						de alta calidad.
					</h3>
					<Button
						action="#"
						color="white"
						content="CONOZCA NUESTROS PRODUCTOS"
					/>
				</div>
			</div>
		</div>
	);
}
