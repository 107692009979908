import React, { Component } from "react";
import { toast } from "react-toastify";
import { firebase } from "../../firebase";
import TopNavbar from "../../components/Admin/TopNavbar";
import LeftSidebar from "../../components/Admin/LeftSidebar";
import withAuth from "../../helpers/withAuth";
import noPhoto from "../../assets/images/product/noPhoto.png";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import { isMobile } from '../../common/utils';

const override = css`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-self: center;
	justify-items: center;
	flex-direction: row;
	width: 100%;
	margin: 20% auto;
	align-items: center;
`;

class Vacations extends Component {
	state = {
		display: false,
		banners: [],
		loading: true,
		modalBlog: null,
	};

	deleteBanner = (banner) => {
		const db = firebase.firestore();
		const dbOrderRef = db.collection("banners");
		dbOrderRef
			.doc(banner.id)
			.delete()
			.then(() => {
				// Delete completed!
				toast.success("Banner fue eliminado exitosamente.", {
					position: "bottom-left",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				setTimeout(() => {
					window.location.reload();
				}, 3000);
			})
			.catch(function (error) {
				toast.error("Ha ocurrido un error", {
					position: "bottom-left",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			});
	};

	componentDidMount() {
		const db = firebase.firestore();
		const dbOrderRef = db.collection("banners");
		let bannersArray = [];
		dbOrderRef
			.get()
			.then((res) => {
				// console.log(res)
				res.forEach((doc) => {
					let productsObj = doc.data();
					productsObj.id = doc.id;
					bannersArray.push(productsObj);
				});
				this.setState({
					banners: bannersArray,
					loading: false,
				});
			})
			.catch((err) => {
				console.log("error", err);
			});
	}

	render() {
		let { banners } = this.state;
		return (
			<React.Fragment>
				<TopNavbar />
				<LeftSidebar />

				<div className="admin-main-content d-flex flex-column">
					<div className="page-header">
						<h2>Banner</h2>
					</div>

					<div className="admin-products">
						{this.state.loading ? (
							<BeatLoader
								css={override}
								size={isMobile() ? 20 : 50}
								color={"#10ABDE"}
								loading={true}
							/>
						) : (
							<div className="row">
								{banners.length ? (
									banners.map((banner, idx) => (
										<div key={idx} className="col-lg-4 col-md-6 col-sm-6">
											<div className="single-products-box">
												<div className="products-image">
													{/* <a
															onClick={(e) => {
																e.preventDefault();
																this.toggleViewProductModal();
																this.dataModal(product);
															}}
														> */}{" "}
													<img
														src={banner.image || noPhoto}
														className="main-image"
														alt="image"
													/>
													{/* </a> */}
													<div className="products-button">
														<ul>
															<li>
																<div className="quick-view-btn">
																	<button
																		onClick={() => this.deleteBanner(banner)}
																		className="invisible-btn"
																	>
																		<a>
																			<i className="bx bx-x"></i>
																			<span className="tooltip-label">
																				Eliminar
																			</span>
																		</a>
																	</button>
																</div>
															</li>
														</ul>
													</div>
												</div>

												<div className="products-content">
													<span className="new-price">{banner.comment}</span>
													<h3>{banner.title}</h3>
													<div className="price">
														<span className="new-price">{banner.subtitle}</span>
													</div>
												</div>
											</div>
										</div>
									))
								) : (
									<p className="no-element-msg">No hay banner.</p>
								)}
							</div>
						)}
					</div>

					{/* Footer */}
					<div className="flex-grow-1"></div>
				</div>

				{/* Sidebar Modal */}
				{/* {this.state.showEditModal && (
					<EditProductModal
						onClick={this.toggleEditProductModal}
						active={this.state.showEditModal ? "active" : ""}
						product={this.state.modalProduct}
					/>
				)}
				<ViewProductModal
					onClick={this.toggleViewProductModal}
					active={this.state.ViewProductModal ? "active" : ""}
					product={this.state.modalProduct}
				/> */}
			</React.Fragment>
		);
	}
}

export default withAuth(Vacations);
