import React, { Component } from "react";
import { toast, ToastContainer } from "react-toastify";
import { firebase } from "../../../firebase";
import { withRouter } from "react-router-dom";
import backendUrl from "../../../constants/urls";
import axios from "axios";

class AddCategoryModal extends Component {
	state = {
		modal: false,
		title: "",
		imageHoverUrl: "",
		previewImageHover: null,
	};

	baseState = this.state;
	resetForm = () => {
		this.setState(this.baseState);
	};

	closeModal = () => {
		this.props.onClick(this.state.modal);
	};

	handleChangeHover = (e) => {
		if (e.target.files[0]) {
			let imageHover = e.target.files[0];
			let reader = new FileReader();
			reader.readAsDataURL(imageHover);
			reader.onload = () => {
				// console.log(reader.result);
				this.setState({ previewImageHover: reader.result });
			};
			this.handleUploadHover(imageHover);
		}
	};

	handleUploadHover = (imageHover) => {
		const data = new FormData();
		if (imageHover) {
			data.append("productImageHover", imageHover, imageHover.name);
			axios
				.post(`${backendUrl}/api/upload-hover`, data, {
					headers: {
						accept: "application/json",
						"Accept-Language": "en-US,en;q=0.8",
						"Content-Type": `multipart/form-data; boundary=${data._boundary}`,
					},
				})
				.then((response) => {
					if (200 === response.status) {
						// If file size is larger than expected.
						if (response.data.error) {
							if ("LIMIT_FILE_SIZE" === response.data.error.code) {
								alert("Max size: 2MB");
							} else {
								console.log(response.data);
								// If not the given file type
								// alert( response.data.error, 'red' );
							}
						} else {
							// Success
							let fileName = response.data;
							this.setState({
								imageHoverUrl: fileName.location,
							});
							// console.log( 'fileName', fileName.location );
							// alert( 'File Uploaded', '#3089cf' );
						}
					}
				})
				.catch((error) => {
					// If another error
					alert(error);
				});
		} else {
			// if file not selected throw error
			alert("Please upload file");
		}
	};

	addCategory = async (e) => {
		e.preventDefault();
		let { title, imageHoverUrl } = this.state;

		const category = {
			title: title,
			image: imageHoverUrl,
		};

		if (title != "") {
			try {
				await axios.post(`${backendUrl}/api/categories/add`, category);
				toast.success("Categoría creada exitosamente.", {
					position: "bottom-left",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				setTimeout(() => {
					window.location.reload();
				}, 3000);
			} catch (e) {
				console.log(e)
				const { message } = e;
				toast.error("Ha ocurrido un error", {
					position: "bottom-left",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				setTimeout(() => {
					window.location.reload();
				}, 3000);
			}
			this.closeModal();
			this.resetForm();
		} else {
			toast.error("Rellene los campos", {
				position: "bottom-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	};

	render() {
		let { previewImageHover } = this.state;

		return (
			<React.Fragment>
				<div className={`admin-product-modal ${this.props.active}`}>
				<ToastContainer position="bottom-left" autoClose={3000} />
					<div className="modal-innter-content">
						<button type="button" className="close" onClick={this.closeModal}>
							<span aria-hidden="true">
								<i className="bx bx-x"></i>
							</span>
						</button>

						<div className="modal-body">
							<h3>Agregar Categoría</h3>
							<label>*Esta categoría será para agrupar tus productos.</label>

							<form onSubmit={this.addCategory}>
								<div className="form-group">
									<label>Nombre</label>
									<input
										type="text"
										className="form-control"
										value={this.state.title}
										onChange={(e) => this.setState({ title: e.target.value })}
									/>
								</div>

								<div className="form-group">
									<label>Subir la imagen aquí</label>
									<div className="upload-img">
										<span>
											<i className="bx bxs-image-add"></i>
											Seleccionar
										</span>

										<input
											type="file"
											className="form-control-file"
											name="productImageHover"
											onChange={this.handleChangeHover}
										/>
									</div>

									{previewImageHover ? (
										<div className="uploaded-img">
											<img
												src={previewImageHover}
												alt="Image"
												className="img-thumbnail"
											/>
										</div>
									) : null}
								</div>

								<div className="modal-btn">
									<div
										className="btn optional-btn float-left"
										onClick={this.closeModal}
									>
										Cancelar
									</div>
									<button className="btn default-btn float-right">
										Crear Categoría
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withRouter(AddCategoryModal);
