import React from 'react';
import LayoutFour from "../../components/Layout/LayoutFour";
import { Breadcrumb, BreadcrumbItem } from "../../components/Other/Breadcrumb";
import CTAOne from "../../components/Sections/CallToAction/CTAOne";
import ServiceItem from "../../components/Pages/Services/ServiceItem";
import { formatSingleNumber } from "../../common/utils";
import servicesData from "../../data/pages/services.json";
import products from '../../assets/images/banners/spa.jpg';
export default function services() {
  return (
    <LayoutFour title="Servicios">
      <Breadcrumb title="Servicios" img={products}>
        <BreadcrumbItem name="Inicio" />
        <BreadcrumbItem name="Servicios" current />
      </Breadcrumb>
      {servicesData &&
        servicesData.map((item, index) => (
          <ServiceItem
            key={index}
            idx={index}
            bigImgSrc={process.env.PUBLIC_URL + item.bigImgSrc}
            smallImgSrc={process.env.PUBLIC_URL + item.smallImgSrc}
            title={item.title}
            description={item.description}
            points={item.points}
            order={formatSingleNumber(index + 1)}
            reverse={index % 2 === 1}
          />
        ))}

      <CTAOne />
    </LayoutFour>
  );
}
