import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import map from "../../assets/images/mapa.jpeg";
import LayoutFour from "../../components/Layout/LayoutFour";
import { Breadcrumb, BreadcrumbItem } from "../../components/Other/Breadcrumb";
import InstagramTwo from "../../components/Sections/Instagram/InstagramTwo";
import { formatCurrency, formatSingleNumber } from "../../common/utils";
import {
	calculateTotalPrice,
	calculate,
	getTentativeDate,
	calculateDiscount,
} from "../../common/shopUtils";
import MercadoPago from "./MercadoPago";
import { calculateTotal } from "../../common/utils";
import NotificationModal from "../../components/Shop/NotificationModal";
import {
	addDelivery,
	removeDelivery,
	removeAllFromCart,
} from "../../redux/actions/cartActions";
import {
	addTemporaryData,
	removeTemporaryData,
} from "../../redux/actions/shopActions";
import axios from "axios";
import backendUrl from "../../constants/urls";
import {
	setOrderStatus,
	selectPaymentType,
	selectPaymentStatus,
	selectPaymentId,
} from "../../constants/payment_types";
import { LOCALITIES } from "../../constants/localities";
import spa from "../../assets/images/banners/spa-1.jpg";

export default function () {
	const dispatch = useDispatch();
	const cartState = useSelector((state) => state.cartReducer);
	const shopData = useSelector((state) => state.shopReducers);
	let history = useHistory();
	const [modalPendingShow, setModalPendingShow] = useState(false);
	const [voucherUrl, setVoucherUrl] = useState("");
	const [data, setData] = useState(null);
	const [locality, setLocality] = useState("Montevideo");
	const [modalConfirmedShow, setModalConfirmedShow] = useState(false);
	const [showMP, setShowMP] = useState(false);
	const [payment, setPayment] = useState("");
	const { register, handleSubmit, errors, watch } = useForm();
	const watchDelivery = watch("delivery");
	const isPickup = watchDelivery === "pickUp";

	const {
		register: couponRegister,
		handleSubmit: couponHandleSubmit,
		errors: couponErrors,
	} = useForm();

	useEffect(() => {
		dispatch(removeDelivery());

		//IF COMING BACK FROM MPAGO
		if (
			history?.location?.search &&
			history.location.search.includes("payment_type")
		) {
			handleMobile();
		}
		return () => {
			dispatch(removeDelivery());
		};
	}, []);

	const total = calculateTotal(cartState);
	const isBankTransfer = payment === "bank";

	const handleDelivery = (event) => {
		const { value } = event.target;
		if (value === "deliver" && locality === "Montevideo") {
			dispatch(addDelivery());
		} else if (value === "pickUp") {
			dispatch(removeDelivery());
		}
		setLocality("Montevideo");
	};

	const handleMobile = () => {
		const paymentData = history.location.search;
		if (paymentData && paymentData.includes("payment_type")) {
			const paymentType = paymentData ? selectPaymentType(paymentData) : null;
			const paymentId = paymentData ? selectPaymentId(paymentData) : null;

			if (paymentType) {
				const paymentStatus = paymentData
					? selectPaymentStatus(paymentData)
					: "No hay info";
				if (paymentStatus) {
					switch (paymentStatus) {
						case "approved":
							handleMercadoPagoCallback(paymentType, false, paymentId);

							setTimeout(function () {
								toast.success("Tu pago se ha completado con éxito.", {
									position: "bottom-left",
									autoClose: 3000,
									hideProgressBar: true,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
								});
							}, 1500);
							break;
						case "in_process":
							let inProcess = true;
							notify();
							handleMercadoPagoCallback(paymentType, inProcess, paymentId);

							setTimeout(function () {
								toast.warning(
									"Cuando recibamos tu pago aprobaremos tu compra.",
									{
										position: "bottom-left",
										autoClose: 3000,
										hideProgressBar: true,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
									}
								);
							}, 1500);
							break;
						case "pending":
							const isInProcess = true;
							notify();
							handleMercadoPagoCallback(paymentType, isInProcess, paymentId);

							setTimeout(function () {
								toast.warning(
									"Cuando recibamos tu pago aprobaremos tu compra.",
									{
										position: "bottom-left",
										autoClose: 3000,
										hideProgressBar: true,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
									}
								);
							}, 1500);
							break;
						case "rejected":
							setTimeout(function () {
								toast.error("Ha ocurrido un error con tu pago.", {
									position: "bottom-left",
									autoClose: 3000,
									hideProgressBar: true,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
								});
							}, 1500);
							break;
						default:
							setTimeout(function () {
								toast.error("Ha ocurrido un error con tu pago.", {
									position: "bottom-left",
									autoClose: 1000,
									hideProgressBar: true,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
								});
							}, 1500);
							break;
					}
				}
			}
		}

		return;
	};

	const notify = async () => {
		await axios.post(
			`${backendUrl}/api/mailer/notify`,
			{},
			{
				headers: {
					accept: "application/json",
					"Accept-Language": "en-US,en;q=0.8",
				},
			}
		);
	};

	const handleModalClose = async () => {
		setModalPendingShow(false);
		setModalConfirmedShow(false);
		await notify();
		history.push("/");
	};

	function handleFileChange(e) {
		if (e.target.files[0]) {
			let image = e.target.files[0];
			let reader = new FileReader();
			reader.readAsDataURL(image);
			reader.onload = () => {};
			handleUpload(image);
		}
	}

	function handleUpload(image) {
		const data = new FormData();
		if (image) {
			data.append("productImage", image, image.name);
			axios
				.post(`${backendUrl}/api/upload`, data, {
					headers: {
						accept: "application/json",
						"Accept-Language": "en-US,en;q=0.8",
						"Content-Type": `multipart/form-data; boundary=${data._boundary}`,
					},
				})
				.then((response) => {
					if (200 === response.status) {
						// If file size is larger than expected.
						if (response.data.error) {
							if ("LIMIT_FILE_SIZE" === response.data.error.code) {
								alert("Max size: 2MB");
							} else {
								// If not the given file type
								alert(response.data.error);
							}
						} else {
							// Success
							let fileName = response.data;
							let value = fileName.location;
							setVoucherUrl(value);
							console.log("fileName", fileName.location);
							// alert( 'File Uploaded', '#3089cf' );
						}
					}
				})
				.catch((error) => {
					// If another error
					alert(error, "red");
				});
		} else {
			// if file not selected throw error
			alert("Please upload file");
		}
	}

	const onSubmit = (data) => {
		const copiedData = {
			...data,
			amount: calculateTotalPrice(
				cartState,
				!isPickup && watchDelivery,
				total,
				isBankTransfer
			),
		};
		setData(data);
		dispatch(addTemporaryData(copiedData));
		if (!isBankTransfer) {
			setShowMP(true);
		} else {
			try {
				addToCart(data, "TRANSFERENCIA", true);
				setModalPendingShow(true);
			} catch (error) {
				alert(error);
			}
		}
	};

	const handleMercadoPagoCallbackNew = async (
		mercadoNumber
	) => {
		const temporaryData = shopData?.temporaryData;
		if (temporaryData) {
			try {
					const order = {
						mercadoNumber: mercadoNumber,
						customerDetails: {
							firstName: temporaryData.firstName,
							lastName: temporaryData.lastName,
							email: temporaryData.contact,
							phone: temporaryData.phone,
							rut: temporaryData.rut,
							address: temporaryData.streetAddress,
							extraAddress: temporaryData.apartment,
							town: temporaryData.town,
							zip: temporaryData.zip,
						},
						deliveryTime: getTentativeDate(!isPickup, locality == "Montevideo"),
						voucherUrl: "",
						deliveryMethod: temporaryData.delivery, //pickUp or deliver
						comments: temporaryData.note,
						suscribed: temporaryData.subcribeNews,
						items: [...cartState],
						amount:
							temporaryData.amount ||
							calculateTotalPrice(
								cartState,
								!isPickup && watchDelivery,
								total,
								isBankTransfer
							),
						status: 'mercado',
						paymentType: "No hay info",
						paymentId: "",
					};
	
					await axios.post(`${backendUrl}/api/orders/add-temporary-order`, order);
		

				
			} catch (error) {
				alert(error);
			}
		}
	};

	
	const handleMercadoPagoCallback = (
		paymentType,
		inProcess = false,
		paymentId = ""
	) => {
		const temporaryData = shopData?.temporaryData;
		if (temporaryData) {
			try {
				addToCart(temporaryData, paymentType, inProcess, paymentId);
				setModalConfirmedShow(true);
			} catch (error) {
				alert(error);
			}
		}
	};

	const addToCart = async (
		data,
		paymentType,
		inProcess = false,
		paymentId = ""
	) => {
		if (data && paymentType) {
			const order = {
				customerDetails: {
					firstName: data.firstName,
					lastName: data.lastName,
					email: data.contact,
					phone: data.phone,
					rut: data.rut,
					address: data.streetAddress,
					extraAddress: data.apartment,
					town: data.town,
					zip: data.zip,
				},
				deliveryTime: getTentativeDate(!isPickup, locality == "Montevideo"),
				voucherUrl: paymentType == "TRANSFERENCIA" ? voucherUrl : "",
				deliveryMethod: data.delivery, //pickUp or deliver
				comments: data.note,
				suscribed: data.subcribeNews,
				items: [...cartState],
				amount:
					data.amount ||
					calculateTotalPrice(
						cartState,
						!isPickup && watchDelivery,
						total,
						isBankTransfer
					),
				status: setOrderStatus(paymentType, inProcess),
				paymentType: paymentType,
				paymentId: paymentId,
			};

			 await axios.post(`${backendUrl}/api/orders/add`, order);

			if (!inProcess) {
				await axios.post(`${backendUrl}/api/mailer/confirm`, order);
			}

			dispatch(removeTemporaryData());

			dispatch(removeAllFromCart());
		}
	};

	const handleLocality = (e) => {
		setLocality(e.target.value);
		if (e.target.value !== "Montevideo") {
			dispatch(removeDelivery());
		} else if (!isPickup && e.target.value == "Montevideo") {
			dispatch(addDelivery());
		}
	};

	const onCouponSubmit = (data) => console.log(data);

	return (
		<LayoutFour title="Resumen">
			<Breadcrumb title="Resumen" img={spa}>
				<BreadcrumbItem name="Tienda" />
				<BreadcrumbItem name="Resumen" current />
			</Breadcrumb>
			<div className="checkout">
				<div className="container">
					<NotificationModal
						show={modalPendingShow}
						title={"Pedido creado"}
						subtitle={"Pendiente de confirmación."}
						content={
							"Recibirás un correo con la confirmación del pedido a la brevedad. Muchas gracias."
						}
						onHide={handleModalClose}
					/>
					<NotificationModal
						show={modalConfirmedShow}
						title={"Pedido creado"}
						subtitle={"Hemos recibido tu pedido."}
						content={
							"Recibirás un correo con el resumen del pedido. Muchas gracias."
						}
						onHide={handleModalClose}
					/>
					<div className="row">
						<div>
							<p
								style={{
									marginBottom: "40px",
									backgroundColor: "#f1f1f1",
									padding: "10px",
									borderRadius: "10px",
									fontSize: "13px",
									width: "fit-content",
									lineHeight: "20px",
								}}
							>
								<h3 style={{ marginBottom: "5px" }}>Notas:</h3>
								<h4
									style={{
										color: "#10ABDE",
										fontSize: "1.1rem",
										marginTop: "5px",
									}}
								>
									Generales:
								</h4>
								<p>
									-Mínimo de <strong>$1000</strong> para envíos.
								</p>
								<p>
									-Para compras mayores a <strong>$3000</strong> el envío es
									gratuito.
								</p>
								<p>
									-Los pagos con transferencia tienen <strong>5%</strong> de
									descuento (Brou & Santander).
								</p>
								<h4
									style={{
										color: "#10ABDE",
										fontSize: "1.1rem",
										marginTop: "10px",
									}}
								>
									Retiros:
								</h4>
								<p>
									-Se retiran después de <strong>24hrs</strong> los días
									hábiles. Pasadas las 15.00hrs se retiran después de{" "}
									<strong>48hrs</strong>.
								</p>
								<p>
									-La dirección para retirar en tienda es Magallanes 920,
									Montevideo. Horarios: L-V 8:30 a 12:30, y 13:00 a 17:30.
								</p>
								<h4
									style={{
										color: "#10ABDE",
										fontSize: "1.1rem",
										marginTop: "10px",
									}}
								>
									Envíos a Montevideo:
								</h4>
								<p>
									-Se entregan después de <strong>48hrs</strong> los días
									hábiles.
								</p>{" "}
								<p>
									-Ver mapa con <strong>zonas de envío.</strong>
								</p>
								<p>-Los envíos dentro de Montevideo tienen un costo de $350.</p>{" "}
								<h4
									style={{
										color: "#10ABDE",
										fontSize: "1.1rem",
										marginTop: "10px",
									}}
								>
									Envíos al interior:
								</h4>
								<p>
									-Se entregan después de <strong>72hrs</strong> los días
									hábiles.
								</p>{" "}
								<p>
									-Los envíos al interior son realizados por la agencia DAC.
								</p>{" "}
								<p>-Cobro del servicio por parte del receptor.</p>{" "}
							</p>
						</div>
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px', marginLeft: '30px' }}>
							<p style={{fontSize: '12px', paddingBottom: '20px'}}>
								<strong>Zonas de envío dentro de Montevideo</strong>:
							</p>
							<img src={map} style={{ width: "300px" }} />
						</div>
					</div>

					<div className="row">
						<div className="col-12  col-lg-8">
							<form>
								<div className="checkout__form">
									<div className="checkout__form__contact">
										<div className="checkout__form__contact__title">
											<h5 className="checkout-title">Opción de entrega:</h5>
										</div>
										<div className="row">
											<div className="col-12 col-md-6">
												<select
													name="delivery"
													ref={register}
													onChange={handleDelivery}
												>
													<option value="pickUp">Retiro en tienda</option>
													<option
														disabled={total && total < 999}
														value="deliver"
													>
														Envío a domicilio
													</option>
												</select>
											</div>
										</div>
									</div>

									<div className="checkout__form__contact">
										<div className="checkout__form__contact__title">
											<h5 className="checkout-title">Método de pago:</h5>
										</div>
										<div className="row">
											<div className="col-12 col-md-6">
												<select
													name="payment"
													ref={register}
													onChange={(e) => setPayment(e.target.value)}
												>
													<option value="mercadopago">
														Tarjeta/Red de cobranza
													</option>
													<option value="bank">Transferencia bancaria</option>
												</select>
											</div>
										</div>
										{isBankTransfer && (
											<>
												<div className="row">
													<div className="col-12 col-md-12">
														<p style={{ marginTop: "10px", fontSize: "13px" }}>
															Si pagás por transferencia tenés un{" "}
															<strong>5%</strong> descuento sobre el total.
														</p>
													</div>
												</div>
												<div className="row">
													<div className="col-12 col-md-12">
														<p style={{ marginTop: "10px", fontSize: "13px" }}>
															<strong>*</strong>Para que aprobemos tu compra más
															rápido te sugerimos subir el comprobante.
														</p>
													</div>
												</div>
											</>
										)}
										{isBankTransfer && (
											<div className="row">
												<div className="col-12 col-md-6">
													<div
														style={{
															marginTop: "10px",
															border: "1px solid #10ABDE",
															padding: "5px",
														}}
													>
														<p style={{ fontSize: "13px" }}>
															Concepto de la transferencia:{" "}
															<strong>APELLIDO</strong>
														</p>
														<p style={{ fontSize: "13px" }}>
															<strong>Banco Santander: </strong>Cta. cte.{" "}
															<strong>$ 803 4024 805</strong> / Cta. cte.{" "}
															<strong>U$S 803 4024 902</strong>. Sucursal 2.
														</p>

														<p style={{ fontSize: "13px" }}>
															<strong>Brou:</strong> Caja ahorro{" "}
															<strong>$ 001 551063 00001</strong> / Caja ahorro{" "}
															<strong>U$S 001 551063 00002</strong>
														</p>
													</div>
												</div>
											</div>
										)}
										{isBankTransfer && (
											<div className="row">
												<div className="col-12 col-md-6">
													<div className="upload-img">
														<input
															style={{ fontSize: "14px", marginTop: "10px" }}
															type="file"
															className="form-control-file"
															name="voucherUrl"
															accept="image/*"
															onChange={handleFileChange}
														/>
													</div>
												</div>
											</div>
										)}
									</div>

									<div className="checkout__form__contact">
										<div className="checkout__form__contact__title">
											<h5 className="checkout-title">
												Información de contacto:
											</h5>
										</div>
										<div className="input-validator">
											<input
												type="text"
												name="contact"
												ref={register({ required: true })}
												placeholder="Ingrese su email"
											/>
											{errors.contact && (
												<span className="input-error">
													Este campo es obligatorio.
												</span>
											)}
										</div>
									</div>
									<div className="checkout__form__shipping">
										<div className="row">
											<div className="col-12 col-md-6">
												<div className="input-validator">
													<label>
														Nombre <span>*</span>
														<input
															type="text"
															name="firstName"
															ref={register({ required: true })}
														/>
													</label>
													{errors.firstName && (
														<span className="input-error">
															Este campo es obligatorio.
														</span>
													)}
												</div>
											</div>
											<div className="col-12 col-md-6">
												<div className="input-validator">
													<label>
														Apellido <span>*</span>
														<input
															type="text"
															name="lastName"
															ref={register({ required: true })}
														/>
													</label>
													{errors.lastName && (
														<span className="input-error">
															Este campo es obligatorio.
														</span>
													)}
												</div>
											</div>
											<div className="col-12 col-md-6">
												<div className="input-validator">
													<label>
														Celular <span>*</span>
														<input
															type="text"
															name="phone"
															ref={register({ required: true })}
														/>
													</label>
													{errors.phone && (
														<span className="input-error">
															Este campo es obligatorio.
														</span>
													)}
												</div>
											</div>
											<div className="col-12 col-md-6">
												<div className="input-validator">
													<label>
														RUT (opcional para factura)
														<input type="text" name="rut" ref={register} />
													</label>
													{errors.rut && (
														<span className="input-error">
															Este campo es obligatorio.
														</span>
													)}
												</div>
											</div>

											{watchDelivery === "deliver" && (
												<>
													<div className="col-12">
														<div className="input-validator">
															<label>
																Dirección <span>*</span>
																<input
																	type="text"
																	name="streetAddress"
																	ref={register({
																		required: isPickup ? false : true,
																	})}
																	placeholder="Información principal: calle y número de puerta"
																/>
																<input
																	type="text"
																	name="apartment"
																	ref={register({
																		required: isPickup ? false : true,
																	})}
																	placeholder="Información secundaria: Apto 1A, esquina, etc."
																/>
															</label>
															{errors.streetAddress || errors.apartment ? (
																<span className="input-error">
																	Este campo es obligatorio.
																</span>
															) : null}
														</div>
													</div>
													<div className="col-12">
														<div className="input-validator">
															<label>
																Ciudad <span>*</span>
																{/* <input
																	type="text"
																	name="town"
																	ref={register({
																		required: isPickup ? false : true,
																	})}
																/> */}
															</label>

															<div className="row">
																<div className="col-12 col-md-6">
																	<select
																		value={locality}
																		name="town"
																		ref={register({
																			required: isPickup ? false : true,
																		})}
																		onChange={handleLocality}
																	>
																		{LOCALITIES.map((l) => (
																			<option value={l.name}>{l.name}</option>
																		))}
																	</select>
																</div>
															</div>
															{errors.town && (
																<span className="input-error">
																	Este campo es obligatorio.
																</span>
															)}
														</div>
													</div>
													<div className="col-12">
														<div className="input-validator">
															<label>
																Código postal <span>*</span>
																<input
																	type="text"
																	name="zip"
																	ref={register({
																		required: isPickup ? false : true,
																	})}
																/>
															</label>
															{errors.zip && (
																<span className="input-error">
																	Este campo es obligatorio.
																</span>
															)}
														</div>
													</div>
												</>
											)}

											<div className="col-12">
												<div className="input-validator">
													<label>
														Comentarios
														<input
															type="text"
															name="note"
															placeholder="Notas extras. ej:'Para regalo'."
															ref={register()}
														/>
													</label>
												</div>
											</div>
											<label className="checkbox-label" htmlFor="subcribe-news">
												<input
													type="checkbox"
													id="wants-rut"
													name="subcribeNews"
													ref={register}
												/>
												Quiero recibir noticias y descuentos exclusivos.
											</label>
										</div>
									</div>
								</div>
							</form>
						</div>
						<div className="col-12 col-lg-4">
							<div className="row">
								<div className="col-12 col-md-6 col-lg-12 ml-auto">
									<div className="checkout__total">
										<h5 className="checkout-title">Tu pedido</h5>

										{cartState && cartState.length > 0 ? (
											<div className="checkout__total__price">
												<h5>Producto(s)</h5>
												<table>
													<colgroup>
														<col style={{ width: "70%" }} />
														<col style={{ width: "30%" }} />
													</colgroup>
													<tbody>
														{cartState.map((item) => (
															<tr key={item.cartId}>
																<td>
																	<span>
																		{formatSingleNumber(item.cartQuantity)}
																	</span>{" "}
																	x {item.name} {item.selectedColor}
																	{item.unit}
																</td>
																<td>
																	{formatCurrency(
																		calculate(item) * item.cartQuantity
																	)}
																</td>
															</tr>
														))}
													</tbody>
												</table>
												<div className="checkout__total__price__total-count">
													<table>
														<tbody>
															<tr>
																<td>Subtotal</td>
																<td>${calculateTotalPrice(cartState)}</td>
															</tr>
															{!isPickup &&
																watchDelivery &&
																total < 3000 &&
																locality === "Montevideo" && (
																	<tr>
																		<td>Delivery</td>
																		<td>$350</td>
																	</tr>
																)}
															{!isPickup &&
																watchDelivery &&
																total > 3000 &&
																locality === "Montevideo" && (
																	<tr>
																		<td>Delivery</td>
																		<td>GRATIS</td>
																	</tr>
																)}
															{isBankTransfer && (
																<tr>
																	<td>Descuento transferencia</td>
																	<td>
																		- $
																		{calculateDiscount(
																			cartState,
																			!isPickup &&
																				watchDelivery &&
																				locality === "Montevideo",
																			total,
																			isBankTransfer
																		)}
																	</td>
																</tr>
															)}
															<tr>
																<td>Total</td>
																<td>
																	$
																	{calculateTotalPrice(
																		cartState,
																		!isPickup &&
																			watchDelivery &&
																			locality === "Montevideo",
																		total,
																		isBankTransfer
																	)}
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										) : (
											<div className="checkout__total__price">
												<h5>No tienes productos en el carrito.</h5>
											</div>
										)}

										{showMP && !isBankTransfer ? (
											<MercadoPago
												paymentCallback={handleMercadoPagoCallbackNew}
												forceNotification={notify}
												mercadoNumber={uuidv4()}
											/>
										) : (
											cartState &&
											cartState.length > 0 && (
												<button
													className="btn -red"
													onClick={handleSubmit(onSubmit)}
												>
													Comprar
												</button>
											)
										)}
										{shopData?.vacationNotification && (
											<p style={{ fontSize: "12px", marginTop: "10px" }}>
												<strong>Atención:</strong>
												{shopData?.vacationNotification?.content}
											</p>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<InstagramTwo />
		</LayoutFour>
	);
}
