import React from "react";
import { Link } from "react-router-dom";
import alt from "../../assets/images/blog/1.png";

export default function PostCardTwo({ data, photo }) {
	const convertDateTime = (d) => {
		let t = new Date(1970, 0, 1);
		if (t && d) {
			let time = t.setSeconds(d.seconds);
			const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
				time
			);
			const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(time);
			const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(time);
			return `${da}-${mo}-${ye}`;
		}
	};

	return (
		<div className="post-card-two">
			<div className="post-card-two__image">
				<img src={data.coverImageUrl || alt} alt={data.mainTitle} />
				<div className="post-card-two__info">
					<div className="post-card-two__info__date">
						<h5> {convertDateTime(data.createdAt)}</h5>
					</div>
					<div className="post-card-two__info__detail">
						<Link to="#">
							<a>{data.category}</a>
						</Link>
					</div>
				</div>
			</div>
			<div className="post-card-two__content">
				<Link to={"/blog/post/" + data.id}>
					<a>{data.mainTitle}</a>
				</Link>
				<p>
					{" "}
					{data.content && data.content.length > 0 ? data.content[0].text : ""}
				</p>
			</div>
		</div>
	);
}
