import React from "react";

import { Link } from "react-router-dom";
import classNames from "classnames";

import menuData from "../../../data/header/navigation.json";
import leaf from "../../../assets/images/logo/leaves.svg";

export default function Navigator({ disableSubmenu, className }) {
	function renderMenu() {
		return menuData.map((item, index) => {
			if (item.title === "Tienda" || item.title === "Nosotros") {
				return (
					<li key={index}>
						<Link to={item.to}>
							{item.title}
							<span className="dropable-icon">
								<i className="fa fa-angle-down"></i>
							</span>
						</Link>
						<ul className="dropdown-menu -wide">
							<ul className="dropdown-menu__col">
								{item.subMenu.slice(0, 4).map((i, index) => (
									<li key={index}>
										<Link to={i.to}>
											<img
												src={leaf}
												style={{ height: "20px", marginRight: "8px" }}
											/>
											{i.title}
										</Link>
									</li>
								))}
							</ul>
							<ul className="dropdown-menu__col">
								{item.subMenu.slice(4, 8).map((i, index) => (
									<li key={index}>
										<Link to={i.to}>
											{" "}
											<img
												src={leaf}
												style={{ height: "20px", marginRight: "8px" }}
											/>
											{i.title}
										</Link>
									</li>
								))}
							</ul>
							<ul className="dropdown-menu__col">
								{item.subMenu.slice(8).map((i, index) => (
									<li key={index}>
										<Link to={i.to}>
											{" "}
											<img
												src={leaf}
												style={{ height: "20px", marginRight: "8px" }}
											/>
											{i.title}
										</Link>
									</li>
								))}
							</ul>
						</ul>
					</li>
				);
			}
			return (
				<li key={index}>
					<Link to={item.to}>
						<a>{item.title}</a>
					</Link>
				</li>
			);
		});
	}
	if (disableSubmenu) {
		return (
			<div className={`navigator -off-submenu ${classNames(className)}`}>
				<ul>
					{menuData.map((item, index) => (
						<li key={index}>
							<Link to={item.to}>
								<a>{item.title}</a>
							</Link>
						</li>
					))}
				</ul>
			</div>
		);
	}
	return (
		<div className={`navigator ${classNames(className)}`}>
			<ul>{renderMenu()}</ul>
		</div>
	);
}
