export const product = {
  DEFAULT_QUANTITY: 1,
};

export const blog = {
  CATEGORIES: ["Beauty tips", "Make up", "Skin care", "Body care", "Videos"],
};

export const shop = {
  CATEGORISE: ["Todos", "Aceites", "Fragancias", "Colorantes", "Sabores", "Vitaminas", "Otros"],
  SIZES: ["30", "100", "500", "1000"],
  PRICE_POINTS: [0, 200, 500, 750, 1000],
  SORT_TYPES: [
    { name: "Todos", value: "default" },
    { name: "Precio ascendente", value: "lowToHigh" },
    { name: "Precio descendente", value: "highToLow" },
  ],
  DEFAULT_VIEW: "grid",
};
