import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";

import Rate from "../Other/Rate";
import Button from "../Control/Button";
import Modal from "../Control/Modal";
import ProductQuickView from "../ProductDetail/ProductQuickView";
import { formatCurrency } from "../../common/utils";
import { checkProductInCart } from "../../common/shopUtils";
import { addToCart } from "../../redux/actions/cartActions";
import AddToCart from "../Control/AddToCart";
import i1 from "../../assets/images/product/product_1.jpg";
import i2 from "../../assets/images/product/product_4.jpg";
import jar from "../../assets/images/jars/lid.svg";
import noPhoto from "../../assets/images/product/noPhoto.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function Product({ data, type, className }) {
	const dispatch = useDispatch();
	const cartState = useSelector((state) => state.cartReducer);
	const [showQuickView, setShowQuickView] = useState(false);
	const [otherSize, setOtherSize] = useState(
		data.variants && data.variants.length > 0 ? data.variants[0] : null
	);

	useEffect(() => {
		setOtherSize(
			data.variants && data.variants.length > 0 ? data.variants[0] : null
		);
	}, [data]);

	const calculatePrice = () => {
		return otherSize ? Number(otherSize.price) : 0;
	};

	const calculateDiscount = (data) => {
		const price = calculatePrice();
		const ruleOfThree = (data.discount * price) / 100;
		return Math.round(price - ruleOfThree);
	};

	const [currentPic, setCurrentPic] = useState(i1);
	const percentDiscount = data.discount;

	function renderType() {
		if (data.new) {
			return <h5 className="-new">Nuevo</h5>;
		}
		if (
			data.discount &&
			typeof data.discount === "number" &&
			data.discount > 0
		) {
			return <h5 className="-sale">-{percentDiscount}%</h5>;
		}
		return null;
	}
	const addToCartHandle = (e) => {
		e.preventDefault();
		let productItem = checkProductInCart(cartState, data.id, otherSize?.value);
		if (!productItem && data.quantity > 0) {
			dispatch(addToCart(data, 1, otherSize?.value, e)); //prvious color
			return toast.success("Producto añadido al carrito");
		}
	};

	return (
		<>
			{!type || type === "grid" ? (
				<div className={`product ${classNames(className)}`}>
					<div className="product-type">{renderType()}</div>
					<div className="product__thumb">
						<Link
							className="product__thumb__image"
							to={`/shop/product/${data.slug}`}
						>
							{data.thumbImage && data.thumbImage.length > 0 ? (
								data.thumbImage
									.slice(0, 1)
									.reverse()
									.map((img, index) => (
										<LazyLoadImage effect="blur" key={index} src={img} />
									))
							) : (
								<img key={1} src={noPhoto} alt="Product image" />
							)}

							{/* {otherSize && (
									// <img src={otherSize.image} alt="Product image" />
									<img src={currentPic} alt="Product image" />
								)} */}
						</Link>
						<div className="product__thumb__actions">
							<div className="product-btn" data-tip data-for="cartIcon">
								<Button
									height="50px"
									width="50px"
									color="white"
									className={`product__actions__item  ${classNames({
										"active -disable":
											checkProductInCart(
												cartState,
												data.id,
												otherSize?.value
											) || data.quantity < 1,
									})}
                  `}
									action="#"
									onClick={addToCartHandle}
									content={<i className="fa fa-shopping-bag" />}
								></Button>
							</div>
							<ReactTooltip id="cartIcon" type="dark" effect="solid">
								<span>Agregar al carrito</span>
							</ReactTooltip>
						</div>
					</div>
					<div className="product__content">
						<div className="product__content__header">
							<h5 className="product-category">{data.category}</h5>
						</div>
						<Link className="product-name" to={`/shop/product/${data.slug}`}>
							{data.name}
						</Link>
						<div className="product__content__footer">
							<h5 className="product-price--main">
								{data.discount && data.discount > 0
									? formatCurrency(calculateDiscount(data))
									: formatCurrency(calculatePrice())}
							</h5>
							{data.discount && data.discount > 0 ? (
								<h5 className="product-price--discount">
									{formatCurrency(calculatePrice())}
								</h5>
							) : null}
							{data.variants && (
								<div
									className="product-colors"
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "baseline",
									}}
								>
									{data.variants.map((bottle, index) => (
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												paddingLeft: "5px",
											}}
										>
											<img
												key={index}
												className={`product-colors__item${`-${bottle.id}`} ${classNames(
													{
														active:
															otherSize && otherSize.value === bottle.value,
													}
												)}`}
												src={jar}
												// style={{ backgroundColor: 'red' }}
												onClick={() => {
													setOtherSize(bottle);
													// setCurrentPic((p) => (p == i1 ? i2 : i1));
												}}
											/>
											<p
												style={{ fontSize: "12px" }}
											>{`${bottle.value}${data.unit}`}</p>
										</div>
									))}
								</div>
							)}
						</div>
					</div>
				</div>
			) : (
				<div className={`product-list ${classNames(className)}`}>
					<div className="product-list__thumb">
						<div className="product-type">{renderType()}</div>
						<Link
							to={`/shop/product/${data.slug}`}
							className="product-list__thumb__image"
						>
							{data.thumbImage.map((img, index) => (
								<img
									key={index}
									src={require("../../assets/images/product/product_3.png")}
									alt="Product image"
								/>
							))}
							{otherSize && (
								// <img src={otherSize.image} alt="Product image" />
								<img
									src={require("../../assets/images/product/product_4.jpg")}
									alt="Product image"
								/>
							)}
						</Link>
					</div>
					<div className="product-list__content">
						<div className="product-list__content__top">
							<div className="product-category__wrapper">
								<h5 className="product-category">{data.category}</h5>
							</div>
							<Link
								to={`/shop/product/${data.slug}`}
								className="product-name"
								style={{ textDecoration: "none !important" }}
							>
								{data.name}
							</Link>
							<div className="product__price">
								<div className="product__price__wrapper">
									<h5 className="product-price--main">
										{data.discount
											? formatCurrency(data.discount)
											: formatCurrency(calculatePrice())}
									</h5>
									{data.discount ? (
										<h5 className="product-price--discount">
											{formatCurrency(calculatePrice())}
										</h5>
									) : null}
								</div>
								{data.variation && (
									<div className="product-colors">
										{data.variation.map((color, index) => (
											<div
												key={index}
												className={`product-colors__item ${
													otherSize && otherSize.colorCode === color.colorCode
														? "active"
														: ""
												}`}
												style={{ backgroundColor: color.colorCode }}
												onClick={() => setOtherSize(color)}
											/>
										))}
									</div>
								)}
							</div>
						</div>
						<div className="product-list__content__bottom">
							<p className="product-description">{data.description}</p>
							<div className="product__actions">
								<div className="product-btn">
									<AddToCart
										small
										onClick={addToCartHandle}
										className={classNames({
											"-disable":
												checkProductInCart(
													cartState,
													data.id,
													otherSize?.value
												) || data.quantity < 1,
										})}
									/>
								</div>
								<ReactTooltip id="l-qvIcon" type="dark" effect="solid">
									<span>Vista rápida</span>
								</ReactTooltip>
							</div>
						</div>
					</div>
				</div>
			)}
			{showQuickView && (
				<Modal
					showModal={showQuickView}
					setShowModal={setShowQuickView}
					width={1170}
				>
					<ProductQuickView data={data} />
				</Modal>
			)}
		</>
	);
}

export default Product;
