import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import CartItemsSidebar from "./CartItemsSidebar";
import MobileNavSidebar from "./MobileNavSidebar";
import { formatCurrency } from "../../../common/utils";
import cart1 from '../../../assets/images/header/shopping-bag-white.svg'


export default function MenuFunctionIcons(props) {
  const cartState = useSelector((state) => state.cartReducer);
  const hide = props.hide || "";
  const [showCart, setShowCart] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);

  const calculate = (item) => {
    const withoutDiscount = item.variants && item.variants.length > 0 && item.selectedColor ? item.variants.find(i => i.id === item.selectedColor)?.price : 0;
    const withDiscount = item.discount && item.discount > 0 ? withoutDiscount - Math.round(withoutDiscount * item.discount / 100) : withoutDiscount
    return withDiscount;
  }

  function calcalateTotal(arr) {
    let total = 0;
    arr.forEach((item) => (total += calculate(item) * item.cartQuantity));
    return total;
  }
  return (
    <>
      <div
        className={`menu__wrapper__functions ${classNames(props.className)}`}
      >
        {!hide.includes("cart") && (
          <>
            <div className="menu__cart">
              <a
                href="#"
                className="menu-icon -cart"
                onClick={(e) => {
                  e.preventDefault();
                  setShowCart(!showCart);
                }}
              >
                <img
                  src={
                    props.white
                      ? cart1
                      : cart1
                  }
                  alt="Cart icon"
                />
                <span className="cart__quantity">{cartState.length}</span>
              </a>
              {/* <h5 style={{color: 'black'}}> */}
              <h5 >

                TOTAL: <span>{formatCurrency(calcalateTotal(cartState))}</span>
              </h5>
            </div>
            <a
              href="#"
              className="menu-icon -navbar"
              onClick={(e) => {
                e.preventDefault();
                setShowMobileNav(!showMobileNav);
              }}
            >
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </a>
          </>
        )}
      </div>
      {/* Cart sidebar */}
      <CartItemsSidebar showCart={showCart} setShowCart={setShowCart} />


      {/* Mobile navigation sidebar */}
      <MobileNavSidebar
        showMobileNav={showMobileNav}
        setShowMobileNav={setShowMobileNav}
      />
    </>
  );
}
