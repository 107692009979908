import React, { Component } from "react";
import { toast } from "react-toastify";
import { firebase } from "../../../firebase";
import axios from "axios";
import noPhoto from "../../../assets/images/product/noPhoto.png";
import backendUrl from "../../../constants/urls";

class OrderUpdateModal extends Component {
	state = {
		modal: false,
		selected: "",
	};

	closeModal = () => {
		this.props.onClick(this.state.modal);
	};

	convertDateTime = (d) => {
		let t = new Date(1970, 0, 1);
		let time = t.setSeconds(d.seconds);
		const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(time);
		const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(time);
		const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(time);
		return `${da}-${mo}-${ye}`;
	};

	componentDidMount() {
		const { order } = this.props;
		this.setState({
			selected: order.status,
		});
	}

	handleUpdate = (e) => {
		e.preventDefault();
		const { order } = this.props;

		if (this.state.selected === "") {
			return false;
		} else if(this.state.selected === "cancel" && order.status === "approved"){
			toast.error("No puedes cancelar una compra que ya fue aprobada", {
				position: "bottom-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
			return false;
		}else if(this.state.selected === "pending" && order.status === "approved"){
			toast.error("No puedes setear pendiente a una compra que ya fue aprobada", {
				position: "bottom-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
			return false;
		}
		if (order) {
			const data = {
				...order,
			};
			const db = firebase.firestore();
			const dbOrderRef = db.collection("orders");
			dbOrderRef
				.doc(this.props.order.id)
				.update({
					status: this.state.selected,
				})
				.then(async (r) => {
					if (
						this.state.selected === "approved" &&
						order.status != "approved"
					) {
						try {
							const response = await axios.post(
								`${backendUrl}/api/mailer/confirm`,
								data,
								{
									headers: {
										accept: "application/json",
										"Accept-Language": "en-US,en;q=0.8",
										"Content-Type": `application/json`,
									},
								}
							);

							if (200 === response.status) {
								// If file size is larger than expected.
								if (response.data.error) {
									// If not the given file type
									alert(response.data.error);
								} else {
									// Success
									console.log("email sent");
								}
							}
						} catch (error) {
							// If another error
							alert(error, "red");
						}
					}
				 else {
						toast.success("Estado cambiado a " + " " + this.state.selected, {
							position: "bottom-left",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
						});
						this.closeModal();
						setTimeout(() => {
							window.location.reload();
						}, 1000);
					}

					toast.success("Estado cambiado correctamente", {
						position: "bottom-left",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
					setTimeout(() => {
						this.closeModal();
						window.location.reload();
					}, 1000);
				});
		}
	};

	render() {
		const { order } = this.props;
		const products = order && order.items ? Object.values(order.items) : null;
		return (
			<React.Fragment>
				<div className={`admin-product-modal ${this.props.active}`}>
					<div className="modal-innter-content">
						<button type="button" className="close" onClick={this.closeModal}>
							<span aria-hidden="true">
								<i className="bx bx-x"></i>
							</span>
						</button>

						<div className="modal-body">
							<h3>Ver/Cambiar estado</h3>

							<div>
								<label>
									<strong>Productos:</strong>
								</label>
								{order &&
									products &&
									products.length > 0 &&
									products.map((i) => (
										<div className="my-product-box">
											<div className="my-row">
												<label>Producto:</label>
												<div className="my-value">{i.name}</div>
											</div>
											<div className="my-row">
												<label>Tamaño:</label>
												<div className="my-value">{i.selectedColor || "-"}</div>
											</div>
											<div className="my-row">
												<label>Cantidad:</label>
												<div className="my-value">{i.cartQuantity}</div>
											</div>
											<div className="solid-border"> </div>
										</div>
									))}
								<label>
									<strong>Comentarios:</strong>
								</label>{" "}
								<div>{order.comments || "-"}</div>
								<label> </label>
								{order.paymentType == "TRANSFERENCIA" && (
									<>
										<label>
											<strong>Comprobante:</strong>
										</label>
										<div>
											<img
												src={order.voucherUrl ? order.voucherUrl : noPhoto}
												className="voucher-image"
												alt="image"
											/>
										</div>
									</>
								)}
							</div>

							<form onSubmit={this.handleUpdate}>
								<div className="form-group">
									<label>Estado</label>
									<select
										className="form-control"
										id="product-type"
										value={this.state.selected}
										onChange={(e) =>
											this.setState({ selected: e.target.value })
										}
									>
										<option value="approved">Aprobado</option>
										<option value="pending">Pendiente</option>
										<option value="cancel">Rechazado</option>
									</select>
								</div>

								<div className="modal-btn">
									<div
										className="btn optional-btn float-left"
										onClick={this.closeModal}
									>
										Cancel
									</div>
									<button className="btn default-btn float-right">
										Cambiar estado
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default OrderUpdateModal;
