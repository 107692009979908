import { SHOP } from "../variables";

export const setFilterCategory = (category) => ({
  type: SHOP.SET_FILTER_CATEGORY,
  category,
});
export const setFilterSize = (size) => ({
  type: SHOP.SET_FILTER_SIZE,
  size,
});
export const setFilterPriceRange = (priceRange) => ({
  type: SHOP.SET_FILTER_PRICE_RANGE,
  priceRange,
});

export const resetFilter = () => ({
  type: SHOP.RESET_FILTER,
});

export const addFeed = (feed) => {
  return {
      type: SHOP.ADD_FEED,
      feed
  }
}
export const addTemporaryData = (data) => ({
	type: SHOP.ADD_TEMPORARY_DATA,
	data,
});

export const addVacation = (vacation) => ({
	type: SHOP.ADD_VACATION,
	vacation,
});

export const updateVacation = (vacation) => ({
	type: SHOP.UPDATE_VACATION,
	vacation,
});

export const removeVacation = () => ({
	type: SHOP.REMOVE_VACATION,
});

export const removeTemporaryData = () => ({
	type: SHOP.REMOVE_TEMPORARY_DATA
});