import React from "react";
import { useState, useEffect } from "react";
import Select from "../Control/Select";
import { shop } from "../../common/variables";
import info from "../../assets/icons/information.svg";
import SearchBox from "../Header/Elements/SearchBox";

export default function ShopHeader({
	curentView,
	getCurrentView,
	setCurrentSearch,
	currentSearch,
}) {
	const [view, setView] = useState(curentView ? curentView : shop.DEFAULT_VIEW);
	useEffect(() => {
		getCurrentView && getCurrentView(view);
	}, [view]);

	return (
		<div className="shop-header">
			<div className="shop-header__view">
				<div className="shop-header__view__icon">
					<a
						href={process.env.PUBLIC_URL + "#"}
						className={view === "grid" ? "active" : ""}
						onClick={(e) => {
							e.preventDefault();
							setView("grid");
						}}
					>
						<i className="fa fa-th"></i>
					</a>
					<a
						href={process.env.PUBLIC_URL + "#"}
						className={view === "list" ? "active" : ""}
						onClick={(e) => {
							e.preventDefault();
							setView("list");
						}}
					>
						<i className="fa fa-bars"></i>
					</a>
				</div>
			</div>
			<SearchBox search={currentSearch} setCurrentSearch={setCurrentSearch} />

			<p style={{ fontSize: "11px", color: "gray" }}>
				<img src={info} style={{ height: "18px" }} /> Antes de usar cualquier{" "}
				<strong>aceite esencial</strong>, se recomienda consultar a tu médico y,
				si lo vas a usar sobre la piel, es importante que hagas una prueba de
				sensibilidad primero, consiste en aplicar una gota en la parte posterior
				de la muñeca y esperar un par de horas. Durante embarazo, lactancia o
				tratamiento médico, consulta a tu médico tratante antes de usar los
				aceites esenciales en cualquier forma de uso. Evita el contacto con los
				ojos, oídos internos y áreas sensibles. Se recomienda no sobreexponerse
				al sol luego de su aplicación.
			</p>
		</div>
	);
}
