import React from 'react';

import { Link } from "react-router-dom";
import classNames from "classnames";

export default function Button(props) {
  const {
    action,
    as,
    content,
    color,
    height,
    width,
    className,
    onClick,
    disable,
    none
  } = props;
  return (
      <button
        className={`btn -${classNames(color)} ${classNames(className)}`}
        onClick={onClick}
        style={{
          height: height,
          width: width,
          lineHeight: height,
          paddingTop: height && 0,
          paddingBottom: height && 0,
          paddingLeft: width && 0,
          paddingRight: width && 0,
          pointerEvents: disable && none,
        }}
      >
        {content}
      </button>
  );
}
