import React, { Component } from "react";

class SalesInfo extends Component {
	isThisMonth = (d) => {
		let t = new Date(1970, 0, 1);
		let time = t.setSeconds(d.seconds);
		const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(time);
		const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(time);
		const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(time);
		const today = new Intl.DateTimeFormat("en", { month: "short" }).format(
			new Date()
		);
		return today == mo;
	};

	isThisYear = (d) => {
		let t = new Date(1970, 0, 1);
		let time = t.setSeconds(d.seconds);
		const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(time);
		const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(time);
		const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(time);
		const today = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
			new Date()
		);
		return today == ye;
	};

	render() {
		const { data } = this.props;
		let totalIncomeThisMonth = 0;
		let totalIncomeAnnual = 0;
		let totalOrders = 0;
		let totalOrdersAnual = 0;
		if (data && data.length > 0) {
			data.forEach((element) => {
				if (
					element.status == "approved" &&
					this.isThisMonth(element.createdAt)
				) {
					totalIncomeThisMonth += element.amount;
					totalOrders++;
				}
				if (
					element.status == "approved" &&
					this.isThisYear(element.createdAt)
				) {
					totalIncomeAnnual += element.amount;
					totalOrdersAnual++;
				}
			});
		}
		return (
			<div>
				<div>MENSUAL:</div>

				<div className="row">
					<div className="col-lg-3 col-md-6">
						<div className="stats-card">
							<div className="media align-items-center justify-content-between">
								<div className="ml-0">
									<h3>Total ingresos</h3>
									<p>(Este mes)</p>
								</div>
								<div className="avatar avatar-pink">
									<i className="bx bxs-badge-dollar"></i>
								</div>
							</div>

							<h4 className="growth">${totalIncomeThisMonth}</h4>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div className="stats-card">
							<div className="media align-items-center justify-content-between">
								<div className="ml-0">
									<h3>Total pedidos</h3>
									<p>(Este mes)</p>
								</div>
								<div className="avatar avatar-blue">
									<i className="bx bx-cart-alt"></i>
								</div>
							</div>

							<h4 className="growth">{totalOrders}</h4>
						</div>
					</div>
				</div>

				<div>ANUAL:</div>
				<div className="row">
					<div className="col-lg-3 col-md-6">
						<div className="stats-card">
							<div className="media align-items-center justify-content-between">
								<div className="ml-0">
									<h3>Total ingresos</h3>
									<p>(Este año)</p>
								</div>
								<div className="avatar avatar-pink">
									<i className="bx bxs-badge-dollar"></i>
								</div>
							</div>

							<h4 className="growth">${totalIncomeAnnual}</h4>
						</div>
					</div>

					<div className="col-lg-3 col-md-6">
						<div className="stats-card">
							<div className="media align-items-center justify-content-between">
								<div className="ml-0">
									<h3>Total pedidos</h3>
									<p>(Este año)</p>
								</div>
								<div className="avatar avatar-blue">
									<i className="bx bx-cart-alt"></i>
								</div>
							</div>

							<h4 className="growth">{totalOrdersAnual}</h4>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default SalesInfo;
