import { 
    ADD_TO_CART,
    REMOVE_ITEM,
    SUB_QUANTITY,
    ADD_QUANTITY,
    ADD_QUANTITY_WITH_NUMBER,
    RESET_CART,
    ADD_PRODUCTS,
    ADD_SUBPRODUCTS,
    ADD_SINGLE_PRODUCT,
    ADD_SINGLE_SUBPRODUCT,
    EDIT_PRODUCT,
    EDIT_SUBPRODUCT,
    REMOVE_PRODUCT,
    REMOVE_SUBPRODUCT,
    ADD_FEED
} from './action-types/cart-actions'


//add products
export const addProducts = (products) => {
    return {
        type: ADD_PRODUCTS,
        products
    }
}
//add subproducts
export const addSubproducts = (subproducts) => {
    return {
        type: ADD_SUBPRODUCTS,
        subproducts
    }
}

//add single product
export const addSingleProduct = (product) => {
    return {
        type: ADD_SINGLE_PRODUCT,
        product
    }
}

//edit single product
export const editProduct = (product) => {
    return {
        type: EDIT_PRODUCT,
        product
    }
}

//edit single product
export const editSubproduct = (subproduct) => {
    return {
        type: EDIT_SUBPRODUCT,
        subproduct
    }
}

//remove single product
export const removeProduct = (id) => {
    return {
        type: REMOVE_PRODUCT,
        id
    }
}


//remove single subproduct
export const removeSubproduct = (id) => {
    return {
        type: REMOVE_SUBPRODUCT,
        id
    }
}

//add single subproduct
export const addSingleSubproduct = (subproduct) => {
    return {
        type: ADD_SINGLE_SUBPRODUCT,
        subproduct
    }
}

//add cart action
// DO NOT USE THIS FUNCTION
export const addToCart = (id) => {
    return {
        type: ADD_TO_CART,
        id
    }
}
//remove item action
export const removeItem = (product) => {
    return {
        type: REMOVE_ITEM,
        product
    }
}
//subtract qt action
export const subtractQuantity = (id) => {
    return {
        type: SUB_QUANTITY,
        id
    }
}
//add qt action
export const addQuantity = (id) => {
    return {
        type: ADD_QUANTITY,
        id
    }
}

//add qt action with quantity number
export const addQuantityWithNumber = (id, qty, customProd = null) => {
    return {
        type: ADD_QUANTITY_WITH_NUMBER,
        id,
        qty,
        customProd
    }
}

// Reset cart after form submit
export const resetCart = () => {
    return {
        type: RESET_CART
    }
}
