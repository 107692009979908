export const PAYMENT_TYPES = {
	CREDIT_CARD: "credit_card",
	DEBIT_CARD: "debit_card",
	COBRANZA: "ticket",
	TRANSFERENCIA: "bank",
};

export const PAYMENT_TYPES_NORMALIZED = {
	CREDIT_CARD: "Tarjeta",
	DEBIT_CARD: "Tarjeta",
	COBRANZA: "Red de cobranza",
	TRANSFERENCIA: "Transferencia",
};

export const setOrderStatus = (type, inProcess) => {
	if (!type) {
		return "approved";
	}
	return PAYMENT_TYPES[type] == PAYMENT_TYPES.COBRANZA || PAYMENT_TYPES[type] == PAYMENT_TYPES.TRANSFERENCIA || inProcess
		? "pending"
		: "approved";
};

const extractTypeFromUrl = (url) => {
	const firstPart = url.split("payment_type=");
	if (firstPart && firstPart.length > 0) {
		const secondPart = firstPart[1].split("&");
		if (secondPart && secondPart.length > 0) {
			return secondPart[0];
		}
	}
	return null;
};

export const selectPaymentType = (value) => {
	const extracted = extractTypeFromUrl(value);
	if (extracted) {
		return Object.keys(PAYMENT_TYPES).find(
			(key) => PAYMENT_TYPES[key] === extracted
		);
	} else {
		return null;
	}
};


const extractStatusFromUrl = (url) => {
  const firstPart = url.split('collection_status=');
  if(firstPart && firstPart.length > 0) {
    const secondPart = firstPart[1].split('&');
    if(secondPart && secondPart.length > 0) {
      return secondPart[0];
    }
  }
  return null;
}
export const selectPaymentStatus = (value) => {
  const extracted = extractStatusFromUrl(value);
  if(extracted) {
    return extracted;
  } else {
    return null;
  }
};

const extractIdFromUrl = (url) => {
  const firstPart = url.split('payment_id=');
  if(firstPart && firstPart.length > 0) {
    const secondPart = firstPart[1].split('&');
    if(secondPart && secondPart.length > 0) {
      return secondPart[0];
    }
  }
  return null;
}
export const selectPaymentId = (value) => {
  const extracted = extractIdFromUrl(value);
  if(extracted) {
    return extracted;
  } else {
    return null;
  }
};