import React from "react";

export const BreadcrumbItem = ({ name, current }) => {
  return <li className={current && "active"}>{name}</li>;
};

export const Breadcrumb = ({ title, children, img }) => {
  return (
    <div className="breadcrumb" style={{  background: `linear-gradient(0deg, #212529, #2125297d), url(${img})`, backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed'}}>
      <div className="container" >
        <h2>{title}</h2>
        <ul>{children}</ul>
      </div>
    </div>
  );
};
