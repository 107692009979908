import React from "react";
import Slider from "react-slick";
import { connect } from "react-redux";
import { useSelector } from "react-redux";

function InstagramTwo() {
	const feed = useSelector((state) => state.shopReducers.feed);
	const normalizedFeed = feed && feed.length > 0 ? feed.slice(0, 6) : [];
	const settings = {
		dots: false,
		arrows: false,
		slidesToShow: 6,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		responsive: [
			{
				breakpoint: 1400,
				settings: {
					slidesToShow: 5,
				},
			},
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4,
				},
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 2,
				},
			},
		],
	};
	return (
		<div className="instagram-two">
			<Slider {...settings}>
				{normalizedFeed.map((e, i) => {
					return (
						<a
							key={i}
              href={e.link}
              target="_blank"
							className="slider-item"
						>
							<img src={e.img} alt="Instagram image" />
						</a>
					);
				})}
			</Slider>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		feed: state.feed,
	};
};

const WrappedInstagramFeed = connect(mapStateToProps, null)(InstagramTwo);
export default WrappedInstagramFeed;
