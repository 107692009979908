import React from "react";

import { useForm } from "react-hook-form";

import SectionTitleOne from "../SectionTitle/SectionTitleOne";
import Button from "../../Control/Button";

export default function CTAOne() {
	const { register, handleSubmit, watch, errors } = useForm({
		mode: "onChange",
	});
	const onSubmit = (data) => {
		console.log(data);
	};
	return (
		<div className="cta -style-1">
			<div className="container">
				<div className="row">
					<div className="col-12 col-md-6 mx-auto">
						<div className="cta__form">
							<Button
								action="#"
								color="white"
								content="CONOZCA NUESTROS PRODUCTOS"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
