import React from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useForm } from "react-hook-form";
import tick from '../../../assets/images/about/blueTick.svg';

export default function ProductDetailInfoTab({ data, onReviewSubmit }) {
	const { register, handleSubmit, errors } = useForm();
	return (
		<div className="product-detail__tab">
			<Tabs className="product-detail__tab__content">
				<TabList className="tab__content__header">
					<Tab>Propiedades & Beneficios</Tab>
					<Tab>Descripción</Tab>
					<Tab>Usos</Tab>
					<Tab>Dosis</Tab>
				</TabList>

				<TabPanel className="tab__content__item -ship">
					{data.benefits && data.benefits.length > 0 ? (
						<ul>
							{data.benefits.map((b) => (
								<li><img src={tick} style={{height: '20px', paddingRight: '10px'}}/>{b}</li>
							))}
						</ul>
					) : (
						<p>No hay información</p>
					)}
				</TabPanel>
				<TabPanel className="tab__content__item -description">
					<p>{data.description ? data.description : "No hay información."}</p>
				</TabPanel>

				<TabPanel className="tab__content__item -ship">
					{data.uses && data.uses.length > 0 ? (
						<ul>
							{data.uses.map((b) => (
								<li>{b}</li>
							))}
						</ul>
					) : (
						<p>No hay información</p>
					)}
				</TabPanel>
				<TabPanel className="tab__content__item -ship">
					{data.doses && data.doses.length > 0 ? (
						<ul>
							{data.doses.map((b) => (
								<li>{b}</li>
							))}
						</ul>
					) : (
						<p>No hay información</p>
					)}{" "}
				</TabPanel>
			</Tabs>
		</div>
	);
}
