import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import LayoutFour from "../../components/Layout/LayoutFour";
import Quantity from "../../components/Control/Quantity";
import Button from "../../components/Control/Button";
import { Breadcrumb, BreadcrumbItem } from "../../components/Other/Breadcrumb";
import { formatCurrency } from "../../common/utils";
import { calculateTotalPrice, calculate } from "../../common/shopUtils";
import argan from '../../assets/images/product/argan.png';
import {
	removeFromCart,
	removeAllFromCart,
	increaseQuantityCart,
	decreaseQuantityCart,
} from "../../redux/actions/cartActions";
import InstagramTwo from "../../components/Sections/Instagram/InstagramTwo";
import spa from '../../assets/images/banners/spa.jpg'

export default function () {
	const dispatch = useDispatch();
	const { register, handleSubmit, watch, errors } = useForm();
	const cartState = useSelector((state) => state.cartReducer);
	const onSubmit = (data) => console.log(data);
	const removeAllProduct = (e) => {
		e.preventDefault();
		dispatch(removeAllFromCart());
		return toast.error("El carrito fue vaciado.");
	};
	const removeProduct = (e, cartId) => {
		e.preventDefault();
		dispatch(removeFromCart(cartId));
		return toast.error("Producto eliminado del carrito.");
	};
	return (
		<LayoutFour title="Cart">
			<Breadcrumb title="Mi carrito" img={spa}>
				<BreadcrumbItem name="Tienda" />
				<BreadcrumbItem name="Carrito" current />
			</Breadcrumb>
			<div className="cart">
				<div className="container">
					{!cartState || cartState.length === 0 ? (
						<div className="cart__empty">
							<h3>No hay productos en el carrito.</h3>
							<Link to={"/shop"}>
								<Button color="dark" content="Ver productos" />
							</Link>
						</div>
					) : (
						<>
							<div className="cart__table">
								<div className="cart__table__wrapper">
									<table>
										<colgroup>
											<col style={{ width: "40%" }} />
											<col style={{ width: "17%" }} />
											<col style={{ width: "17%" }} />
											<col style={{ width: "17%" }} />
											<col style={{ width: "9%" }} />
										</colgroup>
										<thead>
											<tr>
												<th>Producto</th>
												<th>Tamaño</th>
												<th>Precio</th>
												<th>Cantidad</th>
												<th>Total</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{cartState.map((item, index) => (
												<tr key={index}>
													<td>
														<div className="cart-product">
															<div className="cart-product__image">
																<img src={item.thumbImage && item.thumbImage.length > 0 ? item.thumbImage[0] : (item.images  && item.images.length > 0 ? item.images[0] : argan)} alt={item.name} />
															</div>
															<div className="cart-product__content">
																<h5>{item.category}</h5>
																<Link to={"/shop/product/" + item.slug}>
																	<a>{item.name}</a>
																</Link>
															</div>
														</div>
													</td>
													<td>{`${item.selectedColor}${item.unit}`}</td>
													<td>{formatCurrency(calculate(item))}</td>
													<td>
														<Quantity
															defaultQuantity={item.cartQuantity}
															onIncrease={() =>
																dispatch(increaseQuantityCart(item.cartId))
															}
															onDecrease={() =>
																dispatch(decreaseQuantityCart(item.cartId))
															}
														/>
													</td>
													<td>
														{formatCurrency(calculate(item) * item.cartQuantity)}
													</td>
													<td>
														<a
															href={process.env.PUBLIC_URL + "#"}
															onClick={(e) => removeProduct(e, item.cartId)}
														>
															<i className="fa fa-times"></i>
														</a>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
								<div className="cart__table__footer">
									<Link to={process.env.PUBLIC_URL + "/shop/fullwidth-4col"}>
										<a>
											<i className="fa fa-long-arrow-left"></i>
											Seguir comprando
										</a>
									</Link>

									<Link to="#">
										<a onClick={(e) => removeAllProduct(e)}>
											<i className="fa fa-trash"></i>
											Vaciar carrito
										</a>
									</Link>
								</div>
							</div>
							<div className="cart__total">
								<div className="row">
									<div className="col-12 col-md-8">
										{/* <div className="cart__total__discount">
                      <p>Enter coupon code. It will be applied at checkout.</p>
                      <div className="input-validator">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <input
                            type="text"
                            name="discountCode"
                            placeholder="Your code here"
                            ref={register({ required: true })}
                          />
                          <button className="btn -dark">Apply</button>
                        </form>
                        {errors.discountCode && (
                          <span className="input-error">
                            Please provide a discount code
                          </span>
                        )}
                      </div>
                    </div> */}
									</div>
									<div className="col-12 col-md-4">
										<div className="cart__total__content">
											<h3>Resumen:</h3>
											<table>
												<tbody>
													<tr>
														<th>Subtotal</th>
														<td>${calculateTotalPrice(cartState)}</td>
													</tr>
													<tr>
														<th>Total</th>
														<td className="final-price">
															${calculateTotalPrice(cartState)}
														</td>
													</tr>
													<tr>
														<td style={{ fontSize: "13px", fontWeight: "300" }}>
															**Delivery no incluído.
														</td>
													</tr>
												</tbody>
											</table>
											<Link to="/shop/checkout">
												<Button
													height={45 / 14 + "em"}
													width="100%"
													color="dark"
													content="Comprar"
												/>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
			<InstagramTwo />
		</LayoutFour>
	);
}
