import { formatCurrency } from "./utils";
import moment from "moment";

export const checkProductInCart = (cartArr, pid, color) => {
	if (cartArr || cartArr.length > 0) {
		if (color && color !== "") {
			return cartArr.find(
				(item) =>
					item.id === pid && item.selectedColor && item.selectedColor === color
			);
		}
		return cartArr.find((item) => item.id === pid);
	}
};

export const getAvaiableQuantityInCart = (cartArr, pid, quantity) => {
	let arr = cartArr.filter((item) => item.id === pid);
	if (cartArr || cartArr.length > 0) {
		if (arr.length > 0) {
			let result =
				arr.length > 0 &&
				arr.reduce((total, num) => total + num.cartQuantity, 0);
			return result && quantity - result;
		} else {
			return quantity;
		}
	}
	return quantity;
};

export const checkProductInWishList = (wishlistArr, productId) => {
	return wishlistArr.find((item) => item.id === productId);
};

export const calculate = (item) => {
	const withoutDiscount =
		item.variants && item.variants.length > 0 && item.selectedColor
			? item.variants.find((i) => i.id === item.selectedColor)?.price
			: 0;
	const withDiscount =
		item.discount && item.discount > 0
			? withoutDiscount - Math.round((withoutDiscount * item.discount) / 100)
			: withoutDiscount;
	return withDiscount;
};

export const calculateTotalPrice = (
	cartArr,
	addDelivery = false,
	amount,
	isBankTransfer = false
) => {
	let total = 0;
	cartArr.forEach((item) => (total += calculate(item) * item.cartQuantity));
	let withoutDiscount = addDelivery && amount < 3000 ? total + 350 : total;

	let bankDiscount = Math.round((5 * withoutDiscount) / 100);
	let withDiscount = isBankTransfer
		? withoutDiscount - bankDiscount
		: withoutDiscount;

	return withDiscount;
};

export const calculateDiscount = (
	cartArr,
	addDelivery = false,
	amount,
	isBankTransfer = false
) => {
	let total = 0;
	cartArr.forEach((item) => (total += calculate(item) * item.cartQuantity));
	let withoutDiscount = addDelivery && amount < 3000 ? total + 350 : total;

	let bankDiscount = Math.round((5 * withoutDiscount) / 100);

	return bankDiscount;
};

export const getTentativeDate = (delivery, montevideo) => {
	let limit = 0;

	const today = new Date();
	if (today.getHours() > 15 && !delivery) {
		limit = 48;
	} else if ((today.getHours() < 15 || today.getHours() == 15) && !delivery) {
		limit = 24;
	} else if (delivery && montevideo) {
		limit = 48;
	} else {
		limit = 72;
	}

	//Now check if one item is more than the limit
	let futureDate = null;

	futureDate = new Date();
	let days = 0;
	if (limit == 48) {
		days = 2;
	} else if (limit == 24) {
		days = 1;
	} else if (limit == 72) {
		days = 3;
	}
	futureDate.setDate(today.getDate() + days);

	if (futureDate.getDay() == 6) {
		//Its sunday, we have to move it to Monday.
		futureDate.setDate(today.getDate() + 1);
	} else if (futureDate.getDay() == 5) {
		futureDate.setDate(today.getDate() + 2);
	}
	if (futureDate) {
		return moment(futureDate).format("DD/MM/YY");
	}
};
