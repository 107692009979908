export const LOCALITIES = [
	{
		id: 1,
		name: "Montevideo",
	},
	{
		id: 2,
		name: "Canelones",
	},
	{
		id: 3,
		name: "Maldonado",
	},
	{
		id: 4,
		name: "San José",
	},
	{
		id: 5,
		name: "Colonia",
	},
	{
		id: 6,
		name: "Rocha",
	},
	{
		id: 7,
		name: "Lavalleja",
	},
	{
		id: 8,
		name: "Florida",
	},
	{
		id: 9,
		name: "Flores",
	},
	{
		id: 10,
		name: "Soriano",
	},
	{
		id: 11,
		name: "Río Negro",
	},
	{
		id: 12,
		name: "Durazno",
	},
	{
		id: 13,
		name: "Treinta y Tres",
	},
	{
		id: 14,
		name: "Cerro Largo",
	},
	{
		id: 15,
		name: "Tacuarembó",
	},
	{
		id: 16,
		name: "Paysandú",
	},
	{
		id: 17,
		name: "Salto",
	},
	{
		id: 18,
		name: "Rivera",
	},
	{
		id: 19,
		name: "Artigas",
	},
];
