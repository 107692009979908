import { SHOP } from "../variables";

const initialState = {
	filter: {
		category: "0",
		priceRange: {},
		brand: "",
	},
	vacationNotification: null,
	feed: [],
	temporaryData: null,
};

export default function shopReducer(state = initialState, action) {
	switch (action.type) {
		case SHOP.ADD_VACATION:
			return {
				...initialState,
				vacationNotification: action.vacation,
			};
		case SHOP.REMOVE_VACATION:
			return {
				...initialState,
				vacationNotification: null,
			};
		case SHOP.UPDATE_VACATION:
			return {
				...initialState,
				vacationNotification: action.vacation,
			};
		case SHOP.SET_FILTER_CATEGORY:
			return {
				...initialState,
				filter: {
					...state.filter,
					category: action.category,
				},
			};
		case SHOP.SET_FILTER_SIZE:
			return {
				...initialState,
				filter: {
					...state.filter,
					size: action.size,
				},
			};

		case SHOP.ADD_FEED:
			let existed_item = state.feed.find(
				(item) => item.link === action.feed.link
			);
			if (!existed_item) {
				return {
					...state,
					feed: [...state.feed, action.feed],
				};
			}

		case SHOP.ADD_TEMPORARY_DATA:
			return {
				...state,
				temporaryData: action.data,
			};
		case SHOP.REMOVE_TEMPORARY_DATA:
			return {
				...state,
				temporaryData: null,
			};

		case SHOP.SET_FILTER_PRICE_RANGE:
			return {
				...initialState,
				filter: {
					...state.filter,
					priceRange: action.priceRange,
				},
			};
		case SHOP.RESET_FILTER:
			return {
				...initialState,
				filter: {
					category: "0",
					priceRange: {},
					brand: "",
				},
			};
		default:
			return state;
	}
}
