import React, { useEffect, useState, Suspense } from "react";
import { connect } from "react-redux";

import LayoutOne from "../components/Layout/LayoutOne";
import { useSelector, useDispatch } from "react-redux";
import SliderTwo from "../components/Sections/Slider/SliderTwo";
import sliderData from "../data/slider/sliderTwo.json";
import BrandsOne from "../components/Sections/Brands/BrandsOne";
import InstagramOne from "../components/Sections/Instagram/InstagramOne";
import backendUrl from "../constants/urls";
import axios from "axios";
import {
	addFeed,
	addVacation,
	removeVacation,
	updateVacation,
} from "../redux/actions/shopActions";
import { css } from "@emotion/core";
import NotificationModal from "../components/Shop/NotificationModal";
import BeatLoader from "react-spinners/BeatLoader";
import { firebase } from "../firebase";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
const CategoriesOne = React.lazy(() =>
	import("../components/Sections/Categories/CategoriesOne")
);
const ProductTabOne = React.lazy(() =>
	import("../components/Sections/ProductThumb/ProductTab/ProductTabOne")
);
const DaleOfWeekOne = React.lazy(() =>
	import("../components/Sections/DealOfWeek/DaleOfWeekOne")
);

const IntroductionFive = React.lazy(() =>
	import("../components/Sections/Introduction/IntroductionFive")
);

const BlogTwo = React.lazy(() => import("../components/Sections/Blog/BlogTwo"));

const override = css`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-self: center;
	justify-items: center;
	flex-direction: row;
	width: 100%;
	align-items: center;
`;

function Home(props) {
	const dispatch = useDispatch();
	const [showVacation, setShowVacation] = useState(false);
	const [categories, setCategories] = useState([]);
	const [isLoading1, setIsLoading1] = useState(true);
	const [isLoading2, setIsLoading2] = useState(true);
	const [isLoading3, setIsLoading3] = useState(true);
	const [isLoading4, setIsLoading4] = useState(true);
	const [blogOneData, setBlogOneData] = useState([]);
	const [products, setProducts] = useState([]);
	const [allCategories, setAllCategories] = useState([]);
	const shopData = useSelector((state) => state.shopReducers);

	useEffect(() => {
		const fetchData = async () => {
			const response = await axios.get(`${backendUrl}/api/categories/`, {
				headers: {
					accept: "application/json",
					"Accept-Language": "en-US,en;q=0.8",
				},
			});

			//Put banner in redux store with date
			const db = firebase.firestore();
			const dbOrderRef = db.collection("vacations");
			const res = await dbOrderRef.get();
			let banner = null;
			if (res && res.docs && res.docs.length > 0) {
				let orderObj = res.docs[0]?.data();
				orderObj.id = res.docs[0]?.id;
				banner = orderObj;
			}

			if (banner) {
				if (!shopData?.vacationNotification) {
					banner.lastShown = new Date();
					dispatch(addVacation(banner));
					//Y LO MUESTRO
					setShowVacation(true);
				} else {
					if (shopData?.vacationNotification?.lastShown < new Date()) {
						//LO MUESTRO Y LE UPDATEO LA FECHA EN EL STORE
						setShowVacation(true);
						const newBanner = {
							...shopData?.vacationNotification,
							lastShown: new Date(),
						};
						dispatch(updateVacation(newBanner));
					}
				}
			} else {
				dispatch(removeVacation());
			}

			setCategories(response.data?.filter((c) => c.title != "Todos"));
			setAllCategories(response.data);

			const response2 = await axios.get(`${backendUrl}/api/products/`, {
				headers: {
					accept: "application/json",
					"Accept-Language": "en-US,en;q=0.8",
				},
			});

			const response3 = await axios.get(`${backendUrl}/api/blogs/`, {
				headers: {
					accept: "application/json",
					"Accept-Language": "en-US,en;q=0.8",
				},
			});

			setProducts(response2.data);
			setBlogOneData(response3.data);
			setIsLoading1(false);
			setIsLoading2(false);
			setIsLoading3(false);

			let allFeed = [];
			allFeed = await axios.get(`${backendUrl}/api/get-feed`);
			const { data } = allFeed;

			if (data && data.feed) {
				data.feed.forEach((f) => props.addFeed(f));
			}

			setIsLoading4(false);
		};
		fetchData();
	}, []);

	var isMobile = false;
	// device detection
	if (
		/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
			navigator.userAgent
		) ||
		/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
			navigator.userAgent.substr(0, 4)
		)
	) {
		isMobile = true;
	}

	return (
		<LayoutOne title="Home" data={sliderData} className="-style-1">
			<NotificationModal
				show={showVacation}
				title={"Atención"}
				subtitle={"Las fechas de tus pedidos pueden verse modificados. Comuníquese con nosotros."}
				content={
					shopData?.vacationNotification?.content ||
					"Estaremos cerrados hasta nuevo aviso"
				}
				onHide={() => setShowVacation(false)}
			/>
			<SliderTwo className="-style-2" data={sliderData} showDots />
			<Suspense fallback={<div></div>}>
				<CategoriesOne data={categories} loading={isLoading1} />
			</Suspense>

			<Suspense
				fallback={
					<BeatLoader
						css={override}
						size={isMobile ? 20 : 50}
						color={"#10ABDE"}
						loading={true}
					/>
				}
			>
				<ProductTabOne
					data={products}
					categories={allCategories}
					loadingCat={isLoading2}
					loadingProd={isLoading3}
				/>
			</Suspense>

			<Suspense
				fallback={
					<BeatLoader
						css={override}
						size={isMobile ? 20 : 50}
						color={"#10ABDE"}
						loading={true}
					/>
				}
			>
				<DaleOfWeekOne
					subTitle="POR ESTE MES"
					title="Descuentos en todos los productos"
					description="Encontrá los mejores productos a precios más bajos por el lanzamiento de la web!"
					last={100000000}
				/>
			</Suspense>

			<Suspense
				fallback={
					<BeatLoader
						css={override}
						size={isMobile ? 20 : 50}
						color={"#10ABDE"}
						loading={true}
					/>
				}
			>
				<BlogTwo data={blogOneData.slice(0, 3)} loading={isLoading4} />
			</Suspense>
			<Suspense
				fallback={
					<BeatLoader
						css={override}
						size={isMobile ? 20 : 50}
						color={"#10ABDE"}
						loading={true}
					/>
				}
			>
				<IntroductionFive />
			</Suspense>
			<BrandsOne />
			<InstagramOne />
		</LayoutOne>
	);
}

const mapDispatchToProps = (dispatch) => {
	return {
		addFeed: (feed) => {
			dispatch(addFeed(feed));
		},
	};
};
const WrappedHome = connect(null, mapDispatchToProps)(Home);
export { WrappedHome as Home };
