export const CART = {
	ADD_TO_CART: "ADD_TO_CART",
	REMOVE_FROM_CART: "REMOVE_FROM_CART",
	REMOVE_ALL_FROM_CART: "REMOVE_ALL_FROM_CART",
	DECREASE_QUANTITY_CART: "DECREASE_QUANTITY_CART",
	INCREASE_QUANTITY_CART: "INCREASE_QUANTITY_CART",
};

export const DELIVERY = {
	ADD_DELIVERY: "ADD_DELIVERY",
	REMOVE_DELIVERY: "REMOVE_DELIVERY",
};

export const WISHLIST = {
	ADD_TO_WISHLIST: "ADD_TO_WISHLIST",
	REMOVE_FROM_WISHLIST: "REMOVE_FROM_WISHLIST",
};

export const SHOP = {
	SET_FILTER_CATEGORY: "SET_FILTER_CATEGORY",
	SET_FILTER_PRICE_RANGE: "SET_FILTER_PRICE_RANGE",
	SET_FILTER_SIZE: "SET_FILTER_SIZE",
	RESET_FILTER: "RESET_FILTER",
	ADD_FEED: "ADD_FEED",
	ADD_TEMPORARY_DATA: "ADD_TEMPORARY_DATA",
	REMOVE_TEMPORARY_DATA: "REMOVE_TEMPORARY_DATA",
	ADD_VACATION: "ADD_VACATION",
	UPDATE_VACATION: "UPDATE_VACATION",
	REMOVE_VACATION: "REMOVE_VACATION",
};
