import React from "react";
import Button from "./Button";
import classNames from "classnames";

export default function AddToCart({ onClick, className, small }) {
	return (
		<div className={`add-to-cart ${classNames(className)}`} onClick={onClick}>
			<Button
				height="3.85em"
				width="3.85em"
				color="red"
				className="-round"
				action="#"
				content={<i className="fa fa-shopping-bag"></i>}
			/>
			{small ? <h5>Agregar</h5> : <h5>Agregar al carrito</h5>}
		</div>
	);
}
